document.addEventListener("DOMContentLoaded", function () {
    let dashboardAvailabilityProduct = document.querySelector('.Dashboard--availability-product');
    if (dashboardAvailabilityProduct) {
        orderSimulation();
        let originalTbody = null;
        let searchInput = document.getElementById('availability_product_form_availability_product');
        let qteField = document.getElementById('availability_product_form_quantity_product');
        let btn_submit = document.getElementById('availability_product_form_submit');
        let loaderAvailabilityProduct = document.querySelector('.loading-dragon.loader-availability-product');
        let btnOrderSimulation = document.querySelector('.btn-order-simulation button');

        let openAccordion = dashboardAvailabilityProduct.querySelector('.accordion-header .accordion-button');
        openAccordion.addEventListener('click', function () {
            openAccordion.querySelector('.icon').removeAttribute('class');

            if (openAccordion.classList.contains('collapsed')) {
                openAccordion.querySelector('svg').setAttribute('class', 'icon icon-chevron-down');
                openAccordion.querySelector('svg use').setAttribute('xlink:href', '/bundles/thermorprosite/dist/icons.svg#icon-chevron-down');
            } else {
                openAccordion.querySelector('svg').setAttribute('class', 'icon icon-chevron');
                openAccordion.querySelector('svg use').setAttribute('xlink:href', '/bundles/thermorprosite/dist/icons.svg#icon-chevron');
            }
        })

        function updateStatus(){
            document.querySelectorAll('.search-available-product .empty-date').forEach(span => {
                span.textContent = 'En attente de lancement de la simulation';
                span.classList.remove('d-none');
            });
        }
        function toggleIconsTableVisibility() {
            document.addEventListener('click', function(event) {
                if (event.target.matches('.icons-tables span')) {
                    const parentRow = event.target.closest('.icons-tables');
                    if (parentRow) {
                        parentRow.style.display = 'none';
                    }
                }

                if (event.target.matches('.btn-modify-qte button')) {
                    const parentRow = event.target.closest('.btn-modify-qte')?.closest('.tr-table');
                    const iconsTable = parentRow?.querySelector('.icons-tables');
                    if (iconsTable) {
                        iconsTable.style.display = 'flex';
                    }
                }
            });
        }

        function addProduct() {
            let debounceTimeout;
            let isFieldSearchFilled = false;
            let isQteFieldFilled = false;
            let isSuggestionSelected = false;

            qteField.addEventListener('input', (event) => {
                let value = event.target.value.trim();
                const numberValue = parseInt(value, 10);

                if (!/^\d{1,2}$/.test(value) || numberValue < 1 || numberValue > 50) {
                    event.target.classList.add('error-border');
                    isQteFieldFilled = false;
                } else {
                    event.target.classList.remove('error-border');
                    isQteFieldFilled = value.length > 0;
                }

                toggleSubmitButton();
            });

            searchInput.addEventListener('input', (event) => {
                const query = event.target.value.trim();

                if (query.length <= 3) {
                    isFieldSearchFilled = false;
                    clearSuggestions();
                    toggleSubmitButton();
                    return;
                }

                isFieldSearchFilled = true;

                clearTimeout(debounceTimeout);
                debounceTimeout = setTimeout(() => {
                    fetch('/account/availability-p/', {
                        method: 'POST',
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({searchInput: query})
                    })
                        .then(response => response.json())
                        .then(data => {
                            const suggestionBox = document.querySelector('.SuggDropDown');
                            const inputField = document.getElementById('availability_product_form_availability_product');

                            suggestionBox.innerHTML = '';

                            if (!data.matchedData || !Array.isArray(data.matchedData)) {
                                console.error('Invalid data format:', data);
                                return;
                            }

                            let val = query.toUpperCase();
                            let filteredResults = data.matchedData
                                .filter(item => {
                                    return item &&
                                        item.libelle_article &&
                                        typeof item.libelle_article === 'string' &&
                                        item.libelle_article.toUpperCase().includes(val);
                                })
                                .slice(0, 5);

                            if (!suggestionBox) {
                                const container = document.createElement('div');
                                container.classList.add('SuggDropDown');
                                inputField.parentNode.appendChild(container);
                            }

                            suggestionBox.style.width = `${inputField.offsetWidth}px`;

                            if (filteredResults.length === 0) {
                                clearSuggestions();
                            } else {
                                const suggestionsList = document.createElement('div');
                                suggestionsList.classList.add('suggestions-list');

                                filteredResults.forEach(item => {
                                    const suggestion = document.createElement('div');
                                    suggestion.classList.add('SuggInput');
                                    suggestion.textContent = item.libelle_article;

                                    suggestion.addEventListener('mouseover', () => {
                                        suggestion.classList.add('SuggInputHovered');
                                    });
                                    suggestion.addEventListener('mouseout', () => {
                                        suggestion.classList.remove('SuggInputHovered');
                                    });

                                    suggestion.addEventListener('click', () => {
                                        inputField.value = item.libelle_article;
                                        inputField.setAttribute('data-value', item.libelle_article);

                                        if (btn_submit){
                                            btn_submit.setAttribute('data-sku', item.sku);
                                            btn_submit.setAttribute('data-url-image', item.img);
                                            btn_submit.setAttribute('data-article-deadline', item.onlineArticleDeadline);
                                            btn_submit.setAttribute('data-deadline-th', item.deadlineTh);
                                            btn_submit.setAttribute('data-product-type', item.productType);
                                        }

                                        isFieldSearchFilled = true;
                                        isSuggestionSelected = true;
                                        clearSuggestions();
                                        toggleSubmitButton();
                                    });

                                    suggestionsList.appendChild(suggestion);
                                });

                                suggestionBox.appendChild(suggestionsList);
                            }

                            suggestionBox.style.display = 'block';
                            suggestionBox.classList.remove('d-none');

                            if (filteredResults.length > 5) {
                                suggestionBox.style.overflowY = 'auto';
                                suggestionBox.style.maxHeight = '200px';
                            } else {
                                suggestionBox.style.overflowY = 'hidden';
                            }
                        })
                        .catch(error => {
                            console.error('Error fetching suggestions:', error);
                            clearSuggestions();
                        });
                }, 250);

                toggleSubmitButton();
            });

            function toggleSubmitButton() {
                if (isQteFieldFilled && (isFieldSearchFilled || isSuggestionSelected) &&
                    searchInput.getAttribute('data-value') === searchInput.value
                    && qteField.value > 0 && qteField.value <= 50
                ) {
                    btn_submit.disabled = false;
                } else {
                    btn_submit.disabled = true;
                }
            }

            function clearSuggestions() {
                const suggestionBox = document.querySelector('.SuggDropDown');
                if (suggestionBox) {
                    suggestionBox.innerHTML = '';
                    suggestionBox.classList.add('d-none');
                }
            }
        }

        addProduct();

        const tbody = document.querySelector('.search-available-product tbody');
        let iconsTables = document.querySelector('.icons-tables');
        if (tbody) {
            originalTbody = tbody.cloneNode(true);
        }

        if (btn_submit) {
            btn_submit.addEventListener('click', function (e) {
                e.preventDefault();
                document.querySelectorAll('.empty-date').forEach(element => {
                    element.textContent = "En attente de lancement de la simulation";
                });
                let sku = btn_submit.getAttribute('data-sku');
                let imageUrl = btn_submit.getAttribute('data-url-image');
                let dataArticleDeadline = btn_submit.getAttribute('data-article-deadline');
                let deadlineTh = btn_submit.getAttribute('data-deadline-th');
                let productType = btn_submit.getAttribute('data-product-type');

                let tableAvailableProduct = document.querySelector('.table-available-product');
                if (tableAvailableProduct) {
                    tableAvailableProduct.classList.remove('d-none');
                }

                let qte = qteField.value;
                let searchInputField = searchInput.value;
                let qteId = qteField.id;
                let searchInputFieldId = searchInput.id;

                let tableBody = document.querySelector('.search-available-product tbody');

                if (!tableBody && originalTbody) {
                    const table = document.querySelector('.search-available-product');
                    table.appendChild(originalTbody.cloneNode(true));
                    tableBody = document.querySelector('.search-available-product tbody');
                }

                let firstRow = document.querySelector('.tr-table');
                const selectors = ['.download-pdf-availability-product', '.results-search--notice'];

                selectors.forEach(selector => {
                    const element = document.querySelector(selector);
                    if (element) {
                        element.classList.add('d-none');
                    }
                });

                if (tableBody && firstRow) {
                    let firstTdNameProduct = firstRow.querySelector(`.${searchInputFieldId}`);
                    let firstTdQte = firstRow.querySelector(`.${qteId}`);
                    let firstImgElement = firstRow.querySelector('.img-product-availability img');


                    // Update first row if empty
                    if (firstTdNameProduct && firstTdQte && !firstTdNameProduct.textContent && !firstTdQte.textContent) {
                        firstTdNameProduct.textContent = searchInputField;
                        firstTdQte.textContent = qte;
                        firstRow.setAttribute('data-sku', sku);

                        if (dataArticleDeadline) {
                            firstRow.setAttribute('data-article-deadline', dataArticleDeadline);
                            firstRow.setAttribute('data-deadline-th', deadlineTh);
                            firstRow.setAttribute('data-product-type', productType);
                        }

                        if (firstImgElement && imageUrl) {
                            firstImgElement.setAttribute('src', imageUrl);
                        }

                    } else {
                        // Clone first row and create a new entry
                        let newRow = firstRow.cloneNode(true);

                        let tdNameProduct = newRow.querySelector(`.${searchInputFieldId}`);
                        let tdQte = newRow.querySelector(`.${qteId}`);
                        let imgElement = newRow.querySelector('.img-product-availability img');

                        if (tdQte) {
                            tdQte.textContent = qte;
                        }
                        if (tdNameProduct) {
                            tdNameProduct.textContent = searchInputField;
                        }

                        newRow.setAttribute('data-sku', sku);
                        if (dataArticleDeadline) {
                            newRow.setAttribute('data-article-deadline', dataArticleDeadline);
                            newRow.setAttribute('data-deadline-th', deadlineTh);
                            newRow.setAttribute('data-product-type', productType);
                        }
                        if (imgElement && imageUrl) {
                            imgElement.setAttribute('src', imageUrl);
                        }
                        tableBody.insertBefore(newRow, tableBody.firstChild);

                        // Force reflow to apply changes
                        newRow.offsetHeight;
                    }

                    resetFormFields();
                }
                addProduct();
                editTd();
                enableBtnSimulation();
                toggleIconsTableVisibility();
            });
        }

        function resetFormFields() {
            qteField.value = "";
            searchInput.value = "";
            searchInput.setAttribute('data-value', '');
            btn_submit.setAttribute('data-article-deadline', '');
            btn_submit.disabled = true;
        }

        function enableBtnSimulation() {
            const rows = document.querySelectorAll('.tr-table');
            let hasValidRow = false;

            rows.forEach(row => {
                const nameProduct = row.querySelector('.availability_product_form_availability_product');
                const qte = row.querySelector('.availability_product_form_quantity_product');

                if (nameProduct && qte &&
                    nameProduct.textContent.trim() !== '' &&
                    qte.textContent.trim() !== '') {
                    hasValidRow = true;
                }
            });
            if (btnOrderSimulation) {
                btnOrderSimulation.disabled = !hasValidRow;
            }
        }
        function editTd() {
            document.addEventListener('click', function (event) {
                const target = event.target.closest('.edit-on-click');
                if (target) {
                    let parentRow = target.closest('.tr-table');

                    if (iconsTables){
                        iconsTables.classList.add('d-none');
                    }

                    if (!parentRow) {
                        console.warn('No parent .tr-table found!');
                        return;
                    }

                    const quantityField = parentRow.querySelector('.availability_product_form_quantity_product');
                    const inputQte = parentRow.querySelector('.input-qte');
                    const modifyButton = parentRow.querySelector('.btn-modify-qte');
                    //const modifyButton = parentRow.querySelector('td .btn-modify-qte');

                    const emptyDateTd = parentRow.querySelector('.empty-date')?.closest('td');
                    const inputElement = parentRow.querySelector('.input-qte input');
                    if (btnOrderSimulation) {
                        btnOrderSimulation.disabled = true;
                    }
                    if (quantityField && inputQte && modifyButton && inputElement) {
                        quantityField.classList.add('d-none');

                        inputQte.classList.remove('d-none');
                        modifyButton.classList.remove('d-none');

                        if (emptyDateTd) {
                            emptyDateTd.classList.add('d-none');
                        }

                        inputElement.value = quantityField.textContent.trim();
                    }
                    else {
                        console.warn('Some elements are missing in the row:', parentRow);
                    }
                    const quantityText = parentRow.querySelector('.availability_product_form_quantity_product').textContent.trim();
                    const quantityInput = parentRow.querySelector('.input-qte input').value.trim();
                    const validateBtn = parentRow.querySelector('.btn-validate-qte');

                    if (quantityText === quantityInput) {
                        validateBtn.disabled = true;
                    }
                }
                const inputQte = document.querySelectorAll('.input-qte input');
                if (inputQte) {
                    inputQte.forEach(input => {
                        input.addEventListener('input', function () {
                            let value = this.value;
                            const originalQuantity = this.closest('.tr-table')
                                .querySelector('.availability_product_form_quantity_product')
                                .textContent.trim();

                            value = value.replace(/\D/g, '');

                            if (value.length > 2) {
                                value = value.slice(0, 2);
                            }

                            let number = parseInt(value, 10);

                            if (isNaN(number) ||
                                number === 0 ||
                                number > 50 ||
                                number === parseInt(originalQuantity)) { // Check if value
                                this.classList.add('error-border');
                                this.closest('.tr-table').querySelector('.btn-validate-qte').disabled = true;
                            } else {
                                this.classList.remove('error-border');
                                this.closest('.tr-table').querySelector('.btn-validate-qte').disabled = false;
                            }

                            this.value = value;
                        });
                    });

                }
            });

            const btnValidateQte = document.querySelectorAll('.btn-validate-qte');
            if (btnValidateQte) {
                btnValidateQte.forEach(btnValidate => {
                    btnValidate.addEventListener('click', function (e) {
                        e.preventDefault();

                        if (iconsTables) {
                            iconsTables.classList.remove('d-none');
                        }


                        this.closest('.btn-modify-qte').classList.add('d-none');
                        let parentTd = this.closest('.tr-table');
                        parentTd.querySelector('.estimated-date').classList.remove('d-none');
                        let inputQteValue = parentTd.querySelector('.input-qte');
                        let qteValue = parentTd.querySelector('.availability_product_form_quantity_product');
                        qteValue.textContent = inputQteValue.querySelector('input').value;
                        qteValue.classList.remove('d-none');
                        inputQteValue.classList.add('d-none');
                        updateStatus();
                        enableBtnSimulation();
                    });
                });
            }

            const btnCancelQte = document.querySelectorAll('.btn-cancel-update');
            if (btnCancelQte) {
                btnCancelQte.forEach(btnCancel => {
                    btnCancel.addEventListener('click', function (e) {
                        e.preventDefault();

                        if (iconsTables){
                            iconsTables.classList.remove('d-none');
                        }

                        this.closest('.btn-modify-qte').classList.add('d-none');
                        let parentTdCancel = this.closest('.tr-table');
                        parentTdCancel.querySelector('.estimated-date').classList.remove('d-none');
                        let inputQteValue = parentTdCancel.querySelector('.input-qte');
                        let qteValue = parentTdCancel.querySelector('.availability_product_form_quantity_product');
                        qteValue.classList.remove('d-none');
                        inputQteValue.classList.add('d-none');
                        enableBtnSimulation();

                    });
                });
            }


            document.addEventListener('click', function (event) {
                const btnRemove = event.target.closest('.red-trash');

                if (btnRemove) {
                    event.preventDefault();
                    const row = btnRemove.closest('.tr-table');
                    if (row) {
                        row.remove();
                    }

                    const tableBody = document.querySelector('.search-available-product tbody');
                    const remainingRows = document.querySelectorAll('.tr-table');

                    if (remainingRows.length === 0) {
                        const table = document.querySelector('.table-available-product');
                        if (table) {
                            table.classList.add('d-none');
                        }

                        if (tableBody && originalTbody) {
                            tableBody.replaceWith(originalTbody.cloneNode(true));
                            toggleIconsTableVisibility();
                        }
                    }
                }
            });

            const resetTable = document.querySelector('.table-available-product .reset-table');

            if (resetTable) {
                resetTable.addEventListener('click', function (event) {
                    event.preventDefault();
                    const popUpReset = document.querySelector('.reset-table-product .PopupDelete');

                    if (!popUpReset) return;

                    popUpReset.setAttribute('aria-hidden', 'false');

                    const btnConfirmReset = popUpReset.querySelector('.PopupDelete--actions a');

                    const handleConfirm = function (e) {
                        e.preventDefault();

                        if (loaderAvailabilityProduct) {
                            loaderAvailabilityProduct.classList.add('d-block');
                        }

                        setTimeout(() => {
                            if (loaderAvailabilityProduct) {
                                loaderAvailabilityProduct.classList.remove('d-block');
                            }

                            const tableBody = document.querySelector('.search-available-product tbody');
                            const table = resetTable.closest('.table-available-product');

                            if (tableBody) {
                                const rows = tableBody.querySelectorAll('.tr-table');
                                rows.forEach(row => row.remove());

                                if (originalTbody) {
                                    tableBody.replaceWith(originalTbody.cloneNode(true));
                                    toggleIconsTableVisibility();
                                }
                            }

                            if (table) {
                                table.classList.add('d-none');
                            }

                            popUpReset.setAttribute('aria-hidden', 'true');

                            btnConfirmReset.removeEventListener('click', handleConfirm);
                        }, 500);
                    };

                    btnConfirmReset.addEventListener('click', handleConfirm, {once: true});
                });
            }

            const btnAddRefMob = document.querySelector('.btn-add-ref-mob');
            if (btnAddRefMob) {
                btnAddRefMob.addEventListener('click', function (event) {
                    event.preventDefault();
                    let parentDiv = event.target.closest('.search-bloc');
                    let searchForm = parentDiv.querySelector('.search-form');
                    searchForm.classList.add('open');
                    document.querySelector('main').style.zIndex = '99999999999';
                });
            }
            const btnCloseRefMob = document.querySelector('.btn-close-ref-mob');
            if (btnCloseRefMob) {
                btnCloseRefMob.addEventListener('click', function (event) {
                    event.preventDefault();
                    let parentDiv = event.target.closest('.search-bloc');
                    let searchForm = parentDiv.querySelector('.search-form');
                    searchForm.classList.remove('open');
                    document.querySelector('main').style.zIndex = '9999';

                });
            }

            const searchFormSubmit = document.querySelector('.search-form-submit');
            if (searchFormSubmit) {
                searchFormSubmit.addEventListener('click', function (event) {
                    if (searchFormSubmit.disabled) return;
                    event.preventDefault();
                    let parentDiv = event.target.closest('.search-bloc');
                    let searchForm = parentDiv.querySelector('.search-form');
                    searchForm.classList.remove('open');
                    document.querySelector('main').style.zIndex = '9999';

                });
            }
        }

        editTd();


        let inputAdress = document.querySelector('.input-adress');
        let spanAdress = document.querySelector('.span-address');
        let okButton = document.querySelector(".part-edit .validate-modify-address");
        let canceledModifyAddress = document.querySelector(".part-edit .canceled-modify-address");
        let addressDropdown = null;
        let errorMessage = null;
        let debounceTimer;

        if (canceledModifyAddress){
            canceledModifyAddress.addEventListener('click', function (event) {
                event.preventDefault();

                let parentDivCanceled = event.target.closest('.part-edit');
                parentDivCanceled.classList.add('d-none');
                if (inputAdress && spanAdress){
                    inputAdress.value = spanAdress.textContent;
                    document.querySelector('.part-display').classList.remove('d-none');
                }
            });
        }
        function updateInputAddress() {
            if (inputAdress) {
                addressDropdown = document.createElement('div');
                addressDropdown.className = 'address-dropdown d-none';
                inputAdress.parentNode.insertBefore(addressDropdown, inputAdress.nextSibling);

                errorMessage = document.createElement('div');
                errorMessage.className = 'error-message text-danger mt-2 mb-3 d-none';
                errorMessage.textContent = 'Veuillez sélectionner une adresse parmi celles proposées.';
                inputAdress.parentNode.insertBefore(errorMessage, addressDropdown.nextSibling);

                inputAdress.addEventListener('input', function () {
                    const valueInput = this.value.trim();
                    const dataValue = this.getAttribute('data-value')?.trim().toLowerCase() || '';

                    okButton.disabled = valueInput.toLowerCase() !== dataValue;

                    if (valueInput.toLowerCase() === '') {
                        showError();
                    }

                    clearTimeout(debounceTimer);

                    if (valueInput.length === 0) {
                        hideDropdown();
                        hideError();
                        inputAdress.classList.remove('error-border');
                        return;
                    }

                    // Only search if input has 4 or more characters
                    if (valueInput.length >= 4) {
                        // Debounce the search
                        debounceTimer = setTimeout(() => {
                            searchAddress(valueInput);
                            inputAdress.value = valueInput; // Dynamically set the value
                        }, 300); // Wait 300ms after user stops typing
                    } else {
                        hideDropdown();
                        hideError();
                    }
                });


                // Close dropdown when clicking outside
                document.addEventListener('click', function (event) {
                    if (!inputAdress.contains(event.target) && !addressDropdown.contains(event.target)) {
                        hideDropdown();
                    }
                });
            }
        }


        async function searchAddress(address) {
            try {
                const response = await fetch(`/dqe-search-address/${encodeURIComponent(address)}`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                    },
                });

                if (!response.ok) {
                    showError();
                }

                const dataArray = await response.json();
                const data = Object.values(dataArray);

                if (data.length === 0) {
                    showError();
                } else {
                    displayResults(data);
                }

            } catch (error) {
                console.error('Erreur lors de la recherche d\'adresse :', error);
                hideDropdown();
                hideError();
            }
        }

        function displayResults(results) {
            if (!addressDropdown) return;
            addressDropdown.innerHTML = '';

            const ul = document.createElement('ul');
            ul.className = 'address-suggestions list-unstyled m-0 p-2 border rounded bg-white';

            results.forEach(result => {
                const li = document.createElement('li');
                li.className = 'suggestion-item p-2 cursor-pointer hover:bg-gray-100';
                li.setAttribute('data-postal', result['codePostal']); // New line
                let dataResult = result['numero'] + ' ' + result['typeVoie'] + ' ' + result['voie']
                    + ' - ' + result['codePostal'] + ' - ' + result['localite'];
                li.textContent = dataResult;

                li.addEventListener('click', () => {
                    if (inputAdress) {
                        const postalCode = li.getAttribute('data-postal');
                        inputAdress.value = dataResult;
                        inputAdress.classList.remove('error-border');
                        inputAdress.setAttribute('data-value', dataResult);
                        inputAdress.setAttribute('data-postal', postalCode);
                        if (spanAdress) {
                            spanAdress.textContent = dataResult;
                        }
                        if (okButton) {
                            okButton.disabled = false;
                        }
                        hideDropdown();
                        hideError();
                    }
                });

                ul.appendChild(li);
            });

            addressDropdown.appendChild(ul);
            addressDropdown.classList.remove('d-none');
            hideError();
        }

        function showError() {
            if (inputAdress && errorMessage) {
                inputAdress.classList.add('error-border');
                errorMessage.classList.remove('d-none');
                if (okButton) {
                    okButton.disabled = true;
                }

                hideDropdown();
            }
        }

        function hideError() {
            if (errorMessage) {
                errorMessage.classList.add('d-none');
                if (inputAdress) {
                    inputAdress.classList.remove('error-border');
                }
            }
        }

        function hideDropdown() {
            if (addressDropdown) {
                addressDropdown.classList.add('d-none');
                addressDropdown.innerHTML = '';
            }
        }


        let editIcon = document.querySelector(".part-display img");
        if (editIcon) {
            editIcon.addEventListener("click", function () {
                if (okButton) {
                    okButton.disabled = true;
                }
                let partDisplay = this.closest(".part-display");
                let partEdit = partDisplay.nextElementSibling;
                let input = partEdit.querySelector("input");

                if (partDisplay && partEdit) {
                    partDisplay.classList.add("d-none");
                    partEdit.classList.remove("d-none");
                    input.classList.remove("w-25")
                    input.classList.add("w-75")
                    input.closest('.part-edit').classList.add("w-75")
                    input.focus();
                }
                updateInputAddress();
            });
        }

        if (okButton) {
            okButton.addEventListener("click", function (event) {
                event.preventDefault();

                let partEdit = this.closest(".part-edit");
                let partDisplay = partEdit.previousElementSibling;
                let input = partEdit.querySelector("input");
                let span = partDisplay.querySelector("span");

                if (input && span) {
                    span.textContent = input.value;
                }

                input.classList.remove("w-50");
                input.classList.add("w-25");

                partEdit.classList.add("d-none");
                partDisplay.classList.remove("d-none");
            });
        }

        function downloadPdfAvailabilityPr() {
            let downloadPdfAvailabilityProduct = document.querySelector(".download-pdf-availability-product");

            if (downloadPdfAvailabilityProduct) {
                downloadPdfAvailabilityProduct.addEventListener('click', function(event) {
                    event.preventDefault();
                    document.querySelector('.loading-dragon.loader-availability-product').classList.add('d-block');

                    // Move table data collection inside the click event
                    const tableData = [];
                    document.querySelectorAll('.search-available-product .tr-table').forEach((row, index) => {
                        const productLabel = row.querySelector('.availability_product_form_availability_product')?.textContent.trim() || '';
                        const quantity = row.querySelector('.availability_product_form_quantity_product')?.textContent.trim() || '';
                        const estimatedDate = row.querySelector('.empty-date')?.textContent.trim() || '';

                        tableData.push([
                            productLabel,
                            quantity,
                            estimatedDate
                        ]);
                    });

                    let addressPdf = document.querySelector('.span-address');
                    let pdfParams = {
                        address: addressPdf.textContent,
                        tableData: tableData
                    };

                    // Make the API call
                    fetch('/generate-availability-product-pdf', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(pdfParams)
                    })
                        .then(response => {
                            if (!response.ok) {
                                throw new Error('Network response was not ok.');
                            }
                            return response.blob();
                        })
                        .then(blob => {
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            a.download = `disponibilité_produit.pdf`;
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                        })
                        .catch(error => {
                            console.error(error);
                        })
                        .finally(() => {
                            document.querySelector('.loading-dragon.loader-availability-product').classList.remove('d-block');
                        });
                });
            }
        }

        downloadPdfAvailabilityPr();
        function orderSimulation() {
            const dashboard = document.querySelector('.Dashboard--availability-product');
            if (!dashboard) return;

            dashboard.addEventListener('click', function (e) {
                const btn = e.target.closest('.btn-order-simulation button');

                if (!btn) return;
                if (btn){
                    e.preventDefault();

                    const inputAddress = document.querySelector('.input-adress');
                    if (!inputAddress) {
                        console.error('Address input element not found');
                        return;
                    }

                    const postalCode = inputAddress.getAttribute('data-postal');
                    if (!postalCode) {
                        console.warn('No postal code found');
                        return;
                    }

                    const loader = document.querySelector('.loading-dragon.loader-availability-product');
                    if (loader) {
                        loader.classList.add('d-block');
                    }

                    const tableRows = document.querySelectorAll('.search-available-product .tr-table');
                    if (!tableRows.length) {
                        console.warn('No product rows found');
                        if (loader) loader.classList.remove('d-block');
                        return;
                    }

                    const tableData = Array.from(tableRows).map(row => ({
                        libelle: row.querySelector('.availability_product_form_availability_product')?.textContent.trim() || '',
                        qte: row.querySelector('.availability_product_form_quantity_product')?.textContent.trim() || '',
                        status: row.querySelector('.empty-date')?.textContent.trim() || '',
                        sku: row.dataset.sku || '',
                        dataArticleDeadline: row.dataset.articleDeadline || '',
                        deadlineTh: row.dataset.deadlineTh || '',
                        productType: row.dataset.productType || '',
                    }));

                    const tableParams = {
                        tableData,
                        postalCode
                    };

                    fetch('/order-simulation', {
                        method: 'POST',
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify(tableParams)
                    })
                        .then(response => {
                            if (!response.ok) {
                                throw new Error(`Network response error: ${response.status} ${response.statusText}`);
                            }
                            return response.json();
                        })
                        .then(data => {
                            if (data && Array.isArray(data)) {
                                data.forEach(item => {
                                    if (item && item.sku && item.deliveryDate) {
                                        updateDeliveryDate(item);
                                    } else if (Array.isArray(item)) {
                                        item.forEach(subItem => {
                                            if (subItem && subItem.sku && subItem.deliveryDate) {
                                                updateDeliveryDate(subItem);
                                            }
                                        });
                                    }
                                });
                            }

                            function updateDeliveryDate(item) {
                                const row = document.querySelector(`.tr-table[data-sku="${item.sku}"]`);
                                if (row) {
                                    const emptyDateElement = row.querySelector('.empty-date');
                                    if (emptyDateElement) {
                                        emptyDateElement.textContent = item.deliveryDate;
                                    }
                                }
                            }

                        })
                        .catch(error => {
                            console.error('Error in order simulation:', error);
                        })
                        .finally(() => {
                            if (loader) {
                                loader.classList.remove('d-block');
                            }

                            // Show download button and notice after processing
                            const selectors = ['.download-pdf-availability-product', '.results-search--notice'];
                            selectors.forEach(selector => {
                                const element = document.querySelector(selector);
                                if (element) {
                                    element.classList.remove('d-none');
                                }
                            });
                        });
                }
            });
        }

    }
});
