import {setCookie, createElementFromHTML, getCookie, deleteCookie} from '../../scripts/utilities';

/**
 * This class manages a login popup over a link or a button.
 * When the user click on the link and is not logged in, it opens a popup with login and registration button.
 *
 * The registration button opens a popup to the registration page. A cookie "thermor_pro_registration_popup" is created
 * and is used to know when the user has finished registration process (when value = "completed"). It allows to redirect
 * the user to the login page after registration and when he has closed the popup.
 */
class PopupLoginAdvanced {

    constructor(linkElement) {
        this.registrationSharedCookieName = 'thermor_pro_registration_popup';
        if (linkElement.dataset.popupLogin === undefined) {
            console.error('Missing data-popup-login attribute on link');
            return;
        }
        this.linkElement = linkElement;
        this.init();
    }

    async init() {
        if (this.isUserConnected()) {
            return; // do nothing
        }

        // Removes all existing js events over the element
        let newElement = this.linkElement.cloneNode(true);
        this.linkElement.replaceWith(newElement);
        this.linkElement = newElement;

        await fetch(Routing.generate('thermor_pro_advanced_login_popup'))
            .then(response => response.text())
            .then(html => {
                this.popupHtml = html;
            })
            .catch(error => {
                console.log(error);
            });

        this.linkElement.addEventListener('click', (event) => {
            event.preventDefault();
            this.open();
        });
    }

    /**
     * Opens the popup
     */
    open() {
        this.popupElement = createElementFromHTML(this.popupHtml);
        document.body.appendChild(this.popupElement);
        this.popupElement.setAttribute('aria-hidden', 'false');
        this.popupElement.setAttribute('data-popup-state', 'opened');
        document.querySelector('body').classList.add('popupOverlay');
        this.popupElement.querySelector('.PopupLoginAdvanced-close').addEventListener('click', (event) => {
            this.close();
        });

        // Saving target path in session in order the user to be redirected on this URL after login
        let targetPath = this.getTargetPath();
        if (targetPath !== null) {
            let postData = new FormData();
            postData.append('target', targetPath);
            fetch(Routing.generate('thermor_pro_set_target_path'), {method: 'POST', body: postData});
        }

        this.popupElement.querySelector('.PopupLoginAdvanced-createAccountButton').addEventListener('click', (event) => {
            event.preventDefault();
            setCookie(this.registrationSharedCookieName, 'started', false, '/');

            let popupWidth = (screen.width >= 900)? 900 : screen.width;
            let popupHeight = (screen.height >= 900)? 900 : screen.height;
            let popupLeft = (screen.width - popupWidth) / 2;
            let popupTop = (screen.height - popupHeight) / 4;

            let frame = window.open(
                event.target.href,
                "Création de compte",
                'width='+popupWidth+',height='+popupHeight+',top='+popupTop+',left='+popupLeft
            );

            // Checking when the popup is closed in order to redirect the user to the login page
            let interval = window.setInterval(() => {
                if (frame.closed !== false) { // !== is required for compatibility with Opera
                    window.clearInterval(interval);

                    let registrationCookieValue = getCookie(this.registrationSharedCookieName);
                    deleteCookie(this.registrationSharedCookieName, false, '/');

                    // case where the user has completed his registration => redirecting to the login page
                    if (registrationCookieValue === 'completed') {
                        let loginLink = this.popupElement.querySelector('.PopupLoginAdvanced-loginButton');
                        if (loginLink !== null) {
                            window.location.href = loginLink.href;
                        }
                    }
                }
            }, 200);
        });
    }

    /**
     * Closes the popup
     */
    close() {
        this.popupElement.setAttribute('aria-hidden', 'true');
        this.popupElement.setAttribute('data-popup-state', 'closed');
        document.querySelector('body').classList.remove('popupOverlay');
        document.querySelector('body').removeChild(this.popupElement);
        this.popupElement = null;
    }

    isUserConnected() {
        return document.querySelector('.FullHeader').classList.contains('user-connected');
    }

    getTargetPath() {
        if (this.linkElement.href !== undefined) {
            if (this.linkElement.href.indexOf("http://") == 0
                || this.linkElement.href.indexOf("https://") == 0
                || this.linkElement.href.indexOf("/") == 0) {
                return this.linkElement.href;
            }
            return window.location.origin + window.location.pathname + '#' + this.linkElement.href;
        }
        else if (this.linkElement.dataset.popupLink !== undefined) {
            return window.location.origin + window.location.pathname + '#' + this.linkElement.dataset.popupLink;
        }

        return null;
    }

}

/**
 * Searches for links or buttons with data-popup-login="on" attribute and initialize popup objects for each one.
 */
function initializePopupItems()
{
    document.querySelectorAll('[data-popup-login=on]').forEach((element) => {
        let popup = new PopupLoginAdvanced(element);
    });
}

/**
 * When the user reaches the registration confirmation page, this function tests if the cookie "thermor_pro_registration_popup"
 * exists and replaces the return link by a link which closes the pupup. In addition, set the cookie value to "completed"
 * to indicate the user has completed the registration process.
 */
function searchAndReplaceRegistrationReturnLink()
{
    let registrationReturnLink = document.querySelector('.RegisterConfirmationReturnLink');
    if (registrationReturnLink) {
        let registrationCookieValue = getCookie('thermor_pro_registration_popup');
        if (registrationCookieValue !== null) {
            registrationReturnLink.innerText = 'Retour';
            registrationReturnLink.addEventListener('click', (event) => {
                event.preventDefault();
                window.close();
            });

            if (registrationCookieValue === 'started') {
                setCookie('thermor_pro_registration_popup', 'completed', false, '/');
            }
        }
    }
}

/**
 * Main function to initialize the login popup feature. To be called at page load.
 */
export default function initAdvancedLoginPopup()
{
    initializePopupItems();
    searchAndReplaceRegistrationReturnLink();
}
