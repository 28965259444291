import {initPhoneButtonsClick} from '../cardstation/cardstation'
(function() {
    let bounds = null;
    let lastWindow = null;

    const mapCanvas = document.querySelector('[data-map-canvas]');
    const widgetMap = document.querySelector('[data-map]');

    const inputSearch = document.querySelector('[data-map-search]');
    if (!mapCanvas && mapCanvas == null) {
        return;
    }

    var map = null;
    var stationsMarkers = [];

    const importer = {
        url: (url) => {
            return new Promise((resolve, reject) => {
                let script = document.createElement('script');
                script.type = 'text/javascript';
                let apiKey = document.querySelector('[data-map-key]').dataset.mapKey;
                script.src = url + '&key=' + apiKey;
                script.addEventListener('load', () => resolve(script), false);
                script.addEventListener('error', () => reject(script), false);
                document.body.appendChild(script);
            });
        },
        urls: (urls) => {
            return Promise.all(urls.map(importer.url));
        }
    };

    importer.url(['https://maps.googleapis.com/maps/api/js?libraries=places']).then(() => {
        autocompleteInit();

        // Load more station
        document.querySelector('[data-btn="see-more"]').addEventListener("click", function(event) {
            document.querySelector('[data-map-page]').dataset.mapPage = parseInt(document.querySelector('[data-map-page]').dataset.mapPage) + 1;
            getStations();
        });

    }).catch(() => {
        console.log("Promise Googleapi load Rejected");
    });


    function autocompleteInit() {
        var options = {
            types: ['(regions)'],
            componentRestrictions: {
                country: 'fr'
            }
        }
        new google.maps.places.Autocomplete(inputSearch, options);

        inputSearch.addEventListener('place_changed', function(event) {
            document.querySelector('[data-map-page]').dataset.mapPage = 1;
                // Get Station
            getStations();
        });

        document.querySelector('[data-storelocator]').addEventListener("submit", function(event) {
            event.preventDefault();
            document.querySelector('[data-map-page]').dataset.mapPage = 1;
                // Get Station
            getStations();
        });
    }

    function getStations() {
        var value = inputSearch.value.trim();
        var page = document.querySelector('[data-map-page]').dataset.mapPage;
        var filterPump = document.querySelector('[data-map-search-pump]').value;

        if (value == '') {
            return;
        }

        fetch("/get-stations/" + value + "/" + page + "/" + filterPump).then(function (response){
            response.json().then(function(json) {
                if (json["totalResultStations"] > 0) {

                    // Hiding empty result message
                    if (document.getElementById('MapEmptyResultMessage') !== null) {
                        document.getElementById('MapEmptyResultMessage').setAttribute('aria-hidden', 'true');
                    }

                    // traitement du JSON
                    /* Build Markers icons */
                    const markerIcon = {
                        url: '/bundles/thermorprosite/icons/storelocator.svg',
                        scaledSize: new google.maps.Size(50, 50)
                    }

                    if (page == 1) {
                        deleteAllMarkers();
                        initMap(json["markers"]);

                        // Add marker of search adresse :
                        const searchAddressCords = json["searchAddressCords"];
                        const position = new google.maps.LatLng(searchAddressCords.split(',')[0], searchAddressCords.split(',')[1]);
                        const markerIconSearch = {
                            url: '/bundles/thermorprosite/icons/arrowdownred.svg',
                            scaledSize: new google.maps.Size(50, 50)
                        }
                        const marker = new google.maps.Marker({
                            icon: markerIconSearch,
                            map: map,
                            position: position
                        });

                        bounds.extend(position);
                        map.fitBounds(bounds);
                        stationsMarkers.push(marker);
                        document.querySelector('[data-stations]').innerHTML = json["render"];
                        document.querySelector('.Map-wrapper').setAttribute('aria-hidden', 'false')
                        document.querySelector('[data-stations]').setAttribute('aria-hidden', 'false')

                    } else {
                        const stationMarkers = getAllMarkers(json["markers"], markerIcon);
                        document.querySelector('.Container-flex--wrap').insertAdjacentHTML('beforeend', json["render"]);
                    }

                    initPhoneButtonsClick();

                    // SEE MORE
                    const seeMoreButton = document.querySelector('[data-btn=see-more]');
                    if (json["totalResultStations"] > json["nbStationPerPage"] * page) {
                        seeMoreButton.setAttribute('aria-hidden', 'false')
                        seeMoreButton.parentNode.style.display = 'flex';
                    } else {
                        seeMoreButton.setAttribute('aria-hidden', 'true')
                        seeMoreButton.parentNode.style.display = 'none';
                    }
                } else {
                    if (json["error"] !== null) {
                        console.log(json["error"]);
                    }

                    deleteAllMarkers();
                    initMap([]);

                    // Add marker of search adresse :
                    const searchAddressCords = json["searchAddressCords"];
                    const position = new google.maps.LatLng(searchAddressCords.split(',')[0], searchAddressCords.split(',')[1]);
                    const markerIconSearch = {
                        url: '/bundles/thermorprosite/icons/arrowdownred.svg',
                        scaledSize: new google.maps.Size(50, 50)
                    }
                    const marker = new google.maps.Marker({
                        icon: markerIconSearch,
                        map: map,
                        position: position
                    });

                    bounds.extend(position);
                    map.fitBounds(bounds);

                    document.querySelector('.Map-wrapper').setAttribute('aria-hidden', 'false');
                    document.querySelector('[data-stations]').innerHTML = "<div></div>";
                    document.querySelector('[data-btn=see-more]').setAttribute('aria-hidden', 'true');
                    const seeMoreButton = document.querySelector('[data-btn=see-more]');
                    seeMoreButton.parentNode.style.display = 'none';

                    // Showing empty result message
                    if (document.getElementById('MapEmptyResultMessage') !== null) {
                        document.getElementById('MapEmptyResultMessage').setAttribute('aria-hidden', 'false');
                    }
                }

            });
        });
    }

    /* Building map */
    function initMap(stations) {

        /* Build Markers icons */
        const markerIcon = {
            url: '/bundles/thermorprosite/icons/storelocator.svg',
            scaledSize: new google.maps.Size(50, 50)
        }
        if (map == null) {
            /* Build map options */
            const mapOptions = {
                streetViewControl: false,
                maxZoom: 15,
                minZoom: 2,
                zoom: 6
            };

            /* Build final config */
            const mapConfig = {
                options: mapOptions,
                icon: markerIcon
            }

            /* Initialize Map */
            map = new google.maps.Map(mapCanvas, mapConfig.options);
        }
        /* Build markers */
        const stationMarkers = getAllMarkers(stations, markerIcon);
    }

    /****
     * Building all markers ready for Google Maps
     * @param stations
     * @param markerIcon, stations
     * @returns stationsMarkers*}
     */

    function getAllMarkers(stations, markerIcon) {
        bounds = new google.maps.LatLngBounds();

        stationsMarkers = stations.map(station => {

           const position = new google.maps.LatLng(station.latlng.split(',')[0], station.latlng.split(',')[1]);
           bounds.extend(position);
           const marker = new google.maps.Marker({
               icon: markerIcon,
               map: map,
               position: position,
               title: station.title,
               address: station.address,
               phone: station.tel,
               animation: google.maps.Animation.DROP,
               displayTooltip: true
           });

           /* Building content for infoWindow */
           let contentString = '<article class="CardStation CardStation--noborder"><div class="CardStation-header"><div class="CardStation-icon"><svg class="icon icon-thermorlion-thicker" aria-hidden="true" focusable="false" width="20" height="20"><use xlink:href="/bundles/thermorprosite/dist/icons.svg#icon-thermorlion-thicker"></use></svg></div><div class="CardStation-headings">' +
               '<h2 class="CardStation-title">' + station.title + '</h2>' +
               '<p class="CardStation-distance"> à ' + Math.round(station.distance * 100) / 100 + ' Km</p>' +
               '</div></div><p class="CardStation-address">' + station.address + '<br>'+ station.zipcode + ' '+ station.city +'</p>';
           if(station.tel) {
               contentString = contentString + '<button type="button" class="PrimaryButton PrimaryButton--smallest PrimaryButton--uppercase CardStation-button" data-phone="'+ station.tel + '" data-trk="{&quot;action_type&quot;:&quot;appel téléphone&quot;,&quot;action_name&quot;:&quot;clic bouton&quot;}">Afficher le numéro</button>';
           }
           contentString = contentString + '</article>';

           const infoWindow = new google.maps.InfoWindow({
               content: contentString
           });
           //add listener on domready of infowindow to attach event on phone data
           google.maps.event.addListener(infoWindow, 'domready', function() {
               let eInfoWindow = document.querySelector('.gm-style-iw');
               if (eInfoWindow) {
                   let dataPhone =  eInfoWindow.querySelectorAll('[data-phone]');
                   if (dataPhone) {
                       initPhoneButtonsClick(dataPhone);
                   }
               }
           });
           marker.addListener('click', function() {
               if(lastWindow != null) {
                   lastWindow.close(map, marker)
               }
               infoWindow.open(map, marker)

               lastWindow = infoWindow
           });


           return marker;
        });
        map.fitBounds(bounds);
        return stationsMarkers;
    }

    // Delete all markers to reset before a special search
    function deleteAllMarkers() {
        for (var i = 0; i < stationsMarkers.length; i++) {
            stationsMarkers[i].setMap(null);
        }
        stationsMarkers = [];
    }


} ());
