import { FrMap } from './fr-map.js';
import $ from 'jquery';
import Swal from 'sweetalert2';

// Ce token permet l'authentification aux endpoints mis à disposition sur thermor-events.fr
// Il est plus ici pour de l'ofuscation car thermor-events.fr n'accepte pas les requêtes cross-domain hors
// de son propre domaine ainsi que thermor.fr et thermor.com
let tokenApi = "2dcd3ac3357214287807bd93e85be4e2bfee4bc018380aab2068fb0ab40743b0dfea31d70acfd450172157bc94b37d7d64f9c93ef396c65020eeeb29";

let laMap;
var refresh_rate = 60;
var last_user_action = 0;
var has_focus = false;
var lost_focus_count = 0;
var focus_margin = 10;

function customZoomToRegion(map, code, factor) {
    var bBox = map.regions[code].element.shape.getBBox();
    var normRCX = (bBox.x + 0.5 * bBox.width) / map.defaultWidth;
    var normRCY = (bBox.y + 0.5 * bBox.height) / map.defaultHeight;
    var scale = Math.min(map.defaultWidth / bBox.width, map.defaultHeight / bBox.height);
    map.setFocus({ x: normRCX, y: normRCY, scale: scale * factor, animate: true });
}

function updateMapZoom(value) {
    if (value == "FR-20") {
        value = "FR-2A";
    }
    let mapObj = laMap.vectorMap('get', 'mapObject')
    mapObj.clearSelectedRegions();
    mapObj.setSelectedRegions(value);
    mapObj.setFocus({ scale: 1, x: 0.1, y: 0.1, animate: true });
    setTimeout(function () {
        customZoomToRegion(mapObj, value, 0.01 * 40);
    }, 700);

}
let markerIndex;
function addMarkerToMap(event, lat, lng) {
    let laMapVector = laMap.vectorMap('get', 'mapObject');
    laMapVector.removeAllMarkers();
    laMapVector.addMarker(0, {
        latLng: [lat, lng],
    });

    markerIndex += 1;
}

function refreshCheck() {
    if ((last_user_action >= refresh_rate && !has_focus && document.readyState === "complete") || lost_focus_count > focus_margin) {
        window.location.reload(); // If this is called no reset is needed
    }
}

function resetZoom() {
    let mapObj = laMap.vectorMap('get', 'mapObject').getMapObject()
    mapObj.setFocus({ scale: 1, x: 0.5, y: 0.5, animate: true });
}

function windowHasFocus() {
    has_focus = true;
}
function windowLostFocus() {
    has_focus = false;
    lost_focus_count++;
}

$(document).ready(function () {
    if ($('#map').length > 0)
    { $("#search-bar").autocomplete({
        source: function (request, response) {
            //fetch city data from backend
            fetch('https://thermor-events.fr/map-force-de-vente/recherche-ville', {
                credentials: 'omit',
                method: 'POST',
                mode: 'cors',
                headers: {
                    'X-AUTH-TOKEN': tokenApi,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ search: request.term })
            })
                .then(response => response.json())
                .then(data => {
                    response(data);
                })
                .catch(error => {
                    console.log(error);
                });
        },
        minLength: 2,
        select: function (event, ui) {
            let salesmanInfoEl = $("#salesman-info");
            let salesmanVcardEl = $("#salesman-vcard");

            $("#search-bar").val(ui.item.name + ", " + ui.item.zipCode + " - " + ui.item.region);
            $(".salesman-name").html(ui.item.salesmanName);
            salesmanInfoEl.data('salesman-id', ui.item.salesmanId);
            $(".salesman-picture").attr('src', ui.item.salesmanImage);
            salesmanVcardEl.attr('src', ui.item.salesmanVCard);
            salesmanVcardEl.parent().attr('href', ui.item.salesmanVCardLink);

            salesmanInfoEl.addClass('animate__animated animate__backInRight').show();

            updateMapZoom(ui.item.idMap)
            setTimeout(function () {
                addMarkerToMap(event, ui.item.gpsLng, ui.item.gpsLat);
            }, 700);


            return false;
        }
    }).autocomplete("instance")._renderItem = function (ul, item) {
        return $("<li>")
            .append(item.name + ", " + item.zipCode + " - " + item.region)
            .appendTo(ul);
    };

    $.fn.vectorMap('addMap', 'fr_mill', FrMap());

    let map = 'fr_mill';

    laMap = $('#map').vectorMap({
        markersSelectable: false,
        projection: 'mercator',
        markerStyle: {
            initial: {
                image: {
                    url: '/bundles/thermorprosite/images/pro/ping_thermor.png',
                    offset: [0, -30],
                }
            },
            hover: {
                stroke: 'black',
                "stroke-width": 2
            },
            selected: {
                fill: 'blue'
            },
            selectedHover: {
            }
        },
        markers: [
        ],
        onMarkerClick: function (e, code) {
            $('#salesman-info').addClass('animate__animated animate__backInRight').show();
        },
        map: map,
        zoomOnScroll: false,
        zoomAnimate: true,
        hoverOpacity: 0.7,
        panOnDrag: false,
        regionsSelectable: false,
        regionsSelectableOne: false,

        regionStyle: {
            initial: {
                fill: '#00000029',
                "fill-opacity": 1,
                stroke: 'white',
                "stroke-width": 1,
                "stroke-opacity": 1
            },
            hover: {
                "fill-opacity": 1,
                cursor: 'pointer'
            },
            selected: {
                fill: '#f58d86',
                "fill-opacity": 1,
            },
            selectedHover: {
            }
        }
    });

    // Toutes les 30 secondes, on vérifie si l'utilisateur a fait une action, sinon on rafraichit la page
    // Utile dans le cas ou la map est affiché dans un salon à disposition des visiteurs
    setInterval(function () {
        last_user_action++;
        refreshCheck();
    }, 30000);

    window.addEventListener("focus", windowHasFocus, false);
    window.addEventListener("blur", windowLostFocus, false);

    $('input.deletable').wrap('<span class="deleteicon"></span>').after($('<span>x</span>').click(function () {
        $(this).prev('input').val('').trigger('change').focus();
        $('#salesman-info').addClass('animate__animated animate__backInLeft').hide();
        $('#salesman-vcard-form').addClass('animate__animated animate__backInLeft').hide();
        $('#salesman-contact-form').addClass('animate__animated animate__backInLeft').hide();
        let laMapVector = laMap.vectorMap('get', 'mapObject');
        laMapVector.removeAllMarkers();
        laMapVector.clearSelectedRegions();
        resetZoom();
    }));

    $('#salesman-info').hide();
    $('#salesman-contact-form').hide();
    $('#salesman-vcard-form').hide();

    $('.targetToInfo').click(function () {
        $('#salesman-info').addClass('animate__animated animate__backInRight').show();
    });

    $('#targetToForm1').click(function () {
        $('#salesman-vcard-form').addClass('animate__animated animate__backInRight').show();
        $('#salesman-info').addClass('animate__animated animate__backInLeft').hide();
    });

    $('#targetToSendMessage').click(function () {
        $('#salesman-contact-form').addClass('animate__animated animate__backInRight').show();
        $('#salesman-info').addClass('animate__animated animate__backInLeft').hide();
    });

    $('#refreshPage').click(function () {
        $('#salesman-contact-form').hide();
        $('#salesman-info').show();
    });

    $(".returnToInfo").click(function () {
        $('#salesman-vcard-form').addClass('animate__animated animate__backInLeft').hide();
        $('#salesman-contact-form').addClass('animate__animated animate__backInLeft').hide();
        $('#salesman-info').addClass('animate__animated animate__backInRight').show();
    })

    $("#send-message").on('submit', function (e) {
        e.preventDefault();
        let formData = new FormData(document.getElementById('send-message'));
        formData.append('idSalesman', $("#salesman-info").data('salesman-id'));
        let plainFormData = Object.fromEntries(formData.entries());
        const formDataJsonString = JSON.stringify(plainFormData);


        fetch('https://thermor-events.fr/map-force-de-vente/contact', {
            method: 'POST', headers: {
                "Content-Type": "application/json",
                'X-AUTH-TOKEN': tokenApi,
                "Accept": "application/json"
            }, mode: 'cors', credentials: 'omit', body: formDataJsonString
        })
            .then(response => response.json())
            .then(data => {
                if (data.message) {
                    $('#salesman-contact-form').addClass('animate__animated animate__backInLeft').hide();
                    $('#salesman-info').addClass('animate__animated animate__backInRight').show();
                }

                Swal.fire({
                    title: 'L\' email a bien été envoyé',
                    showCloseButton: true,
                    customClass: {
                        popup: 'thermor-popup',
                        title: 'thermor-popup-title',
                        confirmButton: 'thermor-popup-button'
                    }
                })
            })
    });

    $("#send-v-card").on('submit', function (e) {
        e.preventDefault();
        let formData = new FormData(document.getElementById('send-v-card'));
        formData.append('idSalesman', $("#salesman-info").data('salesman-id'));
        let plainFormData = Object.fromEntries(formData.entries());
        const formDataJsonString = JSON.stringify(plainFormData);

        fetch('https://thermor-events.fr/map-force-de-vente/send-v-card', {
            method: 'POST', headers: {
                "Content-Type": "application/json",
                'X-AUTH-TOKEN': tokenApi,
                "Accept": "application/json"
            }, mode: 'cors', credentials: 'omit', body: formDataJsonString
        })
            .then(response => response.json())
            .then(data => {
                if (data.message) {
                    $('#salesman-vcard-form').addClass('animate__animated animate__backInLeft').hide();
                    $('#salesman-info').addClass('animate__animated animate__backInRight').show();
                }

                Swal.fire({
                    title: 'L\' email a bien été envoyé',
                    showCloseButton: true,
                    customClass: {
                        popup: 'thermor-popup',
                        title: 'thermor-popup-title',
                        confirmButton: 'thermor-popup-button'
                    }
                })
            })
    });
}});
