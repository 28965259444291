document.addEventListener("DOMContentLoaded", event => {
    const checkboxes = document.querySelectorAll('.filter-check-input');

    checkboxes.forEach(function(checkbox) {
        checkbox.addEventListener('change', function() {
            const checkedValues = Array.from(checkboxes)
                .filter(cb => cb.checked)
                .map(cb => cb.value);

            const params = new URLSearchParams(window.location.search);
            const existingValues = params.get('category');

            if (checkedValues.length > 0) {
                params.set('category', JSON.stringify(checkedValues));
            } else {
                params.delete('category');
            }

            window.location.href = '?' + params.toString();
        });
    });


    const filterTopLines = document.querySelectorAll('[data-element="ftlwrapper"]');
    if (filterTopLines.length < 1 || filterTopLines == null) {
        return;
    }

    filterTopLines.forEach(filterTopLine => {
        const filterTopLineFilters = filterTopLine.querySelectorAll('[data-element="ftlfilters"]')
        const filters = []
        filterTopLineFilters.forEach(filterTopLineFilter => {
            initFilterTopLine(filterTopLineFilter);
            filters.push(filterTopLineFilter.getAttribute('data-filter-id'))
        })

        const deleteFilterButton = filterTopLine.querySelector('.FilterTopLine-delete')
        if(deleteFilterButton !== null) {
            deleteFilterButton.addEventListener('click', function (e) {
                const url = new URL(window.location.href);
                filters.forEach(filter => {
                    url.searchParams.delete(filter);
                    url.searchParams.delete('category');
                    url.searchParams.delete('favorite');
                })
                url.hash = '';
                window.location.href = url.href;
            })
        }
    })

    function initFilterTopLine(filterTopLineFilters) {
        const filterList = filterTopLineFilters.querySelector('[data-element="ftllist"]')
        const filterItems = filterList.querySelectorAll('button[data-filter]')
        const filterId = filterTopLineFilters.getAttribute('data-filter-id')

        filterItems.forEach(filterItem => {
            filterItem.addEventListener('click', function (e) {
                const url = new URL(window.location.href)
                url.searchParams.delete('page')
                const filterValue = filterItem.getAttribute('name')
                let facetsValue = url.searchParams.get(filterId) // get facets params from URL

                let splitFacetsValue = new Array;

                if(facetsValue !== null) {
                    splitFacetsValue = facetsValue.split('##')
                    filterItem.setAttribute('aria-selected', 'true')
                }
                if(splitFacetsValue.indexOf(filterValue) !== -1) {
                    splitFacetsValue.splice(splitFacetsValue.indexOf(filterValue), 1);
                    filterItem.setAttribute('aria-selected', 'false')
                }
                else {
                    splitFacetsValue.push(filterValue);
                    filterItem.setAttribute('aria-selected', 'true')
                }

                if(splitFacetsValue.length !== 0) {
                    let formattedFilters = splitFacetsValue.join('##')
                    url.searchParams.set(filterId, formattedFilters)
                }
                else {
                    url.searchParams.delete(filterId)
                }

                // Addition of anchor #results when there is at least 1 active filter
                if (Array.from(url.searchParams).length > 0) {
                    url.hash = '#results'
                }
                else {
                    url.hash = ''
                }

                window.location.href = url.href;
            })
        })
    }
}, {'once': true})
