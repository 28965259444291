import $ from 'jquery'

let notifeQueteStatut = document.querySelector('.notifeQueteStatut');
if(notifeQueteStatut){
  $('.notifeQueteStatut-close').on('click', function() {
    $(this).closest('.notifeQueteStatut').attr('aria-hidden', 'false');
  });

  document.addEventListener("DOMContentLoaded", event => {
    window.onscroll = function () {
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        notifeQueteStatut.classList.add("scrolled")
      } else {
        notifeQueteStatut.classList.remove("scrolled")
      }
    };
  })
}

