import Popup from '../popup/popup'

class PopupLogin extends Popup{

    init() {
        super.init()
        this.fillLoginRedirectUrl()
    }

    fillLoginRedirectUrl() {
        const formRedirectElement = document.getElementById('login_form_redirect_url');
        if(formRedirectElement !== null) {
            formRedirectElement.value = document.location.pathname
        }
    }
}

export default PopupLogin