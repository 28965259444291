(function() {
    initMegaMenu();
    // Move in megamenu.js
}());

function initMegaMenu() {
    const elements = document.querySelectorAll('[data-nav-item]');
    const megamenu = document.querySelector('[data-menu]');

    let lastItemOpened = '';

    elements.forEach(function(element) {
        let contentControl = element.getAttribute('aria-controls');
        let contentId = contentControl ? '#' + contentControl : null;

        const megaMenuElem = document.querySelector(contentId+'.MegaMenu-item');

        if(megaMenuElem != null){

            element.addEventListener("mouseover", function (event) {
                openMegaMenu(megamenu)
                displayMegaMenuItem(contentId);
            }, false);

            element.addEventListener("mouseout", function (event) {
                closeMegaMenu(megamenu);
                hideMegaMenuItem(contentId);
            }, false);


            megaMenuElem.addEventListener("mouseover", function (event) {
                openMegaMenu(megamenu);
                displayMegaMenuItem(contentId);
            }, false);

            megaMenuElem.addEventListener("mouseout", function (event) {
                closeMegaMenu(megamenu);
                hideMegaMenuItem(contentId);
            }, false);
        }

    })


}

function openMegaMenu(menu) {
    menu.setAttribute('aria-expanded', true);
}

function closeMegaMenu(menu) {
    menu.setAttribute('aria-expanded', false);
}

function displayMegaMenuItem(id) {
    let currentId = document.querySelector(id)
    currentId.setAttribute('aria-hidden', false) // Showing mega menu content
    lastItemOpened = currentId;
}

function hideMegaMenuItem(id) {
    let currentId = document.querySelector(id)
    currentId.setAttribute('aria-hidden', true) // Hiding mega menu content
}
