const orderProductBtn = document.querySelectorAll('.ProgramCardProduct .ProgramCardProduct-order');

if(orderProductBtn) {

    orderProductBtn.forEach(function(item) {
        const orderPoppin = item.closest('.ProgramCardProduct').nextElementSibling;
        const cancelBtn = orderPoppin.querySelectorAll('.cancel-order');

        item.addEventListener('click', function() {
            orderPoppin.classList.add('is-visible');
        })

        cancelBtn.forEach(function(item) {
            item.addEventListener('click', function() {

                orderPoppin.classList.remove('is-visible');

            })
        })
    })
}