document.addEventListener("DOMContentLoaded", event => {
    const tabs = document.querySelectorAll('.Tab');
    const hash = window.location.hash;
    if (tabs == null || tabs.length < 1 || hash == "") {
        return;
        console.log('no tabs detected')
    }
    initTabs(tabs, hash);
}, {'once': true});

function initTabs(tabs, hash) {

    tabs.forEach((tab) => {
        let child = tab.childNodes[1]

        if (hash == child.getAttribute('href')) {
            child.classList.add('Tab-link--active')
        }

        child.addEventListener('click', () => {
            tabs.forEach((tab) => {
                tab.childNodes[1].classList.remove('Tab-link--active')
            })

            child.classList.add('Tab-link--active')
        })
    })

}