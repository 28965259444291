import {deleteCookie} from '../../scripts/utilities';
let urlParams = new URLSearchParams(window.location.search);

if(!urlParams.has('id')) {
    const pieces = document.querySelectorAll('.SyntheseDim');

    function getCookie (name) {
        let value = `; ${document.cookie}`;
        let parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    if(getCookie("pac_air_air_step1") !== undefined) {
        var pac_air_step1 = JSON.parse(getCookie("pac_air_air_step1"));
    }

    if(getCookie("pac_air_air_step2") !== undefined) {
        var pac_air_step2 = JSON.parse(getCookie("pac_air_air_step2"));
    }

    pieces.forEach((piece) => {
        const deleteBtn = piece.querySelector('.SyntheseDim--icon');
        deleteBtn.addEventListener('click', () => {
            const idPiece = deleteBtn.getAttribute('id');
            const idPieceNext = parseInt(idPiece) + 1;

            pac_air_step1.nb_pieces =  pac_air_step1.nb_pieces > 1 ? pac_air_step1.nb_pieces - 1 : "";

            if ( pac_air_step1.nb_pieces > 0) {
                for( var key in pac_air_step2) {
                    if(key.endsWith(idPiece)) {
                        delete pac_air_step2[key];
                    }
                    if(key.endsWith((idPieceNext).toString())) {
                        const temp = pac_air_step2[key];
                        const newKey = key.replace((idPieceNext).toString(), idPiece);
                        pac_air_step2[newKey] = temp;
                        delete pac_air_step2[key];
                    }
                }

                document.cookie = "pac_air_air_step2=" + JSON.stringify(pac_air_step2) + "; expires="+ (new Date(Date.now()+ 86400*1000)).toUTCString();
            }

            else {
                deleteCookie('pac_air_air_step2', false, '/');
            }
          
            document.cookie = "pac_air_air_step1=" + JSON.stringify(pac_air_step1) + "; expires="+ (new Date(Date.now()+ 86400*1000)).toUTCString();
            location.reload();
        })
    })
}

if(document.querySelector('.toastBlock--content-close')) {
    document.querySelector('.toastBlock--content-close').addEventListener('click', function() {
        document.querySelector('.toastBlock').style.display = "none";
    })
}