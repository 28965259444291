const formSuccessMsg = document.querySelector('#submit-success')

const init = () => {
  if (!formSuccessMsg) return

  formSuccessMsg.scrollIntoView({ behavior: 'smooth' })
}

document.addEventListener('DOMContentLoaded', () => {
  init()
})
