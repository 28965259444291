import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js"

(function() {
    const datepickers = document.querySelectorAll('[data-input=datepicker]');
    if (datepickers.length < 1) {
        return;
    }
    initDatepicker(datepickers);

    function initDatepicker(elements) {
        elements.forEach(datepicker => {
            flatpickr(datepicker, {
                dateFormat: "d/m/Y",
                locale: French
            });
        })
    }
} ());
