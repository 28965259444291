const quantityInput = document.querySelector('[data-quantity=input]');
const plusButton = document.querySelector('[data-quantity=plus]');
const minusButton = document.querySelector('[data-quantity=minus]');

if (quantityInput != undefined || quantityInput != null) {
    plusButton.addEventListener('click', event => {
        quantityInput.value = parseInt(quantityInput.value) + 1
    })
    minusButton.addEventListener('click', event => {
        if(quantityInput.value > 1) {
            quantityInput.value = parseInt(quantityInput.value) - 1
        }
    })
}

