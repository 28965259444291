import utilities from '../../scripts/utilities'

(function() {
    const forms = document.querySelectorAll('.SearchBar')
    let typingTimer

    if (forms == null || !forms) {
        return;
    }

    forms.forEach(form => {
        const searchBarInput = form.querySelector('.SearchBar-input'); // Search Input for autocomplate
        if ("searchbar" in searchBarInput.dataset) {
            searchBarAutocomplete(form);
        }
    })

    let globalSearchXhr = {
        products: null,
        documentation: null,
        assistance: null,
        services: null,

        cancel: function(name) {
            if (this[name] !== null) {
                this[name].abort();
                this[name] = null;
            }
        }
    }

    function searchBarAutocomplete(form) {
        const searchBarInput = form.querySelector('.SearchBar-input'); // Search Input for autocomplate
        const listResults = form.querySelector('.SearchBar-results') // Empty list of results, will be filled

        let searchType = 'localized'
        if (searchBarInput.dataset.searchbar !== undefined) {
            searchType = searchBarInput.dataset.searchbar
        }

        // Autocompletion has been diactivated for the homepage search bar
        if (searchType === 'homepage') {
            return;
        }

        searchBarInput.addEventListener("keyup", function (event) {
            clearTimeout(typingTimer);
            typingTimer = setTimeout(function () {
                if (searchType === 'global') { // Header search popin
                    let searchText = searchBarInput.value.trim()
                    updateSearchAssistanceButton(searchText)
                    globalPopinSearchFormAutoComplete(searchText, '/search/autocomplete/products', document.querySelector('[data-col=produit]'), 'products')
                    globalPopinSearchFormAutoComplete(searchText, '/search/autocomplete/documentation', document.querySelector('[data-col=documentation]'), 'documentation')
                    globalPopinSearchFormAutoComplete(searchText, '/search/autocomplete/assistance', document.querySelector('[data-col=assistance_pro]'), 'assistance')
                    globalPopinSearchFormAutoComplete(searchText, '/search/autocomplete/services', document.querySelector('[data-col=service_plus]'), 'services')
                }
                else {
                    autocomplete(searchBarInput, listResults)
                }
            }, 1000);
        })

        if (listResults !== null) {
            document.addEventListener("click", event => {
                if (listResults !== null && event.target !== searchBarInput && event.target !== listResults) {
                    listResults.setAttribute('aria-hidden', 'true')
                }
            })
            searchBarInput.addEventListener("focusin", event => {
                listResults.setAttribute('aria-hidden', listResults.innerHTML.trim() === "")
            })
        }
    }

    function updateSearchAssistanceButton(searchText) {
        let button = document.getElementById('PopupSearch-assistance-btn')
        let urlPattern = button?.dataset?.searchPattern
        let url = urlPattern?.replace('{searchText}', searchText)
        button?.setAttribute('href', url);
    }

    /**
     * Launches a XHR request to the backend URL and injects results in the popin column.
     *
     * @param searchText The search query text
     * @param backendUrl The backend controller URL without search text parameter
     * @param container The column element in the search popin
     * @param xhrKey Choose between products|documentation|assistance|services
     */
    function globalPopinSearchFormAutoComplete(searchText, backendUrl, container, xhrKey) {
        // Cancelling a possible XHR request already in progress
        globalSearchXhr.cancel(xhrKey)

        // Removing old results
        container.querySelector('.PopupSearch-items').innerHTML = ''

        // Search text must be 3 characters length minimum
        if (searchText.length <= 2) {
            // Disabling loader picto
            container.querySelector('.PopupSearch-Loader').style.display = 'none'
            return
        }
        let itemType = container.getAttribute('data-col');

        // Enabling loader picto
        container.querySelector('.PopupSearch-Loader').style.display = 'block'
try {
    // XHR request to the backend URL
    globalSearchXhr[xhrKey] = new XMLHttpRequest()
    globalSearchXhr[xhrKey].open('GET', backendUrl+'/'+encodeURI(searchText))
    globalSearchXhr[xhrKey].send()
    globalSearchXhr[xhrKey].onload = function () {
        // Disabling loader picto
        container.querySelector('.PopupSearch-Loader').style.display = 'none'
        let items = JSON.parse(globalSearchXhr[xhrKey].response)
        items.forEach((item) => {
            container.querySelector('.PopupSearch-items').innerHTML += '<li class="PopupSearch-item"><a class="PopupSearch-link" target="_blank" data-trk="{&quot;action_type&quot;:&quot;recherche globale&quot;,&quot;action_name&quot;:&quot;suggestion&quot;,&quot;item_type&quot;:&quot;' + itemType + '&quot;,&quot;item_name&quot;:&quot;' + item.name + '&quot;}" href="' + item.url + '">' + item.name + '</a></li>'
        })
        if (items.length === 0) {
            container.querySelector('.PopupSearch-items').innerHTML = '<li class="PopupSearch-item">Aucun résultat</li>'
        }
    }
}catch (e){
            console.log(e);
}

    }

    async function autocomplete(searchBarInput, listResults) {
        const minCharacters = 3;
        const inputValue = searchBarInput.value; // current search value
        const searchCategory = searchBarInput.dataset.searchCategory;

        if (inputValue.length < minCharacters) {
            return;
        }

        let autocompleteResults = await utilities.getData('/search/autocomplete/documentationtype/' + encodeURI(searchCategory) + '/' + encodeURI(inputValue)); // array of results

        if (autocompleteResults != undefined) {
            if (searchBarInput.dataset.searchbar == 'localized') {
                listResults.innerHTML = '' // clear list of results
                autocompleteResults.forEach(result => {

                    // Replaces search value with strong counterparts -- case insensitive
                    var regEx = new RegExp(inputValue, "ig");
                    const originalName = result.name;
                    const truncatedName = originalName.length > 50 ? originalName.substring(0, 50) + '...' : originalName;
                    const formattedResult = truncatedName.replace(regEx, "$&");

                    // Creating proper DOM elements
                    const listItem = document.createElement('li') // Element inside list of results
                    const button = document.createElement('button')// buttons that replaces search suggestion in input and submits form
                    const type = document.createElement('span')

                    type.classList.add('SearchBar-type')
                    type.setAttribute('data-trk', '{&quot;action_type&quot;:&quot;recherche document&quot;,&quot;action_name&quot;:&quot;suggestion&quot;,&quot;item_type&quot;:&quot;' + result.type + '&quot;,&quot;item_name&quot;:&quot;' + result.name + '&quot;}')
                    type.innerHTML = result.type

                    button.setAttribute('data-search-value', result.name)
                    button.innerHTML = type.outerHTML + '<span class="SearchBar-title">' + formattedResult + '</span>';
                    button.classList.add('SearchBar-link')
                    button.type = "button";
                    listItem.classList.add('SearchBar-result')
                    listItem.appendChild(button)

                    listResults.appendChild(listItem)

                    // Add event for replacing search input value and submitting
                    button.addEventListener('click', event => {
                        searchBarInput.value = button.dataset.searchValue
                        if (result.type ==='Visuel'){
                            window.open(result.url, '_blank');
                        }else {
                            window.location.href = result.url;
                        }
                    })
                })

                listResults.setAttribute('aria-hidden', listResults.innerHTML.trim() === "")
            } else {
                listResults.setAttribute('aria-hidden', 'true')
            }
        }
    }
} ())
