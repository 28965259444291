import passwordUtilities from './form_utilities'

/**
 * Manages js events on password update form.
 */
(function() {

  if (document.querySelector('[data-form=update-password]') === null) {
    return
  }

  document.querySelectorAll('[data-input=password]').forEach(password => {
    passwordUtilities.checkPassword(password)
    password.addEventListener('keyup', function () {
      passwordUtilities.checkPassword(password)
    })
  })

}());