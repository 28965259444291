import { Formio } from 'formiojs';
let apiUrl = window.location.protocol + "//" + window.location.host;
let urlParams = new URLSearchParams(window.location.search);
let formPieces = [];

function getCookie(cookieName) {
    let cookie = {};
    document.cookie.split(';').forEach(function(el) {
        let [key,value] = el.split('=');
        cookie[key.trim()] = value;
    })
    return cookie[cookieName];
}

function converToString(data) {
    Object.keys(data).forEach(k => {

        if (k !== 'submit') {
            data[k] = '' + data[k];
        }
    });

    return data;
}

const isPacAirStep2Page = document.querySelector('.pac-air-air--step2');
if (isPacAirStep2Page) {
    let utilisation = JSON.parse(getCookie("pac_air_air_step1")).utilisation;
// get default values for updating an existing project
    // get default values of formio from cookies
    if (getCookie("pac_air_air_step2") !== undefined) {
        var air_step2 = JSON.parse(getCookie("pac_air_air_step2"));
    }

    if (getCookie("pac_air_air_step1")) {
        let nbre_pieces = JSON.parse(getCookie("pac_air_air_step1")).nb_pieces;


        for (let i = 1; i <= nbre_pieces; i++) {
            formPieces.push(
                {
                    components: [

                        {
                            "input": false,
                            "html": "<span>Pièces n°" + i + "</span>",
                            "type": "content",
                            "key": "content",
                            "customClass": "red-block--title",
                        },
                        {
                            components: [
                                {
                                    "columns": [
                                        {
                                            components: [
                                                {
                                                    tabindex: "1",
                                                    type: 'textfield',
                                                    key: 'pieceName' + i,
                                                    label: 'Nom',
                                                    input: true,
                                                    defaultValue: air_step2 ? air_step2['pieceName' + i] : ''  || "",
                                                    validate: {
                                                        required: true,
                                                        maxLength: '20'
                                                    }
                                                }
                                            ],
                                            "width": 6,
                                            "offset": 0,
                                            "push": 0,
                                            "pull": 0,
                                            "size": "md",
                                            "currentWidth": 6
                                        },
                                        {
                                            components: [
                                                {
                                                    tabindex: "2",
                                                    type: "select",
                                                    label: "Type de pièce",
                                                    key: "pieceType" + i,
                                                    placeholder: "Veuillez sélectionner une option",
                                                    defaultValue: air_step2 ? air_step2['pieceType' + i] : '' || "",
                                                    data: {
                                                        values: [
                                                            {value: 'salon', label: 'Salon'},
                                                            {value: 'chambre', label: 'Chambre'},
                                                            {value: 'bureau', label: 'Bureau'},
                                                            {value: 'autre_piece', label: 'Autre Pièce'}
                                                        ]
                                                    },
                                                    validate: {
                                                        required: true
                                                    },
                                                    dataSrc: "values",
                                                    template: "<span>{{ item.label }}</span>",
                                                    input: true
                                                }
                                            ],
                                            "width": 6,
                                            "offset": 0,
                                            "push": 0,
                                            "pull": 0,
                                            "size": "md",
                                            "currentWidth": 6
                                        }
                                    ],
                                    "type": "columns",
                                    "conditional": {
                                        "show": "",
                                        "when": null,
                                        "eq": ""
                                    },
                                    "key": "columns"
                                },
                                {
                                    "columns": [
                                        {
                                            components: [
                                                {
                                                    tabindex: "1",
                                                    type: "number",
                                                    label: "Surface (en m<sup>2</sup>)",
                                                    key: "surface" + i,
                                                    suffix: ' ',
                                                    disabled: true,
                                                    defaultValue: air_step2 ? air_step2['surface' + i] :'' || "",
                                                    validate: {
                                                        required: true,
                                                        max: 100
                                                    },
                                                    input: true,
                                                    customClass: "calculatedField surface"
                                                }
                                            ],
                                            "width": 4,
                                            "offset": 0,
                                            "push": 0,
                                            "pull": 0,
                                            "size": "md",
                                            "currentWidth": 6
                                        },
                                        {
                                            components: [
                                                {
                                                    tabindex: "2",
                                                    type: "number",
                                                    label: "Hauteur sous plafond (en m)",
                                                    key: "hauteurPlafond" + i,
                                                    defaultValue: air_step2 ?  air_step2['hauteurPlafond' + i] : ''|| "",
                                                    input: true,
                                                    validate: {
                                                        required: true,
                                                        max: "100"
                                                    }
                                                }
                                            ],
                                            "width": 4,
                                            "offset": 0,
                                            "push": 0,
                                            "pull": 0,
                                            "size": "md",
                                            "currentWidth": 6
                                        },
                                        {
                                            components: [
                                                {
                                                    tabindex: "3",
                                                    type: "select",
                                                    label: "Nombre d'occupant de la pièce",
                                                    key: "pieceOccupant" + i,
                                                    placeholder: "Veuillez sélectionner une option",
                                                    defaultValue: air_step2 ? air_step2['pieceOccupant' + i] : ''  || "",
                                                    data: {
                                                        values: [
                                                            {value: '1', label: '1'},
                                                            {value: '2', label: '2'},
                                                            {value: '3', label: '3'},
                                                            {value: '4', label: '4'},
                                                            {value: '5', label: '5'},
                                                            {value: '6', label: '6'},
                                                        ]
                                                    },
                                                    validate: {
                                                        required: true
                                                    },
                                                    dataSrc: "values",
                                                    template: "<span>{{ item.label }}</span>",
                                                    input: true
                                                }
                                            ],
                                            "width": 4,
                                            "offset": 0,
                                            "push": 0,
                                            "pull": 0,
                                            "size": "md",
                                            "currentWidth": 6
                                        }
                                    ],
                                    "type": "columns",
                                    "conditional": {
                                        "show": "",
                                        "when": null,
                                        "eq": ""
                                    },
                                    "key": "columns"
                                },
                                {
                                    "columns": [
                                        {
                                            components: [
                                                {
                                                    tabindex: "1",
                                                    type: "number",
                                                    label: "Surface des fenêtres ensoleillées (en m<sup>2</sup>)",
                                                    key: "surfaceFenetres" + i,
                                                    defaultValue: air_step2 ? air_step2['surfaceFenetres' + i] :'' || "",
                                                    suffix: ' ',
                                                    disabled: true,
                                                    validate: {
                                                        required: true,
                                                        max: 100
                                                    },
                                                    input: true,
                                                    tooltip: '*Orientées Sud, Sud-Ouest, Ouest.',
                                                    description: '*Orientées Sud, Sud-Ouest, Ouest.',
                                                    customClass: "calculatedField"
                                                }
                                            ],
                                            "width": 4,
                                            "offset": 0,
                                            "push": 0,
                                            "pull": 0,
                                            "size": "md",
                                            "currentWidth": 6
                                        },
                                        {
                                            components: [
                                                {
                                                    tabindex: "2",
                                                    type: "number",
                                                    label: "Surface des autres fenêtres (en m<sup>2</sup>)",
                                                    key: "autreSurface" + i,
                                                    defaultValue:  air_step2 ? air_step2['autreSurface' + i] :'' || "",
                                                    suffix: ' ',
                                                    disabled: true,
                                                    validate: {
                                                        required: true,
                                                        max: 100
                                                    },
                                                    input: true,
                                                    tooltip: '*Orientées Nord, Nord-Est, Est.',
                                                    description: '*Orientées Nord, Nord-Est, Est.',
                                                    customClass: "calculatedField"
                                                }
                                            ],
                                            "width": 4,
                                            "offset": 0,
                                            "push": 0,
                                            "pull": 0,
                                            "size": "md",
                                            "currentWidth": 6
                                        },
                                        {
                                            components: [
                                                {
                                                    type: "number",
                                                    label: "Surface des murs ensoleillés (en m<sup>2</sup>)",
                                                    key: "surfaceMurs" + i,
                                                    suffix: ' ',
                                                    disabled: true,
                                                    defaultValue: air_step2 ? air_step2['surfaceMurs' + i] :'' || "",
                                                    validate: {
                                                        required: true,
                                                        max: 100
                                                    },
                                                    input: true,
                                                    customClass: "calculatedField"
                                                }
                                            ],
                                            "width": 4,
                                            "offset": 0,
                                            "push": 0,
                                            "pull": 0,
                                            "size": "md",
                                            "currentWidth": 6
                                        }
                                    ],
                                    "type": "columns",
                                    "conditional": {
                                        "show": "",
                                        "when": null,
                                        "eq": ""
                                    },
                                    "key": "columns"
                                },
                                {
                                    "columns": [
                                        {
                                            components: [
                                                {
                                                    tabindex: "1",
                                                    label: "Ces fenêtres ensoleillées possèdent elles des volets ou des stores pouvant être fermés en fin de journée ?",
                                                    optionsLabelPosition: "right",
                                                    inline: true,
                                                    tableView: false,
                                                    values: [
                                                        {
                                                            "label": "Oui",
                                                            "value": "1"
                                                        },
                                                        {
                                                            "label": "Non",
                                                            "value": "0"
                                                        }
                                                    ],
                                                    key: "choix_volet" + i,
                                                    defaultValue: air_step2 ? air_step2['choix_volet' + i] : '0' || "0",
                                                    type: "radio",
                                                    inputType: "radio",
                                                    input: true
                                                }
                                            ],
                                            "width": 6,
                                            "offset": 0,
                                            "push": 0,
                                            "pull": 0,
                                            "size": "md",
                                            "currentWidth": 6
                                        },
                                        {
                                            components: [
                                                {
                                                    tabindex: "2",
                                                    type: "number",
                                                    label: "Température souhaitée",
                                                    key: "Temperature" + i,
                                                    defaultValue: air_step2 ? air_step2['Temperature' + i] : '',
                                                    validate: {
                                                        required: utilisation !== 'refroidir',
                                                        max: 30
                                                    },
                                                    input: true
                                                }
                                            ],
                                            "width": 6,
                                            "offset": 0,
                                            "push": 0,
                                            "pull": 0,
                                            "size": "md",
                                            "currentWidth": 6
                                        }
                                    ],
                                    "type": "columns",
                                    "conditional": {
                                        "show": "",
                                        "when": null,
                                        "eq": ""
                                    },
                                    "key": "columns"
                                }
                            ],
                            "customClass": "red-block"
                        }
                    ]
                }
            )
        }

        formPieces.push({
            type: 'button',
            action: 'submit',
            label: 'Voir la synthèse',
            theme: 'primary'
        })

        setTimeout(() => {
            if (document.querySelector(".formLoader")) document.querySelector(".formLoader").remove();
        }, 200);

        Formio.createForm(document.getElementById('pac-air-step2'), {
                components: formPieces
            },
            {
                language: 'en-US',
                decimalSeparator: '.',
                delimiter: ',',
                i18n: {
                    'en-US': {
                        required: 'Ce champ est obligatoire*',
                        max: 'Ne peut pas être supérieur à {{max}} .',
                        maxLength: 'Ne peut pas être supérieur à {{length}} caractère.',
                        'No results found': 'Aucun résultat pour cette recherche',
                        'Type to search': 'Écrivez pour lancer une recherche'
                    }
                }
            }).then(function (form) {
            let submitBtn = document.querySelector('.formio-component-submit').querySelector('button');
            let button = document.querySelectorAll(".calculatedField .input-group"),
                inputName;

            const popupWrapper = document.querySelector(".popup-wrapper");

            function setCalculatedValue(value, res) {
                form.getComponent(value).setValue(res);
            }

            if (utilisation === 'refroidir') {
                for (let i = 1; i <= nbre_pieces; i++) {
                    var inputElements = document.querySelectorAll(`.formio-component-number.formio-component-Temperature${i}`);
                if (inputElements.length > 0) {
                    inputElements.forEach(function(inputElement) {
                        inputElement.style.display = 'none';
                    });
                }
                }
            }

            button.forEach(btn => {
                btn.addEventListener('click', () => {
                    inputName = btn.querySelector("input").name.match(/(?<=\[)[^\][]*(?=])/g)[0];
                    let surfaceVal =  btn.closest('.calculatedField').classList.contains('surface');

                    Formio.createForm(document.getElementById('popupCalculator'), {
                            components: [
                                {
                                    components: [
                                        {
                                            type: 'button',
                                            action: 'event',
                                            label: ' ',
                                            theme: 'primary',
                                            customClass: 'cancel cancelBtn'
                                        },
                                        {
                                            "input": false,
                                            "html": "<p>ENTREZ LES DIMENSIONS POUR CALCULER LA SURFACE EN M<sup>2</sup></p>",
                                            "type": "content",
                                            "key": "content",
                                        },
                                        {
                                            "columns": [
                                                {
                                                    components: [
                                                        {
                                                            tabindex: "1",
                                                            type: 'number',
                                                            key: 'h',
                                                            label:  surfaceVal ? "Longueur" + ' (en m)': "Hauteur" + ' (en m)',
                                                            input: true,
                                                            inputType: 'text',
                                                            validate: {
                                                                min: surfaceVal ? 1 : 0,
                                                                max: 100,
                                                                required: true
                                                            }
                                                        },
                                                    ],
                                                    "width": 5,
                                                    "offset": 0,
                                                    "push": 0,
                                                    "pull": 0,
                                                    "size": "md",
                                                    "currentWidth": 5
                                                },
                                                {
                                                    components: [
                                                        {
                                                            tabindex: "2",
                                                            type: 'htmlelement',
                                                            tag: 'p',
                                                            className: 'text-center',
                                                            content: 'x'
                                                        }
                                                    ],
                                                    "width": 2,
                                                    "offset": 0,
                                                    "push": 0,
                                                    "pull": 0,
                                                    "size": "md",
                                                    "currentWidth": 2
                                                },
                                                {
                                                    components: [
                                                        {
                                                            tabindex: "3",
                                                            type: 'number',
                                                            label: 'Largeur (en m)',
                                                            key: 'l',
                                                            input: true,
                                                            inputType: 'text',
                                                            validate: {
                                                                min: surfaceVal ? 1 : 0,
                                                                max: 100,
                                                                required: true
                                                            }
                                                        }
                                                    ],
                                                    "width": 5,
                                                    "offset": 0,
                                                    "push": 0,
                                                    "pull": 0,
                                                    "size": "md",
                                                    "currentWidth": 5
                                                }
                                            ],
                                            "type": "columns",
                                            "conditional": {
                                                "show": "",
                                                "when": null,
                                                "eq": ""
                                            },
                                            "key": "columns",
                                        },
                                        {
                                            type: 'textfield',
                                            label: 'Résultat (en m<sup>2</sup>)',
                                            key: 'total',
                                            input: true,
                                            inputType: 'text',
                                            disabled: true,
                                            validate: {
                                                max: 100
                                            },
                                            calculateValue: {
                                                "*": [
                                                    {var: 'data.h'},
                                                    {var: 'data.l'}
                                                ]
                                            }
                                        },
                                        {
                                            "columns": [
                                                {
                                                    components: [
                                                        {
                                                            tabindex: "1",
                                                            type: 'button',
                                                            action: 'event',
                                                            label: 'Annuler',
                                                            theme: 'primary',
                                                            customClass: 'cancelBtn'
                                                        }
                                                    ],
                                                    "width": 6,
                                                    "offset": 0,
                                                    "push": 0,
                                                    "pull": 0,
                                                    "size": "md",
                                                    "currentWidth": 6
                                                },
                                                {
                                                    components: [
                                                        {
                                                            tabindex: "2",
                                                            type: 'button',
                                                            action: 'submit',
                                                            label: 'Valider',
                                                            theme: 'primary',
                                                            customClass: 'validateBtn'
                                                        }
                                                    ],
                                                    "width": 6,
                                                    "offset": 0,
                                                    "push": 0,
                                                    "pull": 0,
                                                    "size": "md",
                                                    "currentWidth": 6
                                                }
                                            ],
                                            "type": "columns",
                                            "conditional": {
                                                "show": "",
                                                "when": null,
                                                "eq": ""
                                            },
                                            "key": "columns"
                                        }
                                    ],
                                    customClass: "popup"
                                }
                            ]
                        },
                        {
                            language: 'en-US',
                            decimalSeparator: '.',
                            delimiter: ',',
                            i18n: {
                                'en-US': {
                                    required: 'Ce champ est obligatoire*',
                                    min: 'Ne peut pas être inférieur à {{min}} .',
                                    max: 'Ne peut pas être supérieur à {{max}} .',
                                    'No results found': 'Aucun résultat pour cette recherche',
                                    'Type to search': 'Écrivez pour lancer une recherche'
                                }
                            }
                        })
                        .then(function (form) {
                            let firstInputCalculatedPopin = document.querySelector('.popupCalculator').querySelector('.formio-component-h input');

                            firstInputCalculatedPopin.addEventListener('keydown', e => {

                                if(e.keyCode === 9) {
                                    e.preventDefault();
                                    let nextEl = firstInputCalculatedPopin.closest('.formio-component.formio-component-columns').querySelector('.formio-component-l input');
                                    nextEl.focus();
                                }
                            })

                            form.on('submit', function () {
                                let result = document.querySelector('input[name="data[total]"]').value;
                                popupWrapper.style.display = "none";
                                setCalculatedValue(inputName, result);
                            });

                            document.querySelectorAll('.cancelBtn').forEach(item => {
                                item.addEventListener('click', () => {
                                    popupWrapper.style.display = "none";
                                })
                            })
                        })
                    popupWrapper.style.display = "block";
                });
            });

            submitBtn.addEventListener('click', function () {
                const errors = document.querySelectorAll('.formio-errors');
                let brk = true;

                errors.forEach(error => {
                    if (error.querySelector('.form-text.error')?.isConnected && brk) {
                        error.parentNode.scrollIntoView();
                        brk = false;
                    }
                })
            })
            form.on('submit', function (submission) {
                let idDim = 0;
                let canBeSaved = true;
                let jsonSubmissionData = converToString(submission.data);
                var urlParams = new URLSearchParams(window.location.search);


                    localStorage.removeItem("SaveDim");
                    if(getCookie("pac_air_air_step2") !== undefined) {
                        var oldCookie = getCookie("pac_air_air_step2");
                        let jsonOldCookie = JSON.parse(oldCookie);
                        idDim = jsonOldCookie['idDim'];
                        delete jsonOldCookie['canBeSaved'];
                        delete jsonOldCookie['idDim'];
                        if (_.isEqual(jsonOldCookie, jsonSubmissionData)) {
                            canBeSaved = false;
                        }
                    }
                    jsonSubmissionData['canBeSaved'] = canBeSaved;
                    jsonSubmissionData['idDim'] = idDim;

                if (utilisation === 'refroidir') {
                    for (let i = 1; i <= nbre_pieces; i++) {
                        jsonSubmissionData[`Temperature${i}`] = null;
                    }
                }

                const objectString = JSON.stringify(jsonSubmissionData);
                document.cookie = "pac_air_air_step2=" + objectString + "; expires=" + (new Date(Date.now() + 3600 * 1000)).toUTCString() + "; path=/";
                //Check if the "id" parameter exists
                if (urlParams.has('id')) {
                    // Parameter exists, get its value
                    var id = urlParams.get('id');
                    window.location.href = apiUrl + "/simulhome/pac-air-air/nouvelle-installation/logement-individuel/synthese" + "?id=" + encodeURIComponent(id);
                } else {
                    // Parameter doesn't exist
                    window.location.href = apiUrl + "/simulhome/pac-air-air/nouvelle-installation/logement-individuel/synthese";
                }
            });
        });
    }
}
