import {Formio} from 'formiojs';
import {getCookie, deleteCookie} from '../../../scripts/utilities';

let apiUrl = window.location.protocol + "//" + window.location.host;
let department_pool = "";
let urlParams = new URLSearchParams(window.location.search);

setTimeout(() => {
    if(document.querySelector(".formLoader")) document.querySelector(".formLoader").remove();
}, 200);


function converToString(data) {
    Object.keys(data).forEach(k => {
   
    if (k !== 'submit') {
      data[k] = '' + data[k];
    }        
  });
  
   return data;
}


// conitional end date field

function conditionalField(val) {

    let endDateVal = [];
    let monthVal = {
        '5' : 'Mai',
        '6' : 'Juin',
        '7' : 'Juillet',
        '8' : 'Août',
        '9' : 'Septembre',
        '10': 'Octobre'
    }

    for(let key in monthVal) {
        if(parseInt(key) > parseInt(val)) {

            endDateVal.push(
                {value: key, label: monthVal[key]}
            )
        }
    }

    return endDateVal;
}
const isPacPiscineStep1 = document.querySelector('#pac-piscine-step1');
async function getDep() {
    if(getCookie("pac_piscine_step1") !== undefined && getCookie("pac_piscine_step1") !== null ) {
        var myCookie = JSON.parse(getCookie("pac_piscine_step1"));
    }
    await fetch('/dep', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    }).then(function (response) {
        return response.json();
    }).then(function (data) {

        department_pool = Object.entries(data.department).map(([key, value]) => {
            return {value: Number(key), label: value };
        }).sort((a, b) => {
            const numA = parseInt(a.label.substr(0, 2)); // Extract the numeric part
            const numB = parseInt(b.label.substr(0, 2)); // Extract the numeric part
            return numA - numB;
        });
    });

    Formio.createForm(document.getElementById('pac-piscine-step1'), {
            components: [
                {
                    components: [
                        {
                            "input": false,
                            "html": "<span>Informations générales</span>",
                            "type": "content",
                            "key": "content",
                            "customClass": "red-block--title",
                        },
                        {
                            components: [
                                {
                                    "columns": [
                                        {
                                            components: [
                                                {
                                                    tabindex: "1",
                                                    type: 'textfield',
                                                    key: 'nom',
                                                    label: 'Nom de projet',
                                                    input: true,
                                                    defaultValue: myCookie?.nom || "",
                                                    validate: {
                                                        required: true,
                                                        maxLength: '30'
                                                    }
                                                }
                                            ],
                                            "width": 6,
                                            "offset": 0,
                                            "push": 0,
                                            "pull": 0,
                                            "size": "md",
                                            "currentWidth": 6
                                        },
                                        {
                                            components: [
                                                {
                                                    type: "select",
                                                    label: "Département",
                                                    key: "departement",
                                                    defaultValue: (myCookie && myCookie.departement && myCookie.departement > 0 && myCookie.departement <= department_pool.length) ? department_pool[myCookie.departement - 1].value : '',
                                                    placeholder: "Veuillez sélectionner une option",
                                                    data: {
                                                        values: department_pool
                                                    },
                                                    validate: {
                                                        required: true
                                                    },
                                                    template: "<span>{{ item.label }}</span>",
                                                    input: true
                                                }
                                            ],
                                            "width": 6,
                                            "offset": 0,
                                            "push": 0,
                                            "pull": 0,
                                            "size": "md",
                                            "currentWidth": 6
                                        }
                                    ],
                                    "type": "columns",
                                    "conditional": {
                                        "show": "",
                                        "when": null,
                                        "eq": ""
                                    },
                                    "key": "columns"
                                },
                                {
                                    label: "Piscine Hors Sol",
                                    optionsLabelPosition: "right",
                                    inline: true,
                                    tableView: false,
                                    values: [
                                        {
                                            "label": "Oui",
                                            "value": "1"
                                        },
                                        {
                                            "label": "Non",
                                            "value": "0"
                                        }
                                    ],
                                    validate: {
                                        required: true
                                    },
                                    key: "hors_sol",
                                    defaultValue: myCookie?.hors_sol.toString() || "0",
                                    type: "radio",
                                    inputType: "radio",
                                    input: true
                                }
                            ],
                            "customClass": "red-block"
                        }
                    ]
                },
                {
                    components: [
                        {
                            "input": false,
                            "html": "<span>Dimensions de la piscine</span>",
                            "type": "content",
                            "key": "content",
                            "customClass": "red-block--title",
                        },
                        {
                            components: [
                                {
                                    "columns": [
                                        {
                                            components: [
                                                {
                                                    type: 'number',
                                                    key: 'longueur',
                                                    label: 'Longueur (en m)',
                                                    input: true,
                                                    defaultValue: myCookie?.longueur.toString() || "",
                                                    validate: {
                                                        required: true,
                                                        max: 99999
                                                    }
                                                },
                                                {
                                                    type: 'number',
                                                    key: 'largeur',
                                                    label: 'Largeur (en m)',
                                                    input: true,
                                                    defaultValue: myCookie?.largeur.toString() || "",
                                                    validate: {
                                                        required: true,
                                                        max: 99999
                                                    }
                                                },
                                                {
                                                    type: 'number',
                                                    key: 'profondeur',
                                                    label: 'Profondeur (en m)',
                                                    input: true,
                                                    defaultValue: myCookie?.profondeur.toString() || "",
                                                    validate: {
                                                        required: true,
                                                        max: 99999
                                                    }
                                                },
                                                {
                                                    type: 'number',
                                                    key: 'volume',
                                                    label: 'Volume (en m<sup>3</sup>)',
                                                    input: true,
                                                    defaultValue: myCookie?.volume.toString() || "",
                                                    disabled: true,
                                                    calculateValue: {
                                                        "*": [
                                                            {var: 'data.longueur'},
                                                            {var: 'data.largeur'},
                                                            {var: 'data.profondeur'}
                                                        ]
                                                    }
                                                }
                                            ],
                                            "width": 6,
                                            "offset": 0,
                                            "push": 0,
                                            "pull": 0,
                                            "size": "md",
                                            "currentWidth": 6
                                        },
                                        {
                                            components: [
                                                {
                                                    "input": false,
                                                    "html": "<img src='/bundles/thermorprosite/icons/piscine.svg'></img>",
                                                    "type": "content",
                                                    "key": "content",
                                                }
                                            ],
                                            "width": 6,
                                            "offset": 0,
                                            "push": 0,
                                            "pull": 0,
                                            "size": "md",
                                            "currentWidth": 6
                                        }
                                    ],
                                    "type": "columns",
                                    "conditional": {
                                        "show": "",
                                        "when": null,
                                        "eq": ""
                                    },
                                    "key": "columns"
                                },
                                {
                                    "columns": [
                                        {
                                            components: [{
                                                type: "select",
                                                label: "Date d'ouverture",
                                                key: "mois_debut",
                                                placeholder: "Veuillez sélectionner une option",
                                                defaultValue: myCookie?.mois_debut.toString() || "4",
                                                data: {
                                                    values: [
                                                        {value: '4', label: 'Avril'},
                                                        {value: '5', label: 'Mai'},
                                                        {value: '6', label: 'Juin'},
                                                        {value: '7', label: 'Juillet'},
                                                        {value: '8', label: 'Août'},
                                                        {value: '9', label: 'Septembre'}
                                                    ]
                                                },
                                                validate: {
                                                    required: true
                                                },
                                                dataSrc: "values",
                                                template: "<span>{{ item.label }}</span>",
                                                input: true,
                                                customClass: 'mois_debut'
                                            }],
                                            "width": 6,
                                            "offset": 0,
                                            "push": 0,
                                            "pull": 0,
                                            "size": "md",
                                            "currentWidth": 6
                                        },
                                        {
                                            components: [{
                                                type: "select",
                                                label: "Date de fermeture",
                                                key: "mois_fin",
                                                placeholder: "Veuillez sélectionner une option",
                                                defaultValue: myCookie?.mois_fin.toString() || "10",
                                                data: {
                                                    custom(context) {
                                                        var values = conditionalField(context.row.mois_debut);
                                                        return values;
                                                    }
                                                },
                                                validate: {
                                                    required: true
                                                },
                                                dataSrc: 'custom',
                                                valueProperty: 'value',
                                                refreshOn: "mois_debut",
                                                input: true,
                                                customClass: 'mois_fin'
                                            }],
                                            "width": 6,
                                            "offset": 0,
                                            "push": 0,
                                            "pull": 0,
                                            "size": "md",
                                            "currentWidth": 6
                                        }
                                    ],
                                    "type": "columns",
                                    "conditional": {
                                        "show": "",
                                        "when": null,
                                        "eq": ""
                                    },
                                    "key": "columns"
                                },
                                {
                                    "columns": [

                                        {
                                            components: [{
                                                label: "Bâche ou volet",
                                                optionsLabelPosition: "right",
                                                inline: true,
                                                tableView: false,
                                                values: [
                                                    {
                                                        "label": "Oui",
                                                        "value": "1"
                                                    },
                                                    {
                                                        "label": "Non",
                                                        "value": "0"
                                                    }
                                                ],
                                                validate: {
                                                    required: true
                                                },
                                                key: "volet",
                                                defaultValue: myCookie?.volet.toString() || "0",
                                                type: "radio",
                                                inputType: "radio",
                                                input: true
                                            }],
                                            "width": 6,
                                            "offset": 0,
                                            "push": 0,
                                            "pull": 0,
                                            "size": "md",
                                            "currentWidth": 6
                                        },
                                        {
                                            components: [{
                                                label: "Abri Bas",
                                                optionsLabelPosition: "right",
                                                inline: true,
                                                tableView: false,
                                                values: [
                                                    {
                                                        "label": "Oui",
                                                        "value": "1"
                                                    },
                                                    {
                                                        "label": "Non",
                                                        "value": "0"
                                                    }
                                                ],
                                                validate: {
                                                    required: true
                                                },
                                                key: "abris_bas",
                                                defaultValue: myCookie?.abris_bas.toString() || "0",
                                                type: "radio",
                                                inputType: "radio",
                                                input: true
                                            }],
                                            "width": 6,
                                            "offset": 0,
                                            "push": 0,
                                            "pull": 0,
                                            "size": "md",
                                            "currentWidth": 6
                                        }

                                    ],
                                    "type": "columns",
                                    "conditional": {
                                        "show": "",
                                        "when": null,
                                        "eq": ""
                                    },
                                    "key": "columns"
                                },
                                {
                                    "columns": [
                                        {
                                            components: [{
                                                type: "select",
                                                label: "T°d'eau souhaitée",
                                                key: "t_souhaitee",
                                                defaultValue: myCookie?.t_souhaitee.toString() || "",
                                                placeholder: "Veuillez sélectionner une option",
                                                data: {
                                                    values: [
                                                        {value: '24', label: '24'},
                                                        {value: '25', label: '25'},
                                                        {value: '26', label: '26'},
                                                        {value: '27', label: '27'},
                                                        {value: '28', label: '28'},
                                                        {value: '29', label: '29'},
                                                        {value: '30', label: '30'},
                                                        {value: '31', label: '31'},
                                                        {value: '32', label: '32'}
                                                    ]
                                                },
                                                validate: {
                                                    required: true
                                                },
                                                template: "<span>{{ item.label }}</span>",
                                                input: true
                                            }],
                                            "width": 6,
                                            "offset": 0,
                                            "push": 0,
                                            "pull": 0,
                                            "size": "md",
                                            "currentWidth": 6
                                        },
                                        {
                                            components: [{
                                                type: "select",
                                                label: "Exposition au vent",
                                                key: "expo_vent",
                                                defaultValue: myCookie?.expo_vent.toString() || "",
                                                placeholder: "Veuillez sélectionner une option",
                                                data: {
                                                    values: [
                                                        {value: '1', label: 'Faible'},
                                                        {value: '4', label: 'Forte'}
                                                    ]
                                                },
                                                validate: {
                                                    required: true
                                                },
                                                template: "<span>{{ item.label }}</span>",
                                                input: true
                                            }],
                                            "width": 6,
                                            "offset": 0,
                                            "push": 0,
                                            "pull": 0,
                                            "size": "md",
                                            "currentWidth": 6
                                        }
                                    ],
                                    "type": "columns",
                                    "conditional": {
                                        "show": "",
                                        "when": null,
                                        "eq": ""
                                    },
                                    "key": "columns"
                                }

                            ],
                            "customClass": "red-block"
                        }
                    ]
                },
                {
                    components: [
                        {
                            "columns": [
                                {
                                    components: [{
                                        "input": false,
                                        "html": '<button class="Link PrimaryButton PrimaryButton--full PrimaryButton--transparent"><img src="/bundles/thermorprosite/icons/reload.svg" /><span>Réinitialiser le formulaire</span></button>',
                                        "type": "content",
                                        "key": "content",
                                        "customClass": "pac-piscine--reload"
                                    }],
                                    "width": 6,
                                    "offset": 0,
                                    "push": 0,
                                    "pull": 0,
                                    "size": "md",
                                    "currentWidth": 6
                                },
                                {
                                    components: [{
                                        type: 'button',
                                        action: 'submit',
                                        label: 'VOIR LES RÉSULTATS',
                                        theme: 'primary',
                                        customClass: "pac-piscine--submit",
                                        data: apiUrl + '/nouveau-dimensionnemet-piscine'
                                    }],
                                    "width": 6,
                                    "offset": 0,
                                    "push": 0,
                                    "pull": 0,
                                    "size": "md",
                                    "currentWidth": 6
                                }
                            ],
                            "type": "columns",
                            "conditional": {
                                "show": "",
                                "when": null,
                                "eq": ""
                            },
                            "key": "columns"
                        }]
                }
            ]
        },
        {
            language: 'en-US',
            decimalSeparator: '.',
            delimiter: ',',
            i18n: {
                'en-US': {
                    required: 'Ce champ est obligatoire*',
                    max: 'Ne peut pas être supérieur à  5 caractère.',
                    min: 'Ne peut pas être supérieur à 5 caractère.',
                    maxLength: 'Ne peut pas être supérieur à {{length}} caractère.',
                    'No results found': 'Aucun résultat pour cette recherche',
                    'Type to search': 'Écrivez pour lancer une recherche'
                }
            }
        })
        .then(function (form) {
            let newOption = {
                value: 'new_option_value',
                label: 'New Option Label'
            };
            let submitBtn = document.querySelector('.formio-component-submit').querySelector('button');
            submitBtn.addEventListener('click', function () {
                const errors = document.querySelectorAll('.formio-errors');
                let brk = true;

                errors.forEach(error => {
                    if (error.querySelector('.form-text.error')?.isConnected && brk) {
                        error.parentNode.scrollIntoView();
                        brk = false;
                    }
                })
            })
            const value_radio = (elementToSelect)=>{
                let radios = document.querySelectorAll(elementToSelect);
                let value="0";
                radios.forEach((radio)=> {
                    if (radio.checked) {
                        value = radio.value;
                    }

                });
                return value;
            }
            document.querySelector('.mois_debut').querySelector('select').addEventListener('change', function () {
                form.getComponent('mois_fin').setValue((parseInt(this.value) + 1).toString());
            })

            form.on('submit', function(submission) {
                let idDim = 0;
                let canBeSaved = true;
                let jsonSubmissionData = converToString(submission.data);

                localStorage.removeItem("SaveDim");
                if(getCookie("pac_piscine_step1") !== undefined && getCookie("pac_piscine_step1") !== null) {
                    var oldCookie = getCookie("pac_piscine_step1");
                    let jsonOldCookie = JSON.parse(oldCookie);
                    idDim = jsonOldCookie['idDim'];
                    delete jsonOldCookie['canBeSaved'];
                    delete jsonOldCookie['idDim'];
                    delete jsonOldCookie['date_creation'];
                    if (_.isEqual(jsonOldCookie, jsonSubmissionData)) {
                        canBeSaved = false;
                    }
                }
                jsonSubmissionData['canBeSaved'] = canBeSaved;
                jsonSubmissionData['idDim'] = idDim;
                let objectString = JSON.stringify(jsonSubmissionData);
                document.cookie = "pac_piscine_step1=" + objectString + "; expires="+ (new Date(Date.now()+ 86400*1000)).toUTCString() + "; path=/";

                // Check if the "id" parameter exists
                if (urlParams.has('id')) {
                    // Parameter exists, get its value
                    var id = urlParams.get('id');
                    window.location.href = apiUrl + "/simulhome/pac-piscine/reponse" + "?id=" + encodeURIComponent(id);
                } else {
                    // Parameter doesn't exist
                    window.location.href = apiUrl + "/simulhome/pac-piscine/reponse";
                }
            });


            document.querySelector('.formio-component .pac-piscine--reload button').addEventListener('click', function () {
                deleteCookie('pac_piscine_step1',false, '/');
                location.href = '/simulhome/pac-piscine/nouvelle-installation'
            });
        });

}
if (isPacPiscineStep1){
    getDep();
}



const savePool = document.querySelector('.ProjectSynthese--content .save-pool');
const product = document.querySelector('.product_display');
const mailBtn = document.getElementById("mailBtn");
const  modifBtn= document.getElementById("return-btn");

if (!product && modifBtn){
    mailBtn.style.display = 'none';
    savePool.style.display = 'none';
}
if (savePool && product){
    savePool.addEventListener('click', function (e) {
        e.preventDefault();
        let popinSave = document.getElementById('pool-save');
        let saveProject = document.getElementById('saveProject-submit');
        popinSave.setAttribute('aria-hidden', 'false');
        popinSave.addEventListener('click',function (e){
            if(e.target.id==='saveProject-submit'){
                let connected = popinSave.getAttribute('data-connected');
                if (connected === 'false') {
                    return location.href = '/pac-piscine';
                }
                // Make a POST request
                fetch('/create-project', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                })
                    .then(function (response) {
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        return response.json(); // Parse the JSON response
                    })
                    .then(function (data) {
                        if (data.success){
                            popinSave.setAttribute('aria-hidden', 'true');
                            document.querySelector(".successSave").style.display = "flex";
                            setTimeout(() => {
                                document.querySelector(".successSave").style.display = "none";
                            }, 3000);
                        }
                    })
                    .catch(function (error) {
                        console.error('Fetch error:', error);
                    });
            }
        });
    });
}