import Popup from '../popup/popup'

class PopupGainable extends Popup{

    init(){
        super.init()
        this.openPopupGainable()
    }

    openPopupGainable() {

    }

}

let popupGainable = document.querySelector('.PopupGainable');

if(popupGainable) {
    var val = popupGainable.querySelector('.PopupGainable--mashing #val');
    var input = popupGainable.querySelector('.PopupGainable--mashing .inputRange');

    val.innerHTML = input.value;
    let styles = ['-webkit-gradient', '-moz-linear-gradient', '-o-linear-gradient', '-ms-linear-gradient'];

    for (const style of styles) {
        input.style.backgroundImage = style +'(linear, 6% top, right top,color-stop(0 , #e62814),color-stop( 0, #fff))';
    }

    input.style.backgroundImage = 'linear-gradient(to right, #e62814 6%, white 0)';
    

    input.addEventListener("input", function () {
        val.innerHTML = input.value;
        let value = (input.value - input.getAttribute("min")) / (input.getAttribute("max") - input.getAttribute("min")); 
        let redSlide = value;

        if(value === 0) {
            redSlide = '6%';
        }
        for (const style of styles) {
            input.style.backgroundImage = style +'(linear, 6% top, right top, '
                            + 'color-stop(' + value + ', #e62814), '
                            + 'color-stop(' + value + ', #fff)'
                            + ')'
        }
        input.style.backgroundImage = 'linear-gradient(to right, #e62814 ' + redSlide + ', white '+ value + ')';
    });
}


export default PopupGainable