import { getCookie } from './utilities'

/**
 * This function allow to login or logout the user automatically on a shared cookie on the domain.
 * - if the shared cookie exists and the user is not logged in locally => redirects to the sso page
 * - if the shared cookie doesn't exist and the user is logged in locally => logout the user locally
 * - if the shared cookie exists and the user is logged in locally => check that both users match and if not redirects the user to the sso page
 */
async function refreshState() {
    let state = null;
    let ssoCookieValue = getCookie('atlantic_sso_user');
    if (ssoCookieValue === null) {
        ssoCookieValue = '';
    }

    let stateUrl = '/sso/get-state';
    if (ssoCookieValue !== '') {
        stateUrl += '/'+ssoCookieValue;
    }
    await fetch(stateUrl)
        .then(response => response.json())
        .then(result => {
            state = result;
        })
    ;

    if (state === null) {
        return;
    }

    // Do not apply rules if we are connected as a non SSO account
    if (state.is_sso_account === false) {
        return;
    }

    let postData = new FormData();
    postData.append('target', window.location.href);

    // case where the user is not logged in locally but sso cookie exists => redirection to SSO login page
    if (!state.user_is_connected && state.cookie_username !== false) {
        await fetch('/set-target-path', {method: 'POST', body: postData});
        window.location.href = state.login_url;
        return;
    }

    // case where the user is logged in locally and sso cookie exists => checking if both usernames match
    if (state.user_is_connected && state.cookie_username !== false) {
        if (state.username !== state.cookie_username) { // if local user doesn't match with cookie user => redirection to SSO login page to force to change the user
            await fetch('/set-target-path', {method: 'POST', body: postData});
            window.location.href = state.login_url;
        }
        return;
    }

    //if user is connected locally but no SSO cookie, keep connected
    if (state.user_is_connected && state.cookie_username === false && ssoCookieValue === '') {
        return;
    }

    if (state.user_is_connected && state.cookie_username === false) {
        await fetch('/set-target-path', {method: 'POST', body: postData});
        window.location.href = state.logout_url;
    }
}

document.addEventListener('DOMContentLoaded', function(event) {
    refreshState();
});
document.addEventListener('visibilitychange', function(event) {
    if (document.visibilityState === 'visible') {
        refreshState();
    }
});