const inputProjectSearch = document.getElementById("ProjectSearch_home");
const projectList = document.getElementById("BlockProjectDim--wrapper");

if(inputProjectSearch && projectList) {
    inputProjectSearch.addEventListener("keyup", searchDimProject);
}

function searchDimProject() {
    const filter = inputProjectSearch.value.toUpperCase();
    const projectName = projectList.querySelectorAll(".BlockProjectDim");
    for (let i = 0; i < projectName.length; i++) {
        const label = projectName[i].getElementsByTagName("h2")[0];
        const txtValue = label.textContent || label.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
            projectName[i].style.display = "";
        } else {
            projectName[i].style.display = "none";
        }
    }
}