import { Formio } from 'formiojs';
import utilities from "../../../scripts/utilities";

let apiUrl = window.location.protocol + "//" + window.location.host;
let urlParams = new URLSearchParams(window.location.search);

function getCookie (name) {
  let value = `; ${document.cookie}`;
  let parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

function converToString(data) {
  Object.keys(data).forEach(k => {
 
  if (k !== 'submit') {
    data[k] = '' + data[k];
  }        
});

 return data;
}

const isReplaceChauffeEauPage = document.querySelector('.replace-chauffe-eau');
if (isReplaceChauffeEauPage) {
  // get default values of formio from cookies
  if (getCookie("replace_chauffe-eau") !== undefined) {
    var replace_chauffe_eau_cookie = JSON.parse(getCookie("replace_chauffe-eau"));
  }
  Formio.createForm(document.getElementById('replace-chauffe-eau'), {
    components: [
      {
        "input": false,
        "html": "<span>Vous ne connaissez pas la référence de votre chauffe-eau actuel</span>",
        "type": "content",
        "key": "content",
        "customClass": "red-block--title",
      },
      {
        components: [{
          "columns": [
            {
              components: [
                {
                  tabindex: "1",
                  type: 'textfield',
                  key: 'nom',
                  label: 'Nom du projet',
                  input: true,
                  defaultValue: replace_chauffe_eau_cookie?.nom || "",
                  validate: {
                    required: true,
                    maxLength: '30'
                  }
                },

                {
                  tabindex: "3",
                  type: "select",
                  label: "Format",
                  key: "format",
                  placeholder: "Veuillez sélectionner une option",
                  defaultValue: replace_chauffe_eau_cookie?.format || "",
                  data: {
                    values: [
                      {value: 'HORIZONTAL COTE', label: 'HORIZONTAL COTE'},
                      {value: 'HORIZONTAL DESSOUS', label: 'HORIZONTAL DESSOUS'},
                      {value: 'PETITE CAPACITE', label: 'PETITE CAPACITE'},
                      {value: 'STABLE', label: 'STABLE'},
                      {value: 'VERTICAL MURAL', label: 'VERTICAL MURAL'}
                    ]
                  },
                  validate: {
                    required: true
                  },
                  dataSrc: "values",
                  template: "<span>{{ item.label }}</span>",
                  input: true
                },
                {
                  tabindex: "5",
                  type: "select",
                  label: "Diamètre",
                  key: "diametre",
                  placeholder: "Veuillez sélectionner une option",
                  defaultValue: replace_chauffe_eau_cookie?.diametre || "",
                  data: {
                    values: [
                      {value: '255', label: '255'},
                      {value: '287', label: '287'},
                      {value: '338', label: '338'},
                      {value: '380', label: '380'},
                      {value: '450', label: '450'},
                      {value: '460', label: '460'},
                      {value: '470', label: '470'},
                      {value: '489', label: '489'},
                      {value: '500', label: '500'},
                      {value: '505', label: '505'},
                      {value: '506', label: '506'},
                      {value: '508', label: '508'},
                      {value: '510', label: '510'},
                      {value: '513', label: '513'},
                      {value: '520', label: '520'},
                      {value: '530', label: '530'},
                      {value: '550', label: '550'},
                      {value: '555', label: '555'},
                      {value: '560', label: '560'},
                      {value: '567', label: '567'},
                      {value: '570', label: '570'},
                      {value: '575', label: '575'},
                      {value: '350X344', label: '350X344'},
                      {value: '357X330', label: '357X330'},
                      {value: '360X250', label: '360X250'},
                      {value: '360X295', label: '360X295'},
                      {value: '360X301', label: '360X301'},
                      {value: '360X324', label: '360X324'},
                      {value: '360X346', label: '360X346'},
                      {value: '447X365', label: '447X365'},
                      {value: '447X378', label: '447X378'},
                      {value: '447X389', label: '447X389'},
                      {value: '447X392', label: '447X392'},
                      {value: '447X410', label: '447X410'},
                      {value: '600', label: '600'},
                      {value: '750', label: '750'}
                    ]
                  },
                  dataSrc: "values",
                  template: "<span>{{ item.label }}</span>",
                  input: true
                },
                {
                  tabindex: "7",
                  type: "select",
                  label: "Technologie",
                  key: "technologie",
                  placeholder: "Veuillez sélectionner une option",
                  defaultValue: replace_chauffe_eau_cookie?.technologie || "",
                  data: {
                    values: [
                      {value: 'ACI', label: 'ACI'},
                      {value: 'BLINDE', label: 'BLINDE'},
                      {value: 'STEATITE', label: 'STEATITE'}
                    ]
                  },
                  dataSrc: "values",
                  template: "<span>{{ item.label }}</span>",
                  input: true
                }
              ],
              "width": 6,
              "offset": 0,
              "push": 0,
              "pull": 0,
              "size": "md",
              "currentWidth": 6
            },
            {
              components: [
                {
                  tabindex: "2",
                  type: "select",
                  label: "Capacité",
                  key: "capacite",
                  placeholder: "Veuillez sélectionner une option",
                  defaultValue: replace_chauffe_eau_cookie?.capacite || "",
                  data: {
                    values: [
                      {value: '0.1', label: '10L'},
                      {value: '0.15', label: '15L'},
                      {value: '0.3', label: '30L'},
                      {value: '0.4', label: '40L'},
                      {value: '0.5', label: '50L'},
                      {value: '0.65', label: '65L'},
                      {value: '0.75', label: '75L'},
                      {value: '0.8', label: '80L'},
                      {value: '1', label: '100L'},
                      {value: '1.2', label: '120L'},
                      {value: '1.5', label: '150L'},
                      {value: '2', label: '200L'},
                      {value: '2.5', label: '250L'},
                      {value: '2.7', label: '270L'},
                      {value: '3', label: '300L'},
                      {value: '5', label: '500L'}
                    ]
                  },
                  validate: {
                    required: true
                  },
                  dataSrc: "values",
                  template: "<span>{{ item.label }}</span>",
                  input: true
                },
                {
                  tabindex: "4",
                  type: "select",
                  label: "Marque",
                  key: "marque",
                  placeholder: "Veuillez sélectionner une option",
                  defaultValue: replace_chauffe_eau_cookie?.marque || "",
                  data: {
                    values: [
                      {value: 'ALLIANCE', label: 'ALLIANCE'},
                      {value: 'ALTECH', label: 'ALTECH'},
                      {value: 'ALTERNA', label: 'ALTERNA'},
                      {value: 'ARISTON', label: 'ARISTON'},
                      {value: 'ATLANTIC', label: 'ATLANTIC'},
                      {value: 'CHAFFOTEAUX', label: 'CHAFFOTEAUX'},
                      {value: 'DE DIETRICH', label: 'DE DIETRICH'},
                      {value: 'FAGOR', label: 'FAGOR'},
                      {value: 'FLECK', label: 'FLECK'},
                      {value: 'LEMERCIER', label: 'LEMERCIER'},
                      {value: 'PACIFIC', label: 'PACIFIC'},
                      {value: 'THERMOR', label: 'THERMOR'},
                      {value: 'CEINTURES', label: 'CEINTURES'}
                    ]
                  },
                  validate: {
                    required: true
                  },
                  dataSrc: "values",
                  template: "<span>{{ item.label }}</span>",
                  input: true
                },
                {
                  tabindex: "6",
                  type: "select",
                  label: "Entraxe",
                  key: "entraxe",
                  placeholder: "Veuillez sélectionner une option",
                  defaultValue: replace_chauffe_eau_cookie?.entraxe || "",
                  data: {
                    values: [
                      {value: '1050', label: '1050'},
                      {value: '255', label: '255'},
                      {value: '277', label: '277'},
                      {value: '280', label: '280'},
                      {value: '300', label: '300'},
                      {value: '320', label: '320'},
                      {value: '325', label: '325'},
                      {value: '345', label: '345'},
                      {value: '370', label: '370'},
                      {value: '400', label: '400'},
                      {value: '480', label: '480'},
                      {value: '500', label: '500'},
                      {value: '500/800', label: '500/800'},
                      {value: '600', label: '600'},
                      {value: '685', label: '685'},
                      {value: '700', label: '700'},
                      {value: '750', label: '750'},
                      {value: '798', label: '798'},
                      {value: '800', label: '800'},
                      {value: '800/500', label: '800/500'},
                      {value: '800/700', label: '800/700'},
                      {value: '435', label: '435'},
                      {value: '790', label: '790'},
                      {value: '970', label: '970'}
                    ]
                  },
                  validate: {
                    required: true
                  },
                  dataSrc: "values",
                  template: "<span>{{ item.label }}</span>",
                  input: true
                },
                {
                  tabindex: "8",
                  type: "textfield",
                  label: "Référence",
                  key: "reference",
                  input: true,
                  defaultValue: replace_chauffe_eau_cookie?.reference || "",
                  data: {
                    values: [
                      {value: '3010529', label: '3010529'},
                    ]
                  },
                  conditional: {
                    "json": {
                      "!=": [
                        {
                          "var": "data.technologie"
                        },
                        ""
                      ]
                    }
                  }
                }
              ],
              "width": 6,
              "offset": 0,
              "push": 0,
              "pull": 0,
              "size": "md",
              "currentWidth": 6
            }
          ],
          "type": "columns",
          "conditional": {
            "show": "",
            "when": null,
            "eq": ""
          },
          "key": "columns",
        }],
        "customClass": "red-block"
      },
      {
        type: 'button',
        action: 'submit',
        label: 'TROUVER',
        theme: 'primary'
      }
    ]
  }, {language: 'fr',
    i18n: {
      'fr': {
        required: 'Ce champ est obligatoire',
        maxLength: 'Ne peut pas être supérieur à {{length}} caractère.',
        'No results found': 'Aucun résultat pour cette recherche',
        'Type to search': 'Écrivez pour lancer une recherche'
      }
    }
  }).then(function (form) {
    form.on('submit', function (submission) {
      let idDim = 0;
      let canBeSaved = true;
      let jsonSubmissionData = converToString(submission.data);

      localStorage.removeItem("SaveDim");
      jsonSubmissionData['canBeSaved'] = canBeSaved;
      jsonSubmissionData['idDim'] = idDim;
      let objectString = JSON.stringify(jsonSubmissionData);
      document.cookie = "replace_chauffe-eau=" + objectString + "; expires=" + (new Date(Date.now() + 3600 * 1000)).toUTCString();
      let idDimUrl = idDim !==0 ? '&id='+idDim : '';
       window.location.href = apiUrl + "/simulhome/chauffe-eau/remplacement" + "?action=" + encodeURIComponent('get_produits_remplacement') + idDimUrl;
    });
  });
}