import {getCookie} from './../../../../ThermorProSiteBundle/Resources/src/scripts/utilities'

const formContainer = document.querySelectorAll('.Ezform .Ezform-group')

const init = () => {
  let ibanInputField;
  let ssoCookieValue = getCookie('atlantic_sso_user');
  if (ssoCookieValue === null) {
    ssoCookieValue = ''
  }

  let apiUrl = '/form/get-user-info';
  if (ssoCookieValue !== '') {
    apiUrl += '/'+ssoCookieValue;
  }

  if (!formContainer) return

  formContainer.forEach((formContainer) => {
    getUserData(apiUrl)
      .then((data) => {
        if (data.length === 0) {
          console.error("Les données retournées sont nulles ou indéfinies.");
          return;
        }

        const fields = formContainer.querySelectorAll('.Ezform-field')
        if (fields.length > 0) {
          fields.forEach((fieldContainer) => {
            const fieldInput = fieldContainer.querySelector('input');
            const textarea = fieldContainer.querySelector('textarea');
            const fieldLabel = fieldContainer.querySelector('label');
            const fieldIdentifier = fieldLabel ? fieldLabel.textContent.toLowerCase() : null;

            if (textarea && textarea.value === '' && fieldIdentifier === 'adresse') {
              textarea.value = data[fieldIdentifier];
            }

            if (fieldInput && fieldLabel && fieldInput.value === '') {
              const mapping = {
                'numéro de siret': 'siret',
                'nom de la société': 'nom société',
                'nom de l\'installateur': 'nom installateur',
                'prénom de l\'installateur': 'prénom installateur',
                'adresse mail': 'adresse email',
                'adresse': 'adresse'
              };

              const key = mapping[fieldIdentifier];
              if (key && data[key]) {
                fieldInput.value = data[key];
                fieldInput.setAttribute('value', data[key]);
              } else if (data.hasOwnProperty(fieldIdentifier)) {
                fieldInput.value = data[fieldIdentifier];
                fieldInput.setAttribute('value', data[fieldIdentifier]);
              }
            }
            if(fieldContainer.querySelector('.Ezform-field input.iban-type')) {
              ibanInputField = fieldContainer.querySelector('input');
              ibanInputField.addEventListener('blur', function(e) {
                ibanInputField.value = ibanInputField.value.replace(/\s/g, "").toUpperCase();
              })
              ibanInputField.addEventListener('focus', function(e) {
                ibanInputField.parentElement.classList.remove("Ezform-field--invalid");
                if(ibanInputField.nextSibling !== null){
                  ibanInputField.nextSibling.remove();
                }
              })
             }
          })
        }

        const hiddenFields = formContainer.querySelectorAll("input[type='hidden']")
        if (hiddenFields.length > 0) {
          hiddenFields.forEach((field) => {
            if (field.value === '{pro_account_external_id}') {
              field.setAttribute('value', data['pro_account_external_id'])
            }
            if (field.value === '{pro_contact_external_id}') {
              field.setAttribute('value', data['pro_contact_external_id'])
            }
          })
        }
      })
      .catch((error) => {
        console.error(error)
      })
  })

  async function getUserData(url = '') {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (response.ok) {
      return response.json()
    } else {
      throw new Error("Can't get API data")
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  init()
})
