(function() {
    const phoneButtons = document.querySelectorAll('[data-phone]');
    if (!phoneButtons && phoneButtons == null || phoneButtons < 1)
        return;

    initPhoneButtonsClick();

    function initPhoneButtonsClick() {
        phoneButtons.forEach(phoneButton => {
            phoneButton.addEventListener("click", event => {
                const newPhoneButton = document.createElement('a')
                newPhoneButton.classList.add('SecondaryButton')
                newPhoneButton.setAttribute('href', 'tel:' + phoneButton.dataset.phone)
                newPhoneButton.innerHTML = phoneButton.dataset.phone
                phoneButton.parentNode.replaceChild(newPhoneButton, phoneButton)

                if (phoneButton.dataset.info !== undefined) {
                    const info = document.createElement('span')
                    info.innerText = ' ' + phoneButton.dataset.info
                    newPhoneButton.parentNode.insertBefore(info, newPhoneButton.nextSibling)
                }
            })
        })
    }
} ());
