const button = document.querySelector('[data-contact-tel]');

if(button != null) {
    const callHours = document.querySelector('[data-megamenu-hours]');
    const buttonInner = button.querySelector('[data-link-inneritem]')

    button.addEventListener('click', event => {
        if(callHours.innerHTML === '') {
            event.preventDefault()
            changeButtonlabel(callHours, buttonInner)
        }
    })
}

function changeButtonlabel(callHours, buttonInner) {
    buttonInner.innerHTML= button.dataset.contactLabel
    button.href = button.dataset.contactTel;
    callHours.innerHTML = callHours.dataset.megamenuHours
    callHours.setAttribute('aria-hidden', 'false')
}
