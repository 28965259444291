import Swiper from 'swiper';

(function () {
    const slider = document.querySelectorAll('[data-slider]');

    const videoElements = document.querySelectorAll('[data-video-id]')

    if (slider.length < 1) {
        return;
    }
    initSlider();

    /*if (videoElements.length < 1) {
        return;
    }
    initVideo()*/

    function initSlider() {
        const sliderThumbsElement = document.querySelector('[data-slider-type=thumbs]')
        const sliderMainElement = document.querySelectorAll('[data-slider-type=main]')

        const sliderThumbs = new Swiper(sliderThumbsElement, {
            spaceBetween: 0,
            slidesPerView: 5,
            freeMode: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
        });


        const sliderMain = new Swiper(sliderMainElement, {
            spaceBetween: 10,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            thumbs: {
                swiper: sliderThumbs
            }
        });
    }

    function initVideo() {
        const autoPlay = '?autoplay=1' // autoplay attribute to add to URL
        let videoElements = document.querySelector('.HeroTwoBlocks-mainiframe') // defined as a let, can either be a div or an iframe -- redefined if elem changes
        let videoButtons = document.querySelectorAll('.HeroTwoBlocks-button')

        videoButtons.forEach(videoButton => {
            videoButton.addEventListener('click', () => {
                let mainIframe = '<iframe class="HeroTwoBlocks-mainiframe" src="https://player.vimeo.com/video/' + videoButton.parentElement.dataset.videoId + autoPlay + '" frameborder="0" allow="autoplay; fullscreen" width="587" height="auto" allowfullscreen></iframe>'
                // building iframe with correct src values

                var newEl = document.createElement('div')
                newEl.innerHTML = mainIframe
                videoButton.parentElement.parentNode.replaceChild(newEl, videoElement)
                videoButton.parentElement = document.querySelector('.HeroTwoBlocks-mainiframe') // redefining cause element type changes
            })
        })
    }
}())