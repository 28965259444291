import { getCookie } from './utilities'

(function() {
    const addButtons = document.querySelectorAll('.add-another-collection-widget');

    if(addButtons.length >= 1) {
        initSubscription(addButtons);
    }

    function initSubscription(addButtons) {
        for (const addButton of addButtons) {
            addButton.addEventListener('click', function (e) {

                var list = document.querySelector(e.target.getAttribute('data-list-selector'));
                // Try to find the counter of the list or use the length of the list
                var counter = document.querySelector('#subscription_form_participants').childElementCount;

                // grab the prototype template
                var newWidget = list.getAttribute('data-prototype');
                // replace the "__name__" used in the id and name of the prototype
                // with a number that's unique to your emails
                // end name attribute looks like name="contact[emails][2]"
                newWidget = newWidget.replace(/__name__/g, counter);

                // list = document.querySelector('#list-participants-items');
                list = document.querySelector('#subscription_form_participants');

                list.insertAdjacentHTML('beforeend', newWidget);

                addDateUpdateEvent(list.querySelector('.Form-listInputs:last-child'));

                let removeButton = document.querySelectorAll('.remove-collection-widget');
                removeButton = [].slice.call(removeButton).pop();
                removeButton.addEventListener('click', function (e) {
                    e.target.parentNode.parentNode.removeChild(e.target.parentNode);
                });

            });
        }

        let removeButtons = document.querySelectorAll('.remove-collection-widget');
        removeButtons.forEach(removeButton => {
            removeButton.addEventListener('click', function (e) {
                e.target.parentNode.parentNode.removeChild(e.target.parentNode);
            });
        })

        const formListInput = document.querySelectorAll('.Form-listInputs')

        formListInput.forEach(listInput => {
            addDateUpdateEvent(listInput);
        })
    }

    function addDateUpdateEvent(listInput) {
        const locationField = listInput.querySelector('[data-fieldtype=location]')
        const dateField = listInput.querySelector('[data-fieldtype=date]')
        const trainingRefField = listInput.querySelector('[data-fieldtype=training_ref]')
        const url = new URL('/subscribe/date', window.location.origin);
        locationField.addEventListener('change', () => {
            const urlParam = url.searchParams
            urlParam.set('location', locationField.value)
            urlParam.set('training_ref', trainingRefField.value)
            url.search = urlParam.toString()

            let data = {}
            data['location'] = locationField.value
            data['training_ref'] = trainingRefField.value
            fetch(url.toString(), {
                'method': 'GET',
            }).then(response => {
                return response.json()
            }).then((json) => {
                let listOption = []

                Object.keys(json).forEach(key => {
                    const option = document.createElement('option')
                    option.setAttribute('value', json[key])
                    option.innerText = key
                    listOption.push(option)
                })

                dateField.innerHTML = ''
                listOption.forEach(option => {
                    dateField.appendChild(option)
                })
            })
        })
    }

}());
