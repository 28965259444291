import Mmenu from 'mmenu-js'

(function() {
    const mobilemenu = document.querySelector("[data-mmenu]")
    if(mobilemenu !== null && mobilemenu !== undefined){
        initMobileMenu(mobilemenu)
    }
}());

function initMobileMenu(mobilemenu) {
    const menu = new Mmenu('[data-mmenu]', {
        navbar: {
            title: false
        }
    }, {
        // configuration
        offCanvas: {
            page: {
                nodetype: "main"
            }
        }
    })


    const openBtn = document.querySelector('.FullHeaderTop-link--mobile')

    openBtn.addEventListener('click', (event) => {
        if (mobilemenu.getAttribute('aria-hidden') === 'true') { // open
            menu.open();
            mobilemenu.setAttribute('aria-hidden', false)
        } else { // close menu
            menu.close();
            mobilemenu.setAttribute('aria-hidden', false)
        }
    });
    
    const AllexpandBtns = document.querySelectorAll('[data-expand-megamenu]');
    if (!AllexpandBtns) return
    
    AllexpandBtns.forEach((btn)=> {
        const menuItem = btn.closest('.FullHeaderTop-item');
        const megaMenu = menuItem.querySelector('.FullHeaderTop-megamenu');
        const ElementMmMenu = document.querySelector('.mm-menu');
        
        btn.addEventListener('click', (e)=> {
            menu.close();
            mobilemenu.setAttribute('aria-hidden', true)
        });
        
        
    });
}



