const filterCheckbox = document.querySelectorAll('.FilterCheckbox-list');
if (filterCheckbox.length > 0) { // check if there's at least one TeaserFav in page
    initfilterCheckbox(filterCheckbox);
}

function initfilterCheckbox(filterCheckbox) {
    const selectButton = document.querySelector('.FilterCheckbox-select');
    let filterList = document.querySelector('.FilterCheckbox-list');
    const filterItems = document.querySelectorAll('.FilterCheckbox-item .Form-label');
    const filterInputs = filterList.querySelectorAll('input[type="checkbox"]');
    const deleteFilterButton = document.querySelector('.FilterCheckbox-delete');

    selectButton.addEventListener('click', event => {
        if (filterList.getAttribute('aria-hidden') == 'true') { // hidden true, so not visible
            openFilter(filterList)
        } else if (filterList.getAttribute('aria-hidden') == 'false') { // if visible
            closeFilter(filterList)
        }
    })


    deleteFilterButton.addEventListener('click', function (e) {
        var url = new URL(window.location.href)
        url.searchParams.delete('sections')
        url.searchParams.delete('page')
        window.location.href = url.href
    })

    filterItems.forEach(filterItem => {
        filterItem.addEventListener('click', function (e) {
            e.preventDefault()
            const clickedItem = this
            const input = filterList.querySelector('input[id="' + clickedItem.getAttribute('for') + '"]')
            input.checked = !input.checked

            console.log(input)

            let checkedSections = []
            if (input.getAttribute('id') === 'all') { // Click on chebox "all"
                filterInputs.forEach(filterInput => {
                    if (filterInput.getAttribute('id') === 'all')
                        filterInput.checked = true
                    else
                        filterInput.checked = false
                })
            }
            else {
                filterInputs.forEach(filterInput => {
                    if (filterInput.checked) {
                        let value = filterInput.getAttribute('id')
                        if (value !== 'all') {
                            checkedSections.push(value)
                        }
                        else {
                            filterInput.checked = false
                        }
                    }
                })
            }

            var url = new URL(window.location.href);
            url.searchParams.delete('page')
            url.searchParams.delete('sections')
            if (checkedSections.length > 0) {
                url.searchParams.set('sections', checkedSections.join('--'))
            }

            window.location.href = url.href;
        })
    })
}

function closeFilter(filterList) {
    filterList.setAttribute('aria-hidden', 'true');
}

function openFilter(filterList) {
    filterList.setAttribute('aria-hidden', 'false');
}

