let closeBtnNotif = document.querySelector('.notifeSubQueteStatut-close');
let notifeSubQueteStatut = document.querySelector('.notifeSubQueteStatut');
if(notifeSubQueteStatut){
  closeBtnNotif.addEventListener('click', function() {
    notifeSubQueteStatut.setAttribute('aria-hidden', 'false')
  });

  document.addEventListener("DOMContentLoaded", event => {
    window.onscroll = function () {
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        notifeSubQueteStatut.classList.add("scrolled")
      } else {
        notifeSubQueteStatut.classList.remove("scrolled")
      }
    };
  })
}

