import { Formio } from 'formiojs';
import utilities, {getCookie, setCookie} from "../../../scripts/utilities";

let apiUrl = window.location.protocol + "//" + window.location.host;

if(getCookie("new_installation") !== null) {
    var myCookieNewInstallation = JSON.parse(getCookie("new_installation"));
}

function converToString(data) {
  Object.keys(data).forEach(k => {
 
  if (k !== 'submit') {
    data[k] = '' + data[k];
  }        
});

 return data;
}

function conditionalField(val) {
    let selectOptions;

    if(val === "sous_evier" )
    { selectOptions =  [
        {value: 'gain_place', label: 'Gain de Place'},
        {value: 'design', label: 'Design'}
    ]
    }
    else if(val === "sur_evier") {
        selectOptions =  [
            {value: 'standard', label: 'Standard'},
            {value: 'gain_place', label: 'Gain de Place'},
            {value: 'design', label: 'Design'}
        ]
    }

    else {
        selectOptions =  [
            {value: 'standard', label: 'Standard'},
            {value: 'gain_place', label: 'Gain de Place'},
            {value: 'economie', label: "Economies d'énergie"},
            {value: 'design', label: 'Design'}
        ]
    }

    return selectOptions;
}

Formio.createForm(document.getElementById('new-installation-form'),{
    components : [
        {
            "input": false,
            "html": "<span>Bien choisir son chauffe-eau</span>",
            "type": "content",
            "key": "content",
            "customClass": "red-block--title",
        },
        {
            components: [
                {
                    type: 'textfield',
                    key: 'projectName',
                    label: 'Nom du projet',
                    placeholder: 'Saisir le nom du projet',
                    defaultValue: myCookieNewInstallation?.projectName || "",
                    input: true,
                    validate: {
                        required: true,
                        maxLength: '30'
                    }
                },
                {
                    "label": "Chauffe-eau",
                    "optionsLabelPosition": "right",
                    "inline": true,
                    "tableView": false,
                    "values": [
                        {
                            "label": "Appoint",
                            "value": "appoint"
                        },
                        {
                            "label": "Principal",
                            "value": "principal"
                        }
                        // ,{
                        //     "label": "Professionnel",
                        //     "value": "professionnel"
                        // }
                    ],
                    "key": "chauffe_eau",
                    "type": "radio",
                    "inputType": "radio",
                    "validate": {
                        required: true
                    },
                    defaultValue: myCookieNewInstallation?.chauffe_eau || "",
                    "input": true,
                    "customClass": "border-bottom"
                },
                {
                    "label": "Pièce",
                    defaultValue: myCookieNewInstallation?.piece || "",
                    "optionsLabelPosition": "right",
                    "inline": true,
                    "tableView": false,
                    "values": [
                        {
                            "label": "Chauffée",
                            "value": "chauffee"
                        },
                        {
                            "label": "Non chauffée < 20m<sup>3</sup>",
                            "value": "non_chauffee"
                        },
                        {
                            "label": "Non chauffée > 20m<sup>3</sup>",
                            "value": "non_chauffee_sup20m3"
                        }
                    ],
                    "conditional": {
                        "json": {
                          "or" : [
                            { "===": [
                              {
                                "var": "data.chauffe_eau"
                              },
                              ""
                            ] },
                            {
                              "===": [
                                {
                                  "var": "data.chauffe_eau"
                                },
                                "principal"
                              ]
                            }
                          ] 
                        }
                    },
                    "key": "piece",
                    "type": "radio",
                    "inputType": "radio",
                    "validate": {
                        required: true
                    },
                    "input": true,
                    "customClass": "border-bottom"
                },
                {
                    "label": "Besoin",
                    defaultValue: myCookieNewInstallation?.besoin || "",
                    "optionsLabelPosition": "right",
                    "inline": true,
                    "tableView": false,
                    "values": [
                        {
                            "label": "Laver les mains",
                            "value": "mains"
                        },
                        {
                            "label": "Vaiselle + laver les mains",
                            "value": "vaisselle_mains"
                        }
                    ],
                    "key": "besoin",
                    "type": "radio",
                    "inputType": "radio",
                    "validate": {
                        required: true
                    },
                    "conditional": {
                        "json": {
                          "or" : [
                            { "===": [
                              {
                                "var": "data.chauffe_eau"
                              },
                              ""
                            ] },
                            {
                              "===": [
                                {
                                  "var": "data.chauffe_eau"
                                },
                                "appoint"
                              ]
                            }
                          ] 
                        }
                    },
                    "input": true,
                    "customClass": "border-bottom"
                },
                {
                    "label": "Position",
                    defaultValue: myCookieNewInstallation?.position || "",
                    "optionsLabelPosition": "right",
                    "inline": true,
                    "tableView": false,
                    "values": [
                        {
                            "label": "Sous évier",
                            "value": "sous_evier"
                        },
                        {
                            "label": "Sur évier",
                            "value": "sur_evier"
                        }
                    ],
                    "key": "position",
                    "type": "radio",
                    "inputType": "radio",
                    "validate": {
                        required: true
                    },
                    "conditional": {
                        "json": {
                          "or" : [
                            { "===": [
                              {
                                "var": "data.chauffe_eau"
                              },
                              ""
                            ] },
                            {
                              "===": [
                                {
                                  "var": "data.chauffe_eau"
                                },
                                "appoint"
                              ]
                            }
                          ] 
                        }
                    },
                    "input": true,
                    "customClass": "border-bottom"
                },
                {
                    type: "select",
                    defaultValue: myCookieNewInstallation?.nb_personne || "",
                    label: "Nombre de personnes composant le foyer",
                    key: "nb_personne",
                    placeholder: "Veuillez sélectionner une option",
                    data: {
                        values: [
                            {value: '1', label: '1'},
                            {value: '2', label: '2'},
                            {value: '3', label: '3'},
                            {value: '4', label: '4'},
                            {value: '5', label: '5'}
                        ]
                    },
                    validate: {
                        required: true
                    },
                    conditional: {
                        "json": {
                          "or" : [
                            { "===": [
                              {
                                "var": "data.chauffe_eau"
                              },
                              ""
                            ] },
                            {
                              "===": [
                                {
                                  "var": "data.chauffe_eau"
                                },
                                "principal"
                              ]
                            }
                          ] 
                        }
                    },
                    dataSrc: "values",
                    template: "<span>{{ item.label }}</span>",
                    input: true,
                    "customClass": "border-bottom"
                },
                {
                    "label": "Confort souhaité",
                    "defaultValue": myCookieNewInstallation?.confort || "",
                    "optionsLabelPosition": "right",
                    "inline": true,
                    "tableView": false,
                    "values": [
                        {
                            "label": "Confort (consommation raisonnée)",
                            "value": "0"
                        },
                        {
                            "label": "<p>Grand confort (consommation importante)</p>",
                            "value": "1"
                        }
                    ],
                    "conditional": {
                        "json": {
                          "or" : [
                            { "===": [
                              {
                                "var": "data.chauffe_eau"
                              },
                              ""
                            ] },
                            {
                              "===": [
                                {
                                  "var": "data.chauffe_eau"
                                },
                                "principal"
                              ]
                            }
                          ] 
                        }
                    },
                    "key": "confort",
                    "type": "radio",
                    "inputType": "radio",
                    "validate": {
                        required: true
                    },
                    "input": true,
                    "customClass": "border-bottom"
                },
                {
                    type: "select",
                    label: "Type de solution",
                    key: "type_solution",
                    defaultValue: myCookieNewInstallation?.type_solution || "",
                    placeholder: "Veuillez sélectionner une option",
                    data: {
                        custom(context) {
                          var values = [];
                          values = conditionalField(context.row.position);
                          return values;
                        }
                    },
                    validate: {
                        required: true
                    },
                    conditional: {
                        "json": {
                          "or" : [
                            { "===": [
                              {
                                "var": "data.chauffe_eau"
                              },
                              ""
                            ] },
                            {
                                "===": [
                                  {
                                    "var": "data.chauffe_eau"
                                  },
                                  "appoint"
                                ]
                            },
                            {
                              "===": [
                                {
                                  "var": "data.chauffe_eau"
                                },
                                "principal"
                              ]
                            }
                          ] 
                        }
                    },
                    dataSrc: 'custom',
                    valueProperty: 'value',
                    refreshOn: "position",
                    input: true
                },
            ],
            customClass: "red-block"
        },
        {
            type: 'button',
            action: 'submit',
            label: 'VOIR LA RÉPONSE THERMOR',
            theme: 'primary'
        }
    ]
},{language: 'fr', i18n: { 'fr': { required: 'Ce champ est obligatoire', maxLength: 'Ne peut pas être supérieur à {{length}} caractère.', 'No results found' : 'Aucun résultat pour cette recherche', 'Type to search': 'Écrivez pour lancer une recherche'} }}).then(function(form) {

  document.querySelectorAll('.formio-component.border-bottom').forEach(function(item) {

    if(item.childNodes.length === 1) {
        item.style.display = "none"
    }
  }) 

    form.on('change', function() {
        document.querySelectorAll('.formio-component.border-bottom').forEach(function(item) {
            if(item.childNodes.length === 1) {
                item.style.display = "none"
            }
        })  
    });

    form.on('submit', function(submission) {
        let idDim = 0;
        let canBeSaved = true;
        let jsonSubmissionData = converToString(submission.data);

        localStorage.removeItem("SaveDim");

        if(utilities.getCookie("new_installation") !== undefined && utilities.getCookie("new_installation") !== null) {
            var oldCookie = getCookie("new_installation");
            let jsonOldCookie = JSON.parse(oldCookie);
            idDim = jsonOldCookie['idDim'];
            delete jsonOldCookie['canBeSaved'];
            delete jsonOldCookie['idDim'];

            Object.keys(jsonOldCookie).forEach(k => {
              if (jsonOldCookie[k] == "") {
                delete jsonOldCookie[k]
              }        
            });

            if (_.isEqual(jsonOldCookie, jsonSubmissionData)) {
                canBeSaved = false;
            }
        }
        jsonSubmissionData['canBeSaved'] = canBeSaved;
        jsonSubmissionData['idDim'] = idDim;

        let objectString = JSON.stringify(submission.data);
        document.cookie = "new_installation=" + objectString + "; expires="+ (new Date(Date.now()+ 86400*1000)).toUTCString() + "; path=/";
        let idDimUrl = idDim !==0 ? '?id='+idDim : '';
        window.location.href = apiUrl + "/simulhome/chauffe-eau/nouvelle-installation/synthese" + idDimUrl;
    });
});