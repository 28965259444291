let projectNameInput = document.getElementById('simul_home_start_form_projectName');
let submitStartForm = document.getElementById('simul_home_start_form_submit');
let errorMessage = document.getElementById('error-message-simulhome');

if (projectNameInput && submitStartForm) {
    projectNameInput.addEventListener('input', function () {
        if (projectNameInput.value.trim() !== '') {
            errorMessage.classList.remove('d-block');
            errorMessage.classList.add('d-none');
            projectNameInput.classList.remove('error-postal');
        }else{
            projectNameInput.classList.add('error-postal');
        }
    });

    submitStartForm.addEventListener('click', function (e) {
        e.preventDefault();

        if (projectNameInput.value.trim() === '') {
            errorMessage.classList.remove('d-none');
            errorMessage.classList.add('d-block');
            projectNameInput.classList.add('error-postal');
        } else {
            errorMessage.classList.remove('d-block');
            errorMessage.classList.add('d-none');
            projectNameInput.classList.remove('error-postal');

            document.forms['simul_home_start_form'].submit();
        }
    });
}


const constructionYearInput = document.getElementById('second_form_housing_constructionYear');
const aucunTravauxCheckbox = document.getElementById('isolationWorksAucunTravaux');
const checkboxes = document.querySelectorAll('.simulhome-radio-choice input[type="checkbox"]');
checkboxes.forEach(function (checkbox) {
    checkbox.addEventListener('change', function () {
        if (checkbox === aucunTravauxCheckbox && checkbox.checked) {
            checkboxes.forEach(function (cb) {
                if (cb !== aucunTravauxCheckbox) {
                    cb.checked = false;

                    const label = cb.closest('.simulhome-radio-choice').querySelector('label');
                    if (label) {
                        label.classList.remove('error-postal');
                    }
                }
            });
        } else {
            if (aucunTravauxCheckbox && checkbox !== aucunTravauxCheckbox && checkbox.checked) {
                aucunTravauxCheckbox.checked = false;
            }

            checkboxes.forEach(function (cb) {
                cb.disabled = false;

                const label = cb.closest('.simulhome-radio-choice').querySelector('label');
                if (label) {
                    label.classList.remove('error-postal');
                }
            });
        }
    });
});

document.addEventListener("DOMContentLoaded", function () {
    let stepperRadiator = document.querySelector('.value_stepper');
    if (stepperRadiator) {
        const activeLine = document.querySelector('.simulhome-house .range-line .active-line');
        const activeDot = document.querySelector('.simulhome-house .dot-line .active-dot');
        var valueStepper = JSON.parse(stepperRadiator.value);

        if (activeLine) {
            activeLine.style.width = `${valueStepper}%`;
        }

        if (activeDot) {
            activeDot.style.left = `${valueStepper}%`;
        }
    }

    const altitudeInput = document.getElementById('second_form_housing_altitude');
    const altitudeStepper = document.getElementById('altitudeStepper');

    if (altitudeInput && altitudeStepper) {
        if (altitudeInput.value !== '') {
            let altitudeValue = parseInt(altitudeInput.value, 10);
            altitudeStepper.value = altitudeValue;
            updateRangeDisplay(altitudeValue);
        }

        altitudeInput.addEventListener('input', function () {
            let altitudeValue = parseInt(altitudeInput.value, 10);
            if (altitudeValue > 2000) {
                altitudeStepper.value = 2000;
                updateRangeDisplay(2000);
            }
            if (altitudeValue >= 0 && altitudeValue <= 2000) {
                altitudeStepper.value = altitudeValue;
                updateRangeDisplay(altitudeValue);
            }
        });

        altitudeStepper.addEventListener('input', function () {
            altitudeInput.value = altitudeStepper.value;
            updateRangeDisplay(Number(altitudeStepper.value));
        });
    }

    function updateRangeDisplay(value) {
        const max = altitudeStepper.max;
        const percent = (value / max) * 100;

        document.querySelector('.active-line').style.width = percent + '%';
        document.querySelector('.active-dot').style.left = percent + '%';
    }
    const codePostal = document.getElementById('second_form_housing_commonName');
    const errorCodePostal = document.querySelector('.error-codePostal');

    if (codePostal) {
        codePostal.addEventListener('input', function (event) {
            let timeOut = 0;
            let input = this.value;
            clearTimeout(timeOut);

            if (!event.target.value || event.target.value.length < 3) {
                errorCodePostal.classList.add('d-none');
                codePostal.classList.remove('error-postal');
            }

            if (input.length >= 3) {
                event.preventDefault();
                let searchValue = this.value;
                let searchVal = {
                    search: searchValue
                };
                timeOut = setTimeout(() => {
                    fetch(`/search-zone`, {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                        },
                        body: JSON.stringify(searchVal)
                    })
                        .then(response => {
                            if (!response.ok) {
                                throw new Error('Network response was not ok');
                            }
                            return response.json();
                        })
                        .then(data => {
                            let commonName = document.getElementById('second_form_housing_commonName');
                            let menuDropDown = document.querySelector('.dropdown-radiator .menu-radiator');
                            if (commonName) {
                                if (data.length === 0) {
                                    menuDropDown.classList.remove('d-block');
                                    menuDropDown.classList.add('d-none');
                                    commonName.classList.add('error-postal')
                                } else {
                                    commonName.classList.remove('error-postal')
                                    menuDropDown.classList.add('d-block');
                                }

                                commonName.addEventListener('input', function () {
                                    if (commonName.value.trim() === '') {
                                        commonName.classList.remove('error-postal');
                                        menuDropDown.classList.add('d-none');
                                    }
                                });
                            }


                            const activeLine = document.querySelector('.simulhome-house .range-line .active-line');
                            const activeDot = document.querySelector('.simulhome-house .dot-line .active-dot');
                            var climateZoneInput = document.getElementById('second_form_housing_climateZoneId');
                            var departmentRadiator = document.getElementById('second_form_housing_department');
                            var codePostal = document.getElementById('second_form_housing_codePostal');
                            var nearSea = document.getElementById('second_form_housing_nearSea');

                            if (data.length > 0) {
                                errorCodePostal.classList.add('d-none');
                                if (data.length === 1) {
                                    document.querySelector('.form-housing').classList.remove('d-none');
                                    const altitude = data[0].altitude_moyenne;
                                    const min = 0;
                                    const max = 2000;
                                    const percentage = ((altitude - min) / (max - min)) * 100;
                                    activeLine.style.width = `${percentage}%`;
                                    activeDot.style.left = `${percentage}%`;
                                    document.querySelector('.value-altitude-house').value = `${altitude}`;
                                    if (codePostal) {
                                        codePostal.value = data[0].code_postal;
                                    }

                                    if (climateZoneInput) {
                                        climateZoneInput.value = data[0].id;
                                    }

                                    if (departmentRadiator) {
                                        departmentRadiator.value = data[0].code_departement;
                                    }

                                    if (nearSea) {
                                        nearSea.value = data[0].impact_mer;
                                    }
                                    document.getElementById('second_form_housing_commonName').value = data[0].nom_commune;
                                    let isCodepostal = document.querySelector('.isCodepostal');

                                    if (data[0].impact_mer === "0") {
                                        if (isCodepostal) {
                                            isCodepostal.closest('.simulhome-item-form').classList.add('d-none');
                                            document.getElementById('radio_isCoastal_2').checked = true;
                                        }
                                    } else {
                                        if (isCodepostal) {
                                            isCodepostal.closest('.simulhome-item-form').classList.remove('d-none');
                                        }
                                    }
                                    validateConstructionYear();

                                }

                                if (data.length > 1) {
                                    const dropdownContainer = document.querySelector('.dropdown-radiator .menu-radiator');
                                    const dropdownList = document.querySelector('.list_commune');
                                    const inputField = document.querySelector('#second_form_housing_commonName');
                                    dropdownList.innerHTML = '';
                                    dropdownContainer.classList.remove('d-none');
                                    dropdownContainer.classList.add('d-block');

                                    data.forEach(item => {
                                        if (item.nom_commune) {
                                            const listItem = document.createElement('li');
                                            const lieuDitText = item.lieu_dit ? ` - ${item.lieu_dit}` : '';
                                            listItem.textContent = `${item.code_postal} - ${item.nom_commune}${lieuDitText}`;
                                            listItem.addEventListener('click', function () {
                                                document.querySelector('.form-housing').classList.remove('d-none');
                                                inputField.value = item.nom_commune;
                                                dropdownContainer.classList.add('d-none');
                                                dropdownContainer.classList.remove('d-block');

                                                const altitude = item.altitude_moyenne;
                                                const min = 0;
                                                const max = 2000;
                                                const percentage = ((altitude - min) / (max - min)) * 100;
                                                activeLine.style.width = `${percentage}%`;
                                                activeDot.style.left = `${percentage}%`;

                                                document.querySelector('.value-altitude-house').value = `${altitude}`;

                                                if (codePostal) {
                                                    codePostal.value = item.code_postal;
                                                }

                                                if (climateZoneInput) {
                                                    climateZoneInput.value = item.id;
                                                }
                                                if (departmentRadiator) {
                                                    departmentRadiator.value = item.code_departement;
                                                }

                                                if (nearSea) {
                                                    nearSea.value = item.impact_mer;
                                                }
                                                let isCodepostal = document.querySelector('.isCodepostal');

                                                if (item.impact_mer === "0") {
                                                    if (isCodepostal) isCodepostal.closest('.simulhome-item-form').classList.add('d-none');
                                                    document.querySelector('.isCodepostal').classList.add('d-none');
                                                    document.getElementById('radio_isCoastal_2').checked = true;
                                                } else {
                                                    if (isCodepostal) isCodepostal.closest('.simulhome-item-form').classList.remove('d-none');
                                                }


                                                validateConstructionYear();

                                            });

                                            dropdownList.appendChild(listItem);
                                        }
                                    });
                                    document.querySelector('.menu-radiator').style.display = 'block';
                                } else {
                                    document.querySelector('.dropdown-radiator .menu-radiator').classList.add('d-none');
                                    document.querySelector('.dropdown-radiator .menu-radiator').classList.remove('d-block');
                                }
                            } else {
                                errorCodePostal.classList.remove('d-none');
                                codePostal.classList.add('error-postal');
                            }
                        })
                        .catch(error => {
                            console.error('Error:', error);
                        });
                }, 1000);

            } else {
                errorCodePostal.classList.add('d-none');
                codePostal.classList.remove('error-postal');
                document.querySelector('.dropdown-radiator .menu-radiator').classList.remove('d-block');
                document.querySelector('.dropdown-radiator .menu-radiator').classList.add('d-none');
                input = "";
            }
        });
    }
});

const btnHousing = document.getElementById("second_form_housing_submit");
if (btnHousing) {
    btnHousing.classList.remove('btn');
    btnHousing.addEventListener("click", function (e) {
        e.preventDefault();

        /*********************************** error form Element select,input */
        const forms = document.querySelectorAll('.form_housing');
        const valueForm = [];
        let hasError = false;

        forms.forEach(function (form) {
            const formData = new FormData(form); // Collects all form data
            const formValues = {};

            formData.forEach(function (value, key) {
                const input = form.querySelector(`[name="${key}"]`);

                if (input) {
                    // Check if input is empty
                    if (input.value.trim() === "" || (input === constructionYearInput && !validateConstructionYear())) {
                        input.classList.add('error-postal');
                        hasError = true; // Mark error as true
                    } else{
                        input.classList.remove('error-postal');
                    }

                    // Handle input events for removing errors
                    if (input.tagName.toLowerCase() === 'input' || input.tagName.toLowerCase() === 'textarea') {
                        input.addEventListener('input', function () {
                            if (input.value.trim() !== "") {
                                input.classList.remove('error-postal');
                                hasError = false; // Reset error state only for this input
                            }
                        });
                    }

                    if (input.tagName.toLowerCase() === 'select') {
                        input.addEventListener('change', function () {
                            if (input.value !== "") { // For select, check if an option other than the default is selected
                                input.classList.remove('error-postal');
                                hasError = false; // Reset error state only for this select
                            }
                        });
                    }
                }

                formValues[key] = value;
            });

            valueForm.push(formValues);
        });

        /*******************************************  error checkBox */
        const checkboxes = document.querySelectorAll('.choice-radiator input');
        const radioButtons = document.querySelectorAll('.choice-btnRadio input');

        // Check if any radio button in a group is checked
        function isAnyRadioPieceChecked() {
            const radioGroups = {};

            radioButtons.forEach(radioButton => {
                const groupName = radioButton.name;
                if (!radioGroups[groupName]) {
                    radioGroups[groupName] = [];
                }
                radioGroups[groupName].push(radioButton);
            });

            for (const groupName in radioGroups) {
                const radios = radioGroups[groupName];
                const isChecked = radios.some(radioButton => radioButton.checked);

                radios.forEach(radioButton => {
                    const label = radioButton.nextElementSibling;
                    if (label && label.tagName === 'LABEL') {
                        if (!isChecked) {
                            label.classList.add('error-postal');
                            hasError = true; // Mark error as true if none is checked
                            // label.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                        } else {
                            label.classList.remove('error-postal');
                        }
                    }
                });
            }
        }

        radioButtons.forEach(radioButton => {
            radioButton.addEventListener('change', function () {
                isAnyRadioPieceChecked(); // Re-check validation when a radio button changes
            });
        });

        isAnyRadioPieceChecked(); // Initial validation check for radio buttons

        function updateLabelClass() {
            const anyChecked = Array.from(checkboxes).some(cb => cb.checked);

            checkboxes.forEach(function (cb) {
                const label = cb.nextElementSibling;
                if (label && label.tagName === 'LABEL') {
                    if (!anyChecked) {
                        label.classList.add('error-postal');
                        hasError = true;
                        // label.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                    } else {
                        label.classList.remove('error-postal');
                    }
                }
            });
        }

        checkboxes.forEach(function (cb) {
            cb.addEventListener('change', function () {
                updateLabelClass();

                // Reset errors if 'aucunTravauxCheckbox' is checked
                if (aucunTravauxCheckbox.checked) {
                    checkboxes.forEach(function (cb) {
                        const label = cb.nextElementSibling;
                        if (label && label.tagName === 'LABEL') {
                            label.classList.remove('error-postal');
                        }
                    });
                    hasError = false; // Reset hasError since no work is being done
                }
            });
        });

        updateLabelClass();

        const errorPostalLabel = document.querySelector('.error-postal');

        if (errorPostalLabel && hasError) {
            e.preventDefault();
            errorPostalLabel.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            });
        }

        if (!hasError) {
            fetch('/render-housing', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(valueForm),
            })
                .then(response => response.json())
                .then(data => {
                    window.location.href = data.pathPiece;
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    });
}

let btnPopup = document.querySelectorAll('.simulhome-power-btn');

function getCheckedRadioValues(targetModal) {
    const radios = [...targetModal.querySelectorAll('input[type="checkbox"]')];
    const checkedValues = [];
    let isChecked = false;

    radios.forEach((input) => {
        if (input.checked) {
            arrayChecked.push(input.value);
            isChecked = true;
            return;
        }
    });

    radios.forEach(radioButton => {
        const label = radioButton.nextElementSibling;
        if (label && label.tagName === 'LABEL') {
            if (isChecked) {
                label.classList.remove('error-postal');
            } else {
                label.classList.add('error-postal');
            }
        }
    });

    return isChecked;
}

let arrayChecked = [];
let modalIds = [];
let isChecked = false;

function closeModal(btnClose, targetModal) {
    btnClose.addEventListener('click', function () {
        targetModal.classList.remove('d-block');
        targetModal.classList.add('d-none');
        targetModal.closest('.radiator.modal').classList.add('d-none');
    }, {once: true});
}

document.querySelectorAll('.radiator.modal').forEach(modal => {
    modal.addEventListener('click', function (event) {
        let content = modal.querySelector('.modal-content');
        if (!content.contains(event.target)) {
            modal.classList.remove('d-block');
            modal.classList.add('d-none');
        }
    });
});
let currentDataTypeBtn;
let firstChecked = null;

btnPopup.forEach(button => {
    button.addEventListener('click', function (el) {
        let hasError = false;
        let dataIdBtn = button.dataset.id;
        let dataTypeBtn = button.getAttribute('data-type');
        currentDataTypeBtn = dataTypeBtn;
        let targetModal = document.getElementById(dataTypeBtn);
        let parent = targetModal.closest('.radiator.modal');
        parent.classList.remove('d-none');
        parent.classList.add('d-block');
        targetModal.classList.remove('d-none');
        let btnClose = targetModal.querySelector('.simulhome-popup-close');
        let divPiece = el.target.closest('.simulhome-power');
        let typePiece = divPiece.getAttribute('data-type');


        const radios = parent.querySelectorAll(".simulhome-radio-choice input");
        const radiosLevel1 = parent.querySelectorAll(".options-recherche.level-1 input");
        const radiosLevel2 = parent.querySelectorAll(".options-recherche.level-2 input");

        closeModal(btnClose, targetModal);

        if (radiosLevel1.length) {
            radiosLevel1.forEach(radioButton => {
                radioButton.addEventListener('change', function (e) {
                    if (e.target.checked) {

                        let label = radioButton.parentElement.querySelector('label');
                        if (label) {
                            label.style.border = "1px solid #000000";
                        }
                        if (isAnyChecked(radiosLevel1) && !isAnyChecked(radiosLevel2)) {
                            radiosLevel1.forEach(rb => {
                                if (rb !== e.target) {
                                    rb.setAttribute('disabled', 'disabled');
                                }
                            });
                            firstChecked = 'level1';
                            radiosLevel2.forEach(rb => rb.setAttribute('disabled', 'disabled'));

                            let loaderRadiator = targetModal.querySelector('.radiator-loader');
                            loaderRadiator.classList.remove('d-none');
                            sendDataModal2(e.target, typePiece, targetModal, 'level-1');

                        } else {
                            radiosLevel2.forEach(rb => rb.setAttribute('disabled', 'disabled'));
                            radiosLevel1.forEach(rb => {
                                if (rb !== e.target) {
                                    rb.setAttribute('disabled', 'disabled');
                                }
                            });
                        }

                    } else {
                        if (firstChecked === "level1") {
                            radiosLevel1.forEach(rb => {
                                rb.removeAttribute('disabled');
                                let label = rb.parentElement.querySelector('label');
                                if (label) {
                                    label.style.border = "1px solid #dadbdc";
                                }
                            });
                            radiosLevel2.forEach(rb2 => {
                                rb2.removeAttribute('disabled');
                                rb2.setAttribute('data-value', 'false');
                                let label = rb2.parentElement.querySelector('label');
                                if (label) {
                                    label.style.border = "1px solid #dadbdc";
                                }
                            });
                        } else {
                            radiosLevel2.forEach(rb => {
                                if (!rb.checked) {
                                    rb.setAttribute('disabled', 'disabled');
                                } else {
                                    rb.removeAttribute('disabled');
                                }
                            });
                            radiosLevel1.forEach(rb2 => {
                                if (rb2.getAttribute('data-value') === 'true') {
                                    rb2.removeAttribute('disabled');
                                }
                            });

                        }

                    }
                });
            });
        }

        if (radiosLevel2.length) {
            radiosLevel2.forEach(rblevel2 => {
                rblevel2.addEventListener('change', function (e) {
                    if (e.target.checked) {

                        // Vérification si level2 est coché et level1 aussi
                        if (isAnyChecked(radiosLevel2) && !isAnyChecked(radiosLevel1)) {
                            radiosLevel1.forEach(rb => rb.setAttribute('disabled', 'disabled'));
                            radiosLevel2.forEach(rb => {
                                if (rb !== e.target) {
                                    rb.setAttribute('disabled', 'disabled');
                                }
                            });
                            firstChecked = 'level2';
                            let loaderRadiator = targetModal.querySelector('.radiator-loader');
                            loaderRadiator.classList.remove('d-none');

                            sendDataModal2(e.target, typePiece, targetModal, 'level-1');
                        } else {
                            radiosLevel1.forEach(rb => rb.setAttribute('disabled', 'disabled'));
                            radiosLevel2.forEach(rb => {
                                if (rb !== e.target) {
                                    rb.setAttribute('disabled', 'disabled');
                                }
                            });
                        }
                    } else {
                        if (firstChecked === "level2") {
                            radiosLevel1.forEach(rbLevel1 => {
                                if (!rbLevel1.checked) {
                                    rbLevel1.setAttribute('disabled', 'disabled');
                                    rbLevel1.setAttribute('data-value', 'false');
                                } else {
                                    rbLevel1.removeAttribute('disabled');
                                }
                            })
                        }
                        if (firstChecked === "level1") {
                            radiosLevel1.forEach(rb => {
                                if (!rb.checked) {
                                    rb.setAttribute('disabled', 'disabled');
                                } else {
                                    rb.removeAttribute('disabled');
                                }
                            });
                            radiosLevel2.forEach(rb2 => {
                                if (rb2.getAttribute('data-value') === 'true') {
                                    rb2.removeAttribute('disabled');
                                } else {
                                    rb2.setAttribute('disabled', 'disabled');

                                }
                            });
                        } else {
                            radiosLevel2.forEach(rb => {
                                rb.removeAttribute('disabled');
                                let label = rb.parentElement.querySelector('label');
                                if (label) {
                                    label.style.border = "1px solid #dadbdc";
                                }
                            });
                            radiosLevel1.forEach(rb2 => {
                                rb2.removeAttribute('disabled');
                                let label = rb2.parentElement.querySelector('label');
                                if (label) {
                                    label.style.border = "1px solid #dadbdc";
                                }
                            });
                        }

                    }
                });
            });
        }

        function isAnyChecked(radios) {
            return Array.from(radios).some(radio => radio.checked);
        }

        let submitpop = targetModal.querySelector('.cta-popup-submit');
        if (submitpop) {
            submitpop.replaceWith(submitpop.cloneNode(true));
            submitpop = targetModal.querySelector('.cta-popup-submit');
            submitModal(submitpop, radios, targetModal, typePiece);
        }
    });
});


function sendDataModal(dataModal) {
    fetch('/popup-data', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataModal),
    })
        .then(response => response.json())
        .then(data => {
            let targetModal = document.getElementById(currentDataTypeBtn);
            if (targetModal) {
                let btnClose = targetModal.querySelector('.simulhome-popup-close');
                btnClose.closest('.radiator.modal').classList.remove('d-block');
                btnClose.closest('.radiator.modal').classList.add('d-none');
            }

            let contents = data.content;
            let divProduct = document.querySelector(`[data-product="${currentDataTypeBtn}"]`);
            if (divProduct) {
                divProduct.classList.remove('d-none');
                let powerProduct = divProduct.querySelector('.power-products-wrapper');

                powerProduct.innerHTML = '';
                let contentLength = contents.length;
                divProduct.querySelector('.power-products-title').textContent = `${contentLength} ${contentLength > 1 ? 'gammes recommandées' : contentLength === 1 ? 'gamme recommandée' : 'Aucune gamme de produit ne correspond à vos critères.'}`;
                contents.slice(0, 3).forEach(content => {
                    let confortImages = '';
                    let economiesImages = '';

                    for (let i = 0; i < content.classement_confort.length; i++) {
                        confortImages += `<img src="/bundles/thermorprosite/images/simulHome/star.svg" alt="Star" class="star-image" />`;
                    }

                    for (let i = content.classement_confort.length; i < 3; i++) {
                        confortImages += `<img src="/bundles/thermorprosite/images/simulHome/star-off.svg" alt="Star Off" class="star-image" />`;
                    }

                    for (let i = 0; i < content.classement_economies.length; i++) {
                        economiesImages += `<img src="/bundles/thermorprosite/images/simulHome/star.svg" alt="Star" class="star-image" />`;
                    }

                    for (let i = content.classement_economies.length; i < 3; i++) {
                        economiesImages += `<img src="/bundles/thermorprosite/images/simulHome/star-off.svg" alt="Star Off" class="star-image" />`;
                    }

                    powerProduct.innerHTML += `
                        <div class="product-item power-products-item">
                            <img src="${content['URL_photoa1-fr_FR-ecommerce']}" class="power-products-visuel"/>
                            <h3 class="item-title">${content.gamme}</h3>
                            <div class="item-price">
                                <p class="item-price-label"> à partir de</p>
                                <p class="item-price-price">${content.etl_tarif_a_partir} € H.T</p>
                            </div>
                            <div class="items-note">
                                <div class="item-note">
                                    <span class="item-note-label">Confort:</span>
                                    <span class="item-note-stars">${confortImages}</span>
                                </div>
                                <div class="item-note">
                                    <span class="item-note-label">Économies:</span>
                                    <span class="item-note-stars">${economiesImages}</span>
                                </div>
                            </div>
                            <a href="/recherche?search=${encodeURIComponent(content.gamme)}" class="btn-product">Voir la documentation</a>
                        </div>
                    `;
                });
            }
            isEnvironmentSet()
        })
        .catch(error => {
            console.error('Error:', error);
        });
}


function submitModal(submitpop, radios, targetModal, typePiece) {
    submitpop.addEventListener('click', function () {
        let checkedValues = [...new Set(arrayChecked)];
        let checkedRadio = targetModal.querySelectorAll('input[type="checkbox"]:checked');

        radios.forEach((input) => {
            if (input.checked) {
                checkedValues.push(input.value);
            }
        });
        if (checkedValues && Array.isArray(checkedValues)) {
            checkedValues.forEach(value => {
                let baseValue = value.split('__')[0] + '__';
                let extractedValue = value.split('__')[1];
                let modalId = targetModal.getAttribute('id');
                let dataSpecification = document.querySelector(`[data-specification='${modalId}']`);
                let baseElement = dataSpecification.querySelector(`.${baseValue}`);
                if (baseElement) {
                    baseElement.classList.remove('d-none');
                    let specValueElement = baseElement.querySelector('.spec-value');
                    if (specValueElement) {
                        specValueElement.textContent = extractedValue;
                        const rythmeVie = baseElement.querySelector('.type_lame__ .spec-value');
                        const rythmeVieMapping = {
                            'Les 2': 'Hybride',
                            'Lame': 'Lames plates',
                            'Tube Rond': 'Tube ronds',
                            'Graphique': 'Graphiques'
                        };

                        const mappedValue = rythmeVieMapping[specValueElement.textContent];
                        if (rythmeVie && mappedValue) {
                            rythmeVie.textContent = mappedValue;
                        }

                        let niveauEconomieElement = baseElement.querySelector('.niveau_economie__ .spec-value');
                        if (niveauEconomieElement) {
                            niveauEconomieElement.textContent = 'Oui';
                        }
                    }
                }
            });
        } else {
            if (checkedValues) {
                let baseValue = checkedValues.split('__')[0] + '__';
                let extractedValue = checkedValues.split('__')[1];
                let modalId = targetModal.getAttribute('id');
                let dataSpecification = document.querySelector(`[data-specification='${modalId}']`);
                let baseElement = dataSpecification.querySelector(`.${baseValue}`);

                if (baseElement) {
                    baseElement.classList.remove('d-none');
                    let specValueElement = baseElement.querySelector('.spec-value');

                    if (specValueElement) {
                        specValueElement.textContent = extractedValue;
                        const rythmeVie = baseElement.querySelector('.type_lame__ .spec-value');
                        const rythmeVieMapping = {
                            'Les 2': 'Hybride',
                            'Lame': 'Lames plates',
                            'Tube Rond': 'Tube ronds',
                            'Graphique': 'Graphiques'
                        };

                        const mappedValue = rythmeVieMapping[specValueElement.textContent];
                        if (rythmeVie && mappedValue) {
                            rythmeVie.textContent = mappedValue;
                        }

                        let niveauEconomieElement = baseElement.querySelector('.niveau_economie__ .spec-value');
                        if (niveauEconomieElement) {
                            niveauEconomieElement.textContent = 'Oui';
                        }
                    }
                }
            }
        }

        const dataModal = {
            selectedRadios: arrayChecked,
            type: typePiece,
            idModal: targetModal.getAttribute('id')
        };

        const checkedValuesModal = getCheckedRadioValues(targetModal);
        if (checkedValuesModal &&  checkedRadio.length > 1) {
            sendDataModal(dataModal);
        }
        arrayChecked = [];

    });
}

function disableBtnModal(input,targetModal){
    let btnSubmitModal = targetModal.querySelector('.cta-popup-submit');
    input.addEventListener('change', function() {
        if (input.getAttribute('data-value') === 'true' && input.checked){
            btnSubmitModal.removeAttribute("disabled");
        }else{
            btnSubmitModal.setAttribute('disabled', 'disabled');
        }
    })
}

function sendDataModal2(radios, typePiece, targetModal, level) {
    let dataModal = {
        valueRadio: radios.value,
        type: typePiece
    }
    fetch('/popup-data2', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataModal),
    })
        .then(response => response.json())

        .then(data => {
            let dataArray = Object.values(data).flatMap(obj => Object.values(obj));

            if (dataArray.length !== 0) {
                let loaderRadiator = targetModal.querySelector('.radiator-loader');
                loaderRadiator.classList.add('d-none');
                let btnSubmitModal = targetModal.querySelector('.cta-popup-submit');
                let inputsToCheck = targetModal.querySelectorAll('input[type="checkbox"]');
                const normalizedDataArray = dataArray.map(item => item.toLowerCase().trim());
                inputsToCheck.forEach(function (input) {
                    disableBtnModal(input,targetModal);
                    const normalizedValue = input.value.toLowerCase().trim();
                    if (!normalizedDataArray.includes(normalizedValue)) {
                        input.setAttribute('disabled', 'disabled');
                        radios.removeAttribute('disabled');
                        let labelChecked = radios.parentElement.querySelector('label');
                        labelChecked.style.border = "1px solid #84838a";
                        input.setAttribute('data-value', 'false');
                    } else {
                        input.removeAttribute('disabled');
                        input.setAttribute('data-value', 'true');
                        let currentLabel = input.parentElement.querySelector('label');
                        currentLabel.style.border = "1px solid #84838a";

                    }
                });
            }
            else {
                let loaderRadiator = targetModal.querySelector('.radiator-loader');
                if (loaderRadiator) loaderRadiator.classList.add('d-none');
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
}

let saveProjectRd = document.querySelectorAll('.save-project-radiator .saveDim-submit');
let downloadPdfRd = document.querySelector('.download-pdf-radiator');

if (saveProjectRd) {
    saveProjectRd.forEach(btnSave => {
        btnSave.addEventListener('click', function (e) {
            e.preventDefault();
            fetch('/create-project-radiator', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(response => response.json())

                .then(data => {
                    isEnvironmentSet();
                    const toastSelector = data.success === 'updated' || data.success === 'created'
                        ? '.successSave'
                        : '.failToast';
                    togglePopupRadiator('closed', 'true');
                    displayToastRadiator(toastSelector, 'flex', 3000);

                    if (data.pathPdf && downloadPdfRd){
                        downloadPdfRd.setAttribute('href', data.pathPdf);
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        })
    })
}

function togglePopupRadiator(state, ariaState) {
    const popup = document.querySelector(".PopupSave");
    if (popup) {
        popup.setAttribute('data-popup-state', state);
        popup.setAttribute('aria-hidden', ariaState);
    }
}

function displayToastRadiator(toastClass, displayStyle, duration,position = '') {
    const toast = document.querySelector(toastClass);
    if (toast) {
        toast.style.display = displayStyle;
        if (position )toast.style.position = position;
        setTimeout(() => {
            toast.style.display = "none";
        }, duration);
    }
}

function checkRadiatorList() {
    const listRadiators = document.querySelectorAll('.list-radiator');
    const searchRadiators = document.querySelector('.DimclimUp-search.search-radiators');
    const emptyRadiatorMessage = document.querySelector('.empty-list-radiator');
    if (searchRadiators && emptyRadiatorMessage) {
        const hasNoRadiators = listRadiators.length === 0;
        searchRadiators.classList.toggle('d-none', hasNoRadiators);
        emptyRadiatorMessage.classList.toggle('d-none', !hasNoRadiators);
    }
}

document.addEventListener("DOMContentLoaded", function () {
    checkRadiatorList();
});

let deleteRadiator = document.querySelectorAll('.delete-radiator');
let lineLIstRadiators = document.querySelector('.radiator-line-project');
if (lineLIstRadiators){
    if (deleteRadiator) {
        deleteRadiator.forEach(function (element) {
            let parentBtnDelete = element.closest('.BlockProjectChauffe-eau--actions');
            let btnDelete = parentBtnDelete ? parentBtnDelete.querySelector('.radiator-btn-delete') : '';

            if (btnDelete) {
                btnDelete.addEventListener("click", function (e) {
                    element.classList.remove('d-none');
                    element.querySelector('.PopupDelete').style.display = "block";
                    let ProjectId = element.getAttribute('data-id');
                    let confirmDeleteBtn = element.querySelector('.PopupDelete--actions #delete-confirm');

                    if (confirmDeleteBtn) {
                        confirmDeleteBtn.addEventListener("click", function (e) {
                            e.preventDefault();

                            let overlay = document.createElement('div');
                            overlay.classList.add('radiator-overlay');
                            overlay.classList.add('d-none');

                            let loaderDelete = document.createElement('div');
                            loaderDelete.classList.add('radiator-loader-delete');
                            loaderDelete.classList.add('d-none'); // Initially hidden

                            let mainElement = document.querySelector('main');
                            if (mainElement) {
                                mainElement.insertAdjacentElement('beforebegin', overlay);
                                mainElement.insertAdjacentElement('beforebegin', loaderDelete);
                            }

                            document.querySelector('main').classList.add('blur-radiator');
                            overlay.classList.remove('d-none');
                            loaderDelete.classList.remove('d-none');

                            loaderDelete.innerHTML = `<img src="/bundles/thermorprosite/images/animation/thermor-animation-chargement.svg" alt="chargement"/>`; // Add any loader animation here

                            let dataDelete = {projectId: ProjectId};

                            fetch('/delete-radiator', {
                                method: 'POST',
                                headers: {'Content-Type': 'application/json'},
                                body: JSON.stringify(dataDelete),
                            })
                                .then(response => response.json())
                                .then(data => {
                                    loaderDelete.classList.add('d-none');
                                    overlay.classList.add('d-none');
                                    document.querySelector('main').classList.remove('blur-radiator');

                                    let divToDelete = document.querySelector(`[data-project-id="${ProjectId}"]`);
                                    if (divToDelete) {
                                        divToDelete.remove();
                                    }

                                    let successMessage = document.querySelector('.successSave.delete-alert');
                                    if (successMessage) {
                                        displayToastRadiator(".successSave.delete-alert", "flex", 3000);
                                        let divToDelete = document.querySelector(`.div-delete-water-heater [id="${ProjectId}"]`);
                                        if(divToDelete){
                                            successMessage.querySelector('p')
                                        }
                                        if (window.location.hash === '#delete') {
                                            history.replaceState(null, null, window.location.pathname + window.location.search);
                                        }
                                    }
                                    checkRadiatorList();
                                })
                                .catch(error => {
                                    console.error('Error:', error);
                                    loaderDelete.classList.add('d-none');
                                    overlay.classList.add('d-none');
                                    document.querySelector('main').classList.remove('blur-radiator');
                                });
                        });
                    }
                });
            }
        });
    }
}


let downloadPdfRadiator = document.querySelectorAll('.download-pdf-radiator');
if (downloadPdfRadiator) {
    downloadPdfRadiator.forEach(function (element) {
        element.addEventListener('click', function (event) {
            event.preventDefault();
            const loadingDragonPiece = document.querySelector('.loader-page-piece');
            if (loadingDragonPiece) {
                loadingDragonPiece.style.display = 'block';
            }
            let pdfUrl = element.getAttribute('href');
            fetch('/download-pdf-radiator', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({pdfUrl: pdfUrl}),
            })
                .then(response => {
                    if (!response.ok) {
                        displayToastRadiator(".failToast-load-pdf", "flex !important", 3000,'fixed');
                        throw new Error(`Failed to download PDF: ${response.status} ${response.statusText}`);
                    }
                    return response.blob();
                })
                .then(blob => {
                    if (loadingDragonPiece) loadingDragonPiece.style.display = 'none';
                    const downloadUrl = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = downloadUrl;
                    a.download = 'radiator_synthese.pdf';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    URL.revokeObjectURL(downloadUrl);
                    isEnvironmentSet();
                })
                .catch(error => {
                    console.error('Error occurred while downloading PDF:', error);
                    displayToastRadiator(".failToast-load-pdf", "flex !important", 3000,'fixed');
                    if (loadingDragonPiece) loadingDragonPiece.style.display = 'none';
                });
        });
    });
}
isEnvironmentSet();
function isEnvironmentSet() {
    const environmentElement = document.getElementById('app-environment');
    if (environmentElement) {
        fetch('/request-body-apis', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
        })
            .then(response => response.json())
            .then(data => {
                console.log(data)
            })
            .catch(error => {
                console.error(error);
            });
    }
}

function validateConstructionYear() {
    const constructionYearError = document.getElementById("constructionYearError");
    const currentYear = new Date().getFullYear();

    constructionYearInput.addEventListener("blur", () => {
        const value = parseInt(constructionYearInput.value, 10);

        if (isNaN(value) || value < 1800 || value > currentYear) {
            constructionYearError.style.display = "block";
            constructionYearInput.classList.add("error-postal");
        } else {
            constructionYearError.style.display = "none";
            constructionYearInput.classList.remove("error-postal");
        }
    });

    const value = parseInt(constructionYearInput.value, 10);
    if (isNaN(value) || value < 1800 || value > currentYear) {
        constructionYearError.style.display = "block";
        constructionYearInput.classList.add("error-postal");
        return false;
    } else {
        constructionYearError.style.display = "none";
        constructionYearInput.classList.remove("error-postal");
        return true;
    }

}