import {deleteCookie} from '../../scripts/utilities';

const inputProjectSearch = document.getElementById("ProjectSearch_home");
const isChauffeEauProjects = document.getElementById("BlockProjectChauffe-eau--wrapper");

if(inputProjectSearch && isChauffeEauProjects) {
    inputProjectSearch.addEventListener("keyup", searchChauffEauProject);
}

if(isChauffeEauProjects) {
    document.querySelector('.DimclimUpChauffEau-search').querySelector('.TransparentButton--wrapper a').addEventListener('click', function() {
        deleteCookie('new_installation');
        deleteCookie('reference_chauffe_eau');
        deleteCookie('replace_chauffe-eau');
    })
}

function searchChauffEauProject() {
    const filter = inputProjectSearch.value.toUpperCase();
    const projectList = document.getElementById("BlockProjectChauffe-eau--wrapper");
    const projectName = projectList.querySelectorAll(".BlockProjectChauffe-eau");
    for (let i = 0; i < projectName.length; i++) {
        const label = projectName[i].getElementsByTagName("h2")[0];
        const txtValue = label.textContent || label.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
            projectName[i].style.display = "";
        } else {
            projectName[i].style.display = "none";
        }
    }
}