
(function () {

    /*
       This code block allows to create fielsets tags into ezform forms.

       Fieldsets are defined in a variable named ezform_group_definitions_content_<content_id>. Ex:
       ezform_group_definitions_content_111 = {
           "Group 1" => [1, 2, 3],
           "Group 2" => [4, 5, 6],
           // "<fieldset legend>" => [ list of fields positions to put in the fieldset (starts from 1 to N) ]
       }
     */
    document.querySelectorAll('.Ezform').forEach(formContainer => {
        const contentId = formContainer.dataset.contentId

        // Looking for a global variable ezform_group_definitions_content_XXX which contains field groups definition (generated in template)
        const groupDefinitions = eval('ezform_group_definitions_content_'+contentId)
        if (groupDefinitions === undefined) {
            return
        }

        const fieldContainers = formContainer.querySelector('form > div')
        fieldContainers.classList.add('Ezform-group')
        const fields = formContainer.querySelectorAll('.Ezform-field')

        // Looping over reverse keys because of usage of 'prepend' function
        Object.keys(groupDefinitions).reverse().forEach(groupName => {

            // Creating fieldset + legend tags
            const fieldset = document.createElement('fieldset')
            fieldset.classList.add('Ezform-fieldset')
            const legend = document.createElement('legend')
            legend.classList.add('Ezform-legend')
            legend.appendChild(document.createTextNode(groupName))
            fieldset.appendChild(legend)

            fieldContainers.prepend(fieldset)

            // Moving fields into fieldset
            groupDefinitions[groupName].forEach(fieldNumber => {
                if (fields.item(fieldNumber - 1) !== null) {
                    fieldset.appendChild(fields.item(fieldNumber - 1))
                }
            })
        });

        /* Submit btn processing */
        // Move button outside of its nameless div, and replace it into <form> elem (formContainer.children[0])
        const submitBtn = formContainer.querySelector('button[type=submit]')
        if(submitBtn){
            submitBtn.classList.add('PrimaryButton')
            formContainer.children[0].appendChild(submitBtn)
        }

        /* Select processing */
        const selects = formContainer.querySelectorAll('select')
        selects.forEach((select) => {
            // Add the Form elem class, and a wrapper tag necessary for advanced styles
            select.classList.add('Form-select')
            const selectParentTag = document.createElement('div')
            selectParentTag.classList.add('Form-selectWrapper')
            select.parentNode.insertBefore(selectParentTag, select)
            selectParentTag.appendChild(select)

            // Add a disabled first option to avoid
            // select looking empty on its first value
            select.children[0].innerText = "Veuillez sélectionner une option"
            select.children[0].setAttribute('disabled', 'true')
        })

        /* Isolated Checkbox */
        // If a label is immediately followed by a checkbox
        // Switch their order, from (label + input) to (input + label)
        // identify only label + input checkboxes
        const checkboxes = formContainer.querySelectorAll('.Ezform-field > label + input[type=checkbox]')
        checkboxes.forEach((checkbox) => {
            if(checkbox.previousSibling !== null &&
               checkbox.previousSibling.tagName === 'LABEL') {
                const label = checkbox.previousSibling
                label.parentNode.insertBefore(checkbox, label)
            }
        })
    })
})();
