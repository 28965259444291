// import Cart from '../../components/cart/cart';
import { getCookie } from '../../scripts/utilities'

(function() {

    const cookieValueJson = getCookie('is_logged_in');

    if(cookieValueJson != null){
        const cookieValue = JSON.parse(cookieValueJson);
        const loginSpan = document.querySelector('[data-login-text]');

        loginSpan !== null && (loginSpan.textContent = 'Bonjour ' + cookieValue.name);
    }

    // Saving target path when clicking on the login link in order to restore the current URL after login
    const loginLink = document.querySelector('a.FullHeaderTop-login-link');
    if (loginLink !== null) {
        loginLink.addEventListener('click', async (event) => {
            event.preventDefault();

            // saving current URL to session
            let postData = new FormData();
            postData.append('target', window.location.href);
            await fetch(Routing.generate('thermor_pro_set_target_path'), { method: 'POST', body: postData });

            // following the initial link URL
            window.location.href = loginLink.href;
        });

        const popupConnection = document.querySelector('#popup-connection');
        if (popupConnection !== null) {
            popupConnection.addEventListener('click', async (event) => {
                event.preventDefault();

                // saving current URL to session
                let postData = new FormData();
                postData.append('target', window.location.href);
                await fetch(Routing.generate('thermor_pro_set_target_path'), { method: 'POST', body: postData });

                // following the initial link URL
                window.location.href = loginLink.href;
            });
        }
    }

}());

const expandBtns = document.querySelectorAll('[data-expand-megamenu]');


(() => { 
    if (!expandBtns) return

    expandBtns.forEach((btn)=> {
        const menuItem = btn.closest('.FullHeaderTop-item');
        const megaMenu = menuItem.querySelector('.FullHeaderTop-megamenu');

        btn.addEventListener('click', (e)=> {

            if (megaMenu.classList.contains('FullHeaderTop-megamenu--enter')) {
                megaMenu.classList.remove('FullHeaderTop-megamenu--enter');
            }
            megaMenu.classList.toggle('FullHeaderTop-megamenu--show');
        });

        menuItem.addEventListener('mouseenter', (e)=> {
            if (megaMenu.classList.contains('FullHeaderTop-megamenu--show')) {
                megaMenu.classList.remove('FullHeaderTop-megamenu--show');
            }
            megaMenu.classList.add('FullHeaderTop-megamenu--enter');
        });
        menuItem.addEventListener('mouseleave', (e)=> {
            if (megaMenu.classList.contains('FullHeaderTop-megamenu--show')) {
                megaMenu.classList.remove('FullHeaderTop-megamenu--show');
            }
            megaMenu.classList.remove('FullHeaderTop-megamenu--enter');
        });

    });

 })();

 const FullHeaderTopStiky = document.querySelector(".FullHeaderTop");

 if(FullHeaderTopStiky) {
 
    window.addEventListener('scroll' , function () {  
        const scoll = window.scrollY;
 
        if(scoll > 100) FullHeaderTopStiky.classList.add('fixed');
        else FullHeaderTopStiky.classList.remove('fixed');
    })
 }