import Swiper from 'swiper';

(function() {
    const carousel = document.querySelectorAll('[data-carousel]');

    if (carousel.length < 1) {
        return;
    }
    initSlider(carousel);

    function initSlider(carousel) {
        const sliderThumbs = new Swiper('.Carousel-container', {
            navigation: {
                nextEl: '.Carousel-arrows--next',
                prevEl: '.Carousel-arrows--prev',
            },
            // Default parameters
            slidesPerView: 'auto',
            watchOverflow: true
        });
    }
} ());
