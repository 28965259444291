/** Toggle the mobile navigation */
(function() {
  var CONTAINER_CLASS = "Order";
  var CONTAINER_OPEN_CLASS = "Order--open";
  var TOGGLE_CLASS = "Order-toggle";
  var SUMMARY_CLASS = "Order-summary";
  var DETAILS_CLASS = "Order-details";

  init();

  /**
   * Add event listeners
   */
  function init() {
    var containers = document.querySelectorAll("." + CONTAINER_CLASS);
    for (var i = 0; i < containers.length; i++) {
      var component = getOrderComponent(containers[i]);
      component.button.addEventListener("click", onToggleButton);
      component.summary.addEventListener("click", onSummaryClick);
    }
  }

  /**
   * Query the DOM for data about the ancestor Order component
   */
  function getOrderComponent(element) {
    var container = closest(element, "." + CONTAINER_CLASS);
    return {
      container: container,
      open: Boolean(container && container.classList.contains(CONTAINER_OPEN_CLASS)),
      button: container && container.querySelector("." + TOGGLE_CLASS),
      summary: container && container.querySelector("." + SUMMARY_CLASS),
      details: container && container.querySelector("." + DETAILS_CLASS)
    };
  }

  function setOpen(component) {
    component.button.setAttribute("aria-expanded", "true");
    component.container.classList.add(CONTAINER_OPEN_CLASS);
    component.details.hidden = false;
  }

  function setClosed(component) {
    component.button.setAttribute("aria-expanded", "false");
    component.container.classList.remove(CONTAINER_OPEN_CLASS);
    component.details.hidden = true;
  }

  function toggleOpen(component) {
    component.open ? setClosed(component) : setOpen(component);
  }

  /**
   * Toggle Order component's open state on toggle button click
   */
  function onToggleButton(event) {
    event.stopPropagation();
    var component = getOrderComponent(event.currentTarget);
    toggleOpen(component);
  }

  /**
   * Toggle Order component's open state on summary click
   */
  function onSummaryClick(event) {
    event.stopPropagation();
    var component = getOrderComponent(event.currentTarget);
    toggleOpen(component);
  }

  /**
   * Polyfill for element.closest
   */
  function closest(element, selector) {
    if (typeof element.closest === "function") {
      return element.closest(selector);
    }
    if (!document.documentElement.contains(element)) {
      return null;
    }
    do {
      if (element.matches(selector)) return element;
      element = element.parentElement || element.parentNode;
    } while (element !== null && element.nodeType === 1);
    return null;
  }
})();
