import { Formio } from 'formiojs';

let apiUrl = window.location.protocol + "//" + window.location.host;
let urlParams = new URLSearchParams(window.location.search);
let objectString =  {}
let department_pool = "";

function getCookie (name) {
    let value = `; ${document.cookie}`;
    let parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

function converToString(data) {
    Object.keys(data).forEach(k => {
   
    if (k !== 'submit') {
      data[k] = '' + data[k];
    }        
  });
  
   return data;
}

const isPacAirStep1TertiairePage = document.getElementById('pac-air-tertiaire-step1');
if (isPacAirStep1TertiairePage) {
// get default values of formio from cookies
    if (getCookie("pac_air_tertiaire_step1") !== undefined) {
        var pac_tertiaire = JSON.parse(getCookie("pac_air_tertiaire_step1"));
    }
    await fetch('/dep', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    }).then(function (response) {
        return response.json();
    }).then(function (data) {

        department_pool = Object.entries(data.department).map(([key, value]) => {
            return {value: key, label: value };
        }).sort((a, b) => {
            const numA = parseInt(a.label.substr(0, 2)); // Extract the numeric part
            const numB = parseInt(b.label.substr(0, 2)); // Extract the numeric part
            return numA - numB;
        });
    });

    setTimeout(() => {
        if (document.querySelector(".formLoader")) document.querySelector(".formLoader").remove();
    }, 200);

    Formio.createForm(document.getElementById('pac-air-tertiaire-step1'), {
        components: [
            {
                components: [
                    {
                        "input": false,
                        "html": "<span>Informations générales</span>",
                        "type": "content",
                        "key": "content",
                        "customClass": "red-block--title step-1",
                    },
                    {
                        "columns": [
                            {
                                components: [
                                    {
                                        tabindex: "1",
                                        type: 'textfield',
                                        key: 'nom',
                                        label: 'Nom du projet',
                                        input: true,
                                        defaultValue: pac_tertiaire?.nom || "",
                                        validate: {
                                            required: true,
                                            maxLength: '30'
                                        }
                                    },
                                    {
                                        tabindex: "3",
                                        type: "select",
                                        label: "Type de bâtiment",
                                        key: "type",
                                        placeholder: "Veuillez sélectionner une option",
                                        defaultValue: pac_tertiaire?.type || "",
                                        data: {
                                            values: [
                                                {value: 'commerce', label: 'Commerce'},
                                                {value: 'salle-de-sport', label: 'Salle de sport'},
                                                {value: 'bureaux', label: 'Bureaux'},
                                                {value: 'restaurant', label: 'Restaurant'}
                                            ]
                                        },
                                        validate: {
                                            required: true
                                        },
                                        dataSrc: "values",
                                        template: "<span>{{ item.label }}</span>",
                                        input: true
                                    },
                                    {
                                        tabindex: "5",
                                        type: "number",
                                        label: "Surface (en m<sup>2</sup>)",
                                        key: "Surface",
                                        defaultValue: pac_tertiaire?.Surface || "",
                                        validate: {
                                            required: true
                                        },
                                        input: true
                                    }
                                ],
                                "width": 6,
                                "offset": 0,
                                "push": 0,
                                "pull": 0,
                                "size": "md",
                                "currentWidth": 6
                            },
                            {
                                components: [
                                    {
                                        tabindex: "2",
                                        type: "select",
                                        label: "Département",
                                        key: "departement",
                                        placeholder: "Veuillez sélectionner une option",
                                        data: {
                                            values: department_pool
                                        },
                                        defaultValue: (pac_tertiaire && pac_tertiaire.departement && pac_tertiaire.departement > 0 && pac_tertiaire.departement <= department_pool.length) ? department_pool[pac_tertiaire.departement - 1].value : '',
                                        validate: {
                                            required: true
                                        },
                                        template: "<span>{{ item.label }}</span>",
                                        input: true
                                    },
                                    {
                                        tabindex: "4",
                                        type: "select",
                                        label: "Date de construction",
                                        key: "date_construction",
                                        placeholder: "Veuillez sélectionner une option",
                                        defaultValue: pac_tertiaire?.date_construction || "",
                                        data: {
                                            values: [
                                                {value: '< 1974', label: 'Avant 1974'},
                                                {value: '< 1982', label: 'Entre 1971 et 1981'},
                                                {value: '> 1982', label: 'Entre 1982 et 1999'},
                                                {value: '> 2000', label: 'Entre 2000 et 2004'},
                                                {value: '> 2005', label: 'Entre 2005 et 2012'},
                                                {value: '> 2013', label: 'Après 2013'}
                                            ]
                                        },
                                        validate: {
                                            required: true
                                        },
                                        dataSrc: "values",
                                        template: "<span>{{ item.label }}</span>",
                                        input: true
                                    },
                                    {
                                        tabindex: "6",
                                        type: "number",
                                        label: "Hauteur sous plafond (en m)",
                                        key: "HauteurPlafond",
                                        defaultValue: pac_tertiaire?.HauteurPlafond || "",
                                        validate: {
                                            required: true
                                        },
                                        input: true
                                    }
                                ],
                                "width": 6,
                                "offset": 0,
                                "push": 0,
                                "pull": 0,
                                "size": "md",
                                "currentWidth": 6
                            }
                        ],
                        "type": "columns",
                        "conditional": {
                            "show": "",
                            "when": null,
                            "eq": ""
                        },
                        "key": "columns",
                        "customClass": "red-block pd"
                    }
                ]
            },
            {
                components: [
                    {
                        "input": false,
                        "html": "<span>Apports internes</span>",
                        "type": "content",
                        "key": "content",
                        "customClass": "red-block--title step-1",
                    },
                    {
                        "columns": [
                            {
                                components: [
                                    {
                                        tabindex: "1",
                                        type: 'number',
                                        key: "nb_utilisateurs",
                                        label: "Nombre maximal d'occupants",
                                        input: true,
                                        defaultValue: pac_tertiaire?.nb_utilisateurs || "",
                                        validate: {
                                            required: true
                                        }
                                    },
                                    {
                                        tabindex: "4",
                                        type: "number",
                                        label: "Nombre de photocopieurs",
                                        key: "nb_photocopieurs",
                                        defaultValue: pac_tertiaire?.nb_photocopieurs || "",
                                        validate: {
                                            required: true
                                        },
                                        input: true
                                    }
                                ],
                                "width": 4,
                                "offset": 0,
                                "push": 0,
                                "pull": 0,
                                "size": "md",
                                "currentWidth": 4
                            },
                            {
                                components: [
                                    {
                                        tabindex: "2",
                                        type: "number",
                                        label: "Eclairage (en W)",
                                        key: "eclairage",
                                        defaultValue: pac_tertiaire?.eclairage || "",
                                        validate: {
                                            required: true
                                        },
                                        input: true
                                    },
                                    {
                                        tabindex: "5",
                                        type: "number",
                                        label: "Nombre d'imprimantes",
                                        key: "nb_imprimantes",
                                        defaultValue: pac_tertiaire?.nb_imprimantes || "",
                                        validate: {
                                            required: true
                                        },
                                        input: true
                                    }
                                ],
                                "width": 4,
                                "offset": 0,
                                "push": 0,
                                "pull": 0,
                                "size": "md",
                                "currentWidth": 4
                            },
                            {
                                components: [
                                    {
                                        tabindex: "3",
                                        type: "number",
                                        label: "Nombre d'ordinateurs",
                                        key: "nb_ordinateurs",
                                        defaultValue: pac_tertiaire?.nb_ordinateurs || "",
                                        validate: {
                                            required: true
                                        },
                                        input: true
                                    },
                                    {
                                        tabindex: "6",
                                        type: "number",
                                        label: "Autre apports (en W)",
                                        key: "apports",
                                        defaultValue: pac_tertiaire?.apports || "",
                                        validate: {
                                            required: true
                                        },
                                        input: true
                                    }
                                ],
                                "width": 4,
                                "offset": 0,
                                "push": 0,
                                "pull": 0,
                                "size": "md",
                                "currentWidth": 4
                            }
                        ],
                        "type": "columns",
                        "conditional": {
                            "show": "",
                            "when": null,
                            "eq": ""
                        },
                        "key": "columns",
                        "customClass": "red-block pd"
                    },
                ]
            },
            {
                components: [
                    {
                        "input": false,
                        "html": "<span>Apports externes</span>",
                        "type": "content",
                        "key": "content",
                        "customClass": "red-block--title step-1",
                    },
                    {
                        "columns": [
                            {
                                components: [
                                    {
                                        tabindex: "1",
                                        type: "number",
                                        key: "surface_fenetres_ensoleillees",
                                        label: "Surface des fenêtres ensoleillées (en m<sup>2</sup>)",
                                        suffix: ' ',
                                        disabled: true,
                                        defaultValue: pac_tertiaire?.surface_fenetres_ensoleillees || "",
                                        validate: {
                                            required: true
                                        },
                                        input: true,
                                        customClass: "calculatedField"
                                    },
                                    {
                                        tabindex: "3",
                                        label: "Ces fenêtres ensoleillées possèdent elles des volets ou des stores pouvant être fermés en fin de journées ?",
                                        optionsLabelPosition: "right",
                                        inline: true,
                                        tableView: false,
                                        defaultValue: pac_tertiaire?.choix_volet || "0",
                                        values: [
                                            {
                                                "label": "Oui",
                                                "value": "1"
                                            },
                                            {
                                                "label": "Non",
                                                "value": "0"
                                            }
                                        ],
                                        key: "choix_volet",
                                        type: "radio",
                                        inputType: "radio",
                                        input: true
                                    }
                                ],
                                "width": 6,
                                "offset": 0,
                                "push": 0,
                                "pull": 0,
                                "size": "md",
                                "currentWidth": 6
                            },
                            {
                                components: [
                                    {
                                        tabindex: "2",
                                        type: "number",
                                        key: "autres_fenetres",
                                        label: "Surface des autres fenêtres (en m<sup>2</sup>)",
                                        suffix: ' ',
                                        disabled: true,
                                        defaultValue: pac_tertiaire?.autres_fenetres || "",
                                        validate: {
                                            required: true,
                                        },
                                        input: true,
                                        customClass: "calculatedField"
                                    },
                                    {
                                        tabindex: "4",
                                        type: "textfield",
                                        key: "surface_murs_ensoleilles",
                                        label: "Surface des murs ensoleillés (en m<sup>2</sup>)",
                                        suffix: ' ',
                                        disabled: true,
                                        defaultValue: pac_tertiaire?.surface_murs_ensoleilles || "",
                                        validate: {
                                            required: true,
                                            maxLength: '4'
                                        },
                                        input: true,
                                        customClass: "calculatedField"
                                    },
                                    {
                                        tabindex: "6",
                                        type: "select",
                                        label: "Type de ventilation",
                                        key: "type_ventilation",
                                        placeholder: "Veuillez sélectionner une option",
                                        defaultValue: pac_tertiaire?.type_ventilation || "",
                                        data: {
                                            values: [
                                                {value: 'Simple Flux', label: 'Simple Flux'},
                                                {value: 'Hygro-réglable', label: 'Hygro-réglable'},
                                                {value: 'Double Flux', label: 'Double Flux'}
                                            ]
                                        },
                                        validate: {
                                            required: true
                                        },
                                        dataSrc: "values",
                                        template: "<span>{{ item.label }}</span>",
                                        input: true
                                    }
                                ],
                                "width": 6,
                                "offset": 0,
                                "push": 0,
                                "pull": 0,
                                "size": "md",
                                "currentWidth": 6
                            }
                        ],
                        "type": "columns",
                        "conditional": {
                            "show": "",
                            "when": null,
                            "eq": ""
                        },
                        "key": "columns",
                        "customClass": "red-block pd"
                    },
                ]
            },
            {
                components: [
                    {
                        type: 'button',
                        action: 'submit',
                        label: 'Voir la synthèse',
                        theme: 'primary'
                    }
                ]
            }
        ]
    }, {
        language: 'en-US',
        decimalSeparator: '.',
        delimiter: ',',
        i18n: {
            'en-US': {
                required: 'Ce champ est obligatoire',
                max: 'Ne peut pas être supérieur à {{max}} .',
                maxLength: 'Ne peut pas être supérieur à {{length}} caractère.',
                'No results found': 'Aucun résultat pour cette recherche',
                'Type to search': 'Écrivez pour lancer une recherche'
            }
        }
    }).then(function (form) {
        let button = document.querySelectorAll(".calculatedField .input-group"),
            inputName;
        const submitBtn = document.querySelector('.formio-component-submit').querySelector('button');
        const popupWrapper = document.querySelector(".popup-wrapper");

        submitBtn.addEventListener('click', function () {
            const errors = document.querySelectorAll('.formio-errors');
            let brk = true;

            errors.forEach(error => {
                if (error.querySelector('.form-text.error')?.isConnected && brk) {

                    error.parentNode.scrollIntoView();
                    brk = false;
                }
            })
        })

        function setCalculatedValue(value, res) {
            form.getComponent(value).setValue(res);
        }


        button.forEach(btn => {
            btn.addEventListener('click', () => {
                inputName = btn.querySelector("input").name.match(/(?<=\[)[^\][]*(?=])/g)[0];
                Formio.createForm(document.getElementById('popupCalculator'), {
                    components: [
                        {
                            components: [
                                {
                                    type: 'button',
                                    action: 'event',
                                    label: ' ',
                                    theme: 'primary',
                                    customClass: 'cancel cancelBtn'
                                },
                                {
                                    "input": false,
                                    "html": "<p>ENTREZ LES DIMENSIONS POUR CALCULER LA SURFACE EN M<sup>2</sup></p>",
                                    "type": "content",
                                    "key": "content",
                                },
                                {
                                    "columns": [
                                        {
                                            components: [
                                                {
                                                    tabindex: "1",
                                                    type: 'number',
                                                    key: 'h',
                                                    label: 'Hauteur (en m)',
                                                    input: true,
                                                    inputType: 'text',
                                                    validate: {
                                                        min: 1,
                                                        required: true
                                                    }
                                                },
                                            ],
                                            "width": 5,
                                            "offset": 0,
                                            "push": 0,
                                            "pull": 0,
                                            "size": "md",
                                            "currentWidth": 5
                                        },
                                        {
                                            components: [
                                                {
                                                    tabindex: "2",
                                                    type: 'htmlelement',
                                                    tag: 'p',
                                                    className: 'text-center',
                                                    content: 'x'
                                                }
                                            ],
                                            "width": 2,
                                            "offset": 0,
                                            "push": 0,
                                            "pull": 0,
                                            "size": "md",
                                            "currentWidth": 2
                                        },
                                        {
                                            components: [
                                                {
                                                    tabindex: "3",
                                                    type: 'number',
                                                    label: 'Largeur (en m)',
                                                    key: 'l',
                                                    input: true,
                                                    inputType: 'text',
                                                    validate: {
                                                        min: 1,
                                                        required: true
                                                    }
                                                }
                                            ],
                                            "width": 5,
                                            "offset": 0,
                                            "push": 0,
                                            "pull": 0,
                                            "size": "md",
                                            "currentWidth": 5
                                        }
                                    ],
                                    "type": "columns",
                                    "conditional": {
                                        "show": "",
                                        "when": null,
                                        "eq": ""
                                    },
                                    "key": "columns",
                                },
                                {
                                    type: 'textfield',
                                    label: 'Résultat (en m<sup>2</sup>)',
                                    key: 'total',
                                    input: true,
                                    inputType: 'text',
                                    disabled: true,
                                    calculateValue: {
                                        "*": [
                                            {var: 'data.h'},
                                            {var: 'data.l'}
                                        ]
                                    }
                                },
                                {
                                    "columns": [
                                        {
                                            components: [
                                                {
                                                    tabindex: "1",
                                                    type: 'button',
                                                    action: 'event',
                                                    label: 'Annuler',
                                                    theme: 'primary',
                                                    customClass: 'cancelBtn'
                                                }
                                            ],
                                            "width": 6,
                                            "offset": 0,
                                            "push": 0,
                                            "pull": 0,
                                            "size": "md",
                                            "currentWidth": 6
                                        },
                                        {
                                            components: [
                                                {
                                                    tabindex: "2",
                                                    type: 'button',
                                                    action: 'submit',
                                                    label: 'Valider',
                                                    theme: 'primary',
                                                    customClass: 'validateBtn'
                                                }
                                            ],
                                            "width": 6,
                                            "offset": 0,
                                            "push": 0,
                                            "pull": 0,
                                            "size": "md",
                                            "currentWidth": 6
                                        }
                                    ],
                                    "type": "columns",
                                    "conditional": {
                                        "show": "",
                                        "when": null,
                                        "eq": ""
                                    },
                                    "key": "columns"
                                }
                            ],
                            customClass: "popup"
                        }
                    ]
                }, {
                    language: 'en-US',
                    decimalSeparator: '.',
                    delimiter: ',',
                    i18n: {
                        'en-US': {
                            required: 'Ce champ est obligatoire',
                            min: 'Ne peut pas être inférieur à {{min}} .'
                        }
                    }
                }).then(function (form) {

                    let firstInputCalculatedPopin = document.querySelector('.popupCalculator').querySelector('.formio-component-h input');

                        firstInputCalculatedPopin.addEventListener('keydown', e => {

                                if(e.keyCode === 9) {
                                    e.preventDefault();
                                    let nextEl = firstInputCalculatedPopin.closest('.formio-component.formio-component-columns').querySelector('.formio-component-l input');
                                    nextEl.focus();                  
                                }
                    })

                    form.on('submit', function () {
                        let result = document.querySelector('input[name="data[total]"]').value;
                        popupWrapper.style.display = "none";
                        setCalculatedValue(inputName, result);
                    });

                    document.querySelectorAll('.cancelBtn').forEach(item => {
                        item.addEventListener('click', () => {
                            popupWrapper.style.display = "none";
                        })
                    })
                })
                popupWrapper.style.display = "block";
            });
        });

        form.on('submit', function (submission) {
            let idDim = 0;
            let canBeSaved = true;
            let jsonSubmissionData = converToString(submission.data); 

            localStorage.removeItem("SaveDim");
            
            if(getCookie("pac_air_tertiaire_step1") !== undefined) {
                var oldCookie = getCookie("pac_air_tertiaire_step1");
                let jsonOldCookie = JSON.parse(oldCookie);
                idDim = jsonOldCookie['idDim'];
                delete jsonOldCookie['canBeSaved'];
                delete jsonOldCookie['idDim'];
                if (_.isEqual(jsonOldCookie, jsonSubmissionData)) {
                    canBeSaved = false;
                }
            }
            jsonSubmissionData['canBeSaved'] = canBeSaved;
            jsonSubmissionData['idDim'] = idDim;

            objectString = JSON.stringify(jsonSubmissionData);
            document.cookie = "pac_air_tertiaire_step1=" + objectString + "; expires=" + (new Date(Date.now() + 3600 * 1000)).toUTCString() + "; path=/";
            var urlParams = new URLSearchParams(window.location.search);

            // Check if the "id" parameter exists
            if (urlParams.has('id')) {
                // Parameter exists, get its value
                var id = urlParams.get('id');
                window.location.href = apiUrl + "/simulhome/pac-air-air/nouvelle-installation/tertiaire/synthese" + "?id=" + encodeURIComponent(id);
            } else {
                // Parameter doesn't exist
                window.location.href = apiUrl + "/simulhome/pac-air-air/nouvelle-installation/tertiaire/synthese";
            }
        });
    });
}