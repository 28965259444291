class Popup {
     constructor(popupElement, links , event="click"){
         this.popupElement = popupElement;
         this.links = links;
         this.event=event;
         this.init();
     }

    init(){

         if (location.hash === '#'+ this.popupElement.dataset.popup) {
            this.open();
        }

        // loop on each button with data-popup-link attribute
        this.links.forEach(link => {
            if (link){
                link.addEventListener(this.event, () => {
                    this.open();
                });
            }
        });


        // Close button (cross) click
        
        this.popupElement.querySelectorAll('[data-popup-close]').forEach(cls => {
            cls.onclick = (function () {
                this.close();
            }).bind(this)
        })
    
        // Escape key hit
         document.addEventListener('keyup', (function (e) {
            if (e.key === 'Escape' && this.popupElement.dataset.popupState === 'opened') {
                this.close();
            }
        }).bind(this))
     }

    /**
     * Open the popup
     */
    open() {
         this.popupElement.setAttribute('aria-hidden', 'false');
         this.popupElement.setAttribute('data-popup-state', 'opened');
         document.querySelector('body').classList.add('popupOverlay');

         location.hash = "#" + this.popupElement.dataset.popup;

         document.addEventListener("click", (function (event) {
             // If user clicks on the opening link or inside the element, do nothing
             if (event.target.closest("[data-popup-link]") || event.target.closest("[data-popup-state]") ) return;
             // else, hide popup
             this.close();
         }).bind(this))
     }

    /**
     * Close the popup
     */
     close() {
        this.popupElement.setAttribute('aria-hidden', 'true');
        this.popupElement.setAttribute('data-popup-state', 'closed');
        document.querySelector('body').classList.remove('popupOverlay');
        if (location.hash != '') {
            history.replaceState(null, null, window.location.href.substring(0, window.location.href.indexOf('#')));
        }
     }
}

export default Popup