
(function() {

  // Test if we are in register form context
  const form = document.querySelector('[data-form=user_edit]')
  if(form == null) {
    return
  }

  // define timer used to not spam autcomplete request on each key
  let typingTimer

  // DQE Check on phone field
  document.querySelectorAll('[data-input=phone]').forEach(phone => {
    checkPhone(phone)
    phone.addEventListener('keypress', function () {
      clearTimeout(typingTimer)
      typingTimer = setTimeout(() => {
        checkPhone(phone)
      }, 500)
    })
    phone.addEventListener('focusout', () => {
      checkPhone(phone)
    })
  })

  document.querySelectorAll('[data-input=default]').forEach(input => {
    checkBasicRequiredField(input)
    input.addEventListener('change', function () {
      checkBasicRequiredField(input)
    })
  });

  document.querySelectorAll('[data-input=name]').forEach(input => {
    checkName(input)

    input.addEventListener('keypress', function () {
      clearTimeout(typingTimer)
      typingTimer = setTimeout(() => { checkName(input) }, 500)
      input.addEventListener('focusout', () => { checkName(input) })
    })
  });

  // Check the first or last name format
  function checkName(input) {
    if (/^[-'a-zA-ZÀ-ÖØ-öø-ÿ]+[ \-'a-zA-ZÀ-ÖØ-öø-ÿ]*$/i.test(input.value)) {
      setFieldValid(input)
      removeError(input.closest('.Form-group'))
    }
    else {
      setFieldInvalid(input)
      setFieldErrorMessage(input, "Veuillez saisir un nom ou prénom valide")
    }
  }

  // Check phone input
  function checkPhone(phoneInput) {
    if (phoneInput.value == '') {
      phoneInput.closest('div').classList.remove('Form-group--valid')
      return
    }

    let requestUri = '/dqe-validate-phone/' + encodeURI(phoneInput.value)
    if(phoneInput.getAttribute('data-phone-type') != null) {
      requestUri += '/' + encodeURI(phoneInput.getAttribute('data-phone-type'))
    }
    fetch(requestUri)
      .then(data => data.json())
      .then((result) => {
        const formGroup = phoneInput.parentNode

        if (result) {
          phoneInput.closest('div').classList.add('Form-group--valid')
          phoneInput.closest('div').classList.remove('Form-group--error')
          phoneInput.setAttribute('data-valid', 'true');
          removeError(formGroup)
        } else {
          phoneInput.closest('div').classList.add('Form-group--error')
          phoneInput.closest('div').classList.remove('Form-group--valid')
          phoneInput.setAttribute('data-valid', 'false');

          if(formGroup.querySelectorAll('.Form-errorMessage--single').length == 0) {
            if (phoneInput.getAttribute('data-phone-type') === 'FIXE') {
              setFieldErrorMessage(phoneInput, "Veuillez saisir un numéro de téléphone fixe valide")
            }
            else if (phoneInput.getAttribute('data-phone-type') === 'MOBILE') {
              setFieldErrorMessage(phoneInput, "Veuillez saisir un numéro de téléphone portable valide")
            }
            else {
              setFieldErrorMessage(phoneInput, "Veuillez saisir un numéro de téléphone valide")
            }
          }
        }
      })
  }

  function setFieldValid(input) {
    input.closest('.Form-group').classList.remove('Form-group--error')
    input.closest('.Form-group').classList.add('Form-group--valid')
  }

  function setFieldInvalid(input) {
    input.closest('.Form-group').classList.remove('Form-group--valid')
    input.closest('.Form-group').classList.add('Form-group--error')
  }

  function setFieldNeutral(input) {
    input.closest('.Form-group').classList.remove('Form-group--valid')
    input.closest('.Form-group').classList.remove('Form-group--error')
  }

  function setFieldErrorMessage(input, message) {
    if (input.nextElementSibling !== null && input.nextElementSibling.classList.contains('Form-errorMessage--single')) {
      input.nextElementSibling.remove();
    }

    const error = document.createElement('p')
    error.classList.add('Form-errorMessage--single')
    error.innerText = message
    input.after(error)
  }

  /**
   * Checks whether or not an input is filled and colors the input in green if valid.
   * @param input
   */
  function checkBasicRequiredField(input) {
    if (input.value == "")
      input.closest('.Form-group').classList.remove('Form-group--valid')
    else
      input.closest('.Form-group').classList.add('Form-group--valid')
  }

  function removeError(formGroup) {
    if(formGroup.querySelectorAll('.Form-errorMessage--single').length > 0) {
      formGroup.querySelectorAll('.Form-errorMessage--single').forEach( error => {
        error.remove()
      })
    }
  }

}());