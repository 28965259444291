
(function() {

  if (document.querySelector('[data-form=update-preferences]') === null) {
    return
  }

  document.querySelectorAll('.Form-group').forEach((group) => {
    if (group.querySelector('.Form-subgroup') === null) {
      return
    }

    // check-all checkbox element
    const groupCheckbox = group.querySelector('input[type=checkbox]')

    let updateCheckboxGroupStatus = function(groupElement, checkboxElement) {
      checkboxElement.checked = (groupElement.querySelectorAll('.Form-subgroup input[type=checkbox]:not(:checked)').length === 0)
    }
    updateCheckboxGroupStatus(group, groupCheckbox)

    groupCheckbox.addEventListener('change', (event) => {
      const status = event.target.checked
      group.querySelectorAll('.Form-subgroup input[type=checkbox]').forEach((subcheckbox) => {
        subcheckbox.checked = status
      })
    })

    group.querySelectorAll('.Form-subgroup input[type=checkbox]').forEach((subcheckbox) => {
      subcheckbox.addEventListener('change', (event) => {
        updateCheckboxGroupStatus(group, groupCheckbox)
      })
    })
  });

}());
