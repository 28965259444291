(function() {
    const accordions = document.querySelectorAll('[data-accordion]');

    if(accordions != null || accordions > 0) {
        accordions.forEach((accordion) => {
            let button = accordion.children[0]
            let panel = accordion.children[1]

            button.addEventListener("click", event => {
                if(panel.getAttribute('aria-expanded') == 'false') { // closed
                    panel.setAttribute('aria-expanded', true)
                    accordion.setAttribute('data-state', 'opened')
                }
                else { // opened
                    panel.setAttribute('aria-expanded', false)
                    accordion.setAttribute('data-state', 'closed')
                }
            })
        })
    }
} ());
