document.addEventListener('DOMContentLoaded', function() {
    const DOWNLOAD_LINK_CLASS = 'downloadPdfLink';
    const downloadPdfLinks = document.querySelectorAll(`.${DOWNLOAD_LINK_CLASS}`);
    const PDF_PARAMS_CLASS = 'pdfParams';
    const LOADER_CLASS = 'loader-thermor';

    downloadPdfLinks.forEach(downloadPdfLink => {
        const pdfParamsInput = downloadPdfLink.querySelector(`.${PDF_PARAMS_CLASS}`);
        const loaderHtml = downloadPdfLink.closest('td').querySelector(`.${LOADER_CLASS}`);

        const showLoader = () => {
            loaderHtml.style.display = 'block';
            downloadPdfLink.style.display = 'none';
        };

        const hideLoader = () => {
            loaderHtml.style.display = 'none';
            downloadPdfLink.style.display = 'block';
        };

        const generatePdf = () => {
            const pdfParams = JSON.parse(pdfParamsInput.value);
            const { transactionId, prestataire } = pdfParams?.dataTiers ?? {};
            const prefixPdf = prestataire ? `${prestataire}_` : '';
            const suffixPdf = transactionId || '';
            const separator = prestataire && transactionId ? '_' : '';

            fetch('/generate-pdf', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(pdfParams)
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok.');
                    }
                    return response.blob();
                })
                .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = `${prefixPdf}${separator}${suffixPdf}.pdf`;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    hideLoader();
                });
        };

        downloadPdfLink.addEventListener('click', function(event) {
            event.preventDefault();
            showLoader();
            generatePdf();
        });
    });
});
