import Popup from '../popup/popup'

class PopupSave extends Popup{

    init(){
        super.init()
        this.openPopupSave()
    }

    openPopupSave() {

    }

}

export default PopupSave