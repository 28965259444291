(function() {
    const loaderForm = document.querySelector("[data-loader]")

    if (!loaderForm) {
        return
    }

    const loaderLayout = document.querySelector("#Loader").content.cloneNode(true).childNodes[1]

    const body = document.querySelector('body')

    loaderForm.addEventListener("submit", (e) => {
        e.preventDefault()
        window.addEventListener('keydown', (event) => {
            if (event.key == 'Tab') {
                event.preventDefault()
            }
        })
        body.prepend(loaderLayout)
        e.target.submit()
    })

}());