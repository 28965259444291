import Popup from '../popup/popup'


class PopupOrder extends Popup{

    init(){
        super.init()
        this.openPopupOnGuideProFormSubmit()
    }

    openPopupOnGuideProFormSubmit() {
        const form = document.getElementById('formGuidePro');
        if(form !== null){
            if(form.dataset.formResult === 'success')
            {
                //TODO changer ce truc dégueulasse
                let guideProFormContent = document.querySelector('.formContent');
                guideProFormContent.innerHTML = '<p>Vous la recevrez sous 6 jours à l\'adresse que vous nous avez indiquée</p>'
                document.querySelector('.Form-subtitle').innerText = 'Votre commande a bien été prise en compte'
                this.open()
            } else if (form.dataset.formResult === 'fail'){
                this.open()
            }
        }
    }
}

export default PopupOrder