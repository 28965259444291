
/**
 * Detect all links which on which the custom style "thermor_pro_login_button" has been applied and replace href to set
 * the SSO login URL with the initial href as a redirect parameter.
 */
(function() {
    const header = document.querySelector('header');

    // Testing if the user is already connected (if the header contains the class "user-connected")
    if (header !== null && header.classList.contains('user-connected')) {
        return;
    }

    document.querySelectorAll('.ThermorPro-Bouton--login').forEach((container) => {
        const link = container.querySelector('a');
        if (link === null) return;

        const href = link.href;
        link.href = Routing.generate('thermor_pro_sso_login_page') + '?redirect=' + encodeURI(href);
    });
}());
