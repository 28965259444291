
function initPhoneButtonsClick(phoneButtonsSpecific) {
        let phoneButtons = null
        if(phoneButtonsSpecific) {
            phoneButtons = phoneButtonsSpecific;
        } else {
            phoneButtons = document.querySelectorAll('[data-phone]');
        }
        phoneButtons.forEach(phoneButton => {
            phoneButton.addEventListener("click", event => {
            const newPhoneButton = document.createElement('a')
            newPhoneButton.classList.add('PrimaryButton')
            newPhoneButton.classList.add('PrimaryButton--smallest')
            newPhoneButton.classList.add('PrimaryButton--uppercase')
            newPhoneButton.classList.add('CardStation-button')
            newPhoneButton.setAttribute('href', 'tel:' + phoneButton.dataset.phone)
            newPhoneButton.innerHTML = phoneButton.dataset.phone
            phoneButton.parentNode.replaceChild(newPhoneButton, phoneButton)
            })
        })

}

(function() {
    let phoneButtons = document.querySelectorAll('[data-phone]');
    if (!phoneButtons && phoneButtons == null || phoneButtons < 1)
        return;

    initPhoneButtonsClick(phoneButtons);
} ());



export { initPhoneButtonsClick }
