import Player from '@vimeo/player';

document.addEventListener("DOMContentLoaded", event => {
    const videoPlayers = document.querySelectorAll('.VideoPlayer');// an array of all videoplayers on page
    if (videoPlayers == null || videoPlayers.length < 1) {
        return;
    }

    videoPlayers.forEach(videoPlayer => {
        initVideoPlayer(videoPlayer);
    })
}, {'once': true});

function initVideoPlayer(videoPlayer) {
    /******
     * Variables
     */
    let childVideoContainer = videoPlayer.querySelector('.VideoPlayer-mainiframe') // defined as a let, can either be a div or an iframe -- redefined if elem changes
    let videoUrl = childVideoContainer.getAttribute('data-video-url');
    const parts = videoUrl.split('/');
    const videoId = decodeURIComponent(parts[parts.length - 1]);
    const arrayAdditionalVideos = videoPlayer.querySelectorAll('.VideoPlayer-additionalline') // an array that contains all additional videos
    const autoPlay = '?autoplay=1' // autoplay attribute to add to URL

    let prevButtons = videoPlayer.querySelectorAll('[data-button="prev"]')
    let nextButtons = videoPlayer.querySelectorAll('[data-button="next"]') // declared for first onclick event, then redefined

    /******
     * Click events
     */
    // Clicking on main video
    childVideoContainer.addEventListener('click', clickMainVideo);

    // Clicking on each video from the playlist
    arrayAdditionalVideos.forEach(video => // for loop on each additional video
        video.addEventListener('click', clickAdditionnalVideo)
    )

    // Clicking on each next button
    nextButtons.forEach(nextButton => {
        nextButton.addEventListener('click', clickNextButton)
    })

    // Clicking on each previous button
    prevButtons.forEach(prevButton => {
        prevButton.addEventListener('click', clickPrevButton)
    })

    /******
     * Utilities
     *****/

    function clickMainVideo(event) {
        let mainIframe = '<iframe class="VideoPlayer-mainiframe" src="'+childVideoContainer.dataset.videoUrl + autoPlay + '" frameborder="0" width="100%" height="480" allow="autoplay; fullscreen" allowfullscreen></iframe>'
        // building iframe with correct src values

        var newEl = document.createElement('div')
        newEl.innerHTML = mainIframe
       if (childVideoContainer) childVideoContainer.parentNode?.replaceChild(newEl, childVideoContainer)
      if (childVideoContainer)  childVideoContainer = videoPlayer.querySelector('.VideoPlayer-mainiframe') // redefining cause element type changescd ib
    }

    function clickAdditionnalVideo(event) {
        changeVideo(event.target)
    }

    function clickNextButton(event) {
        const nextButton = event.target;
        if (nextButton.getAttribute('data-target-video-id') == arrayAdditionalVideos.length + 1) {
            return
        }
        const targetVideoId = nextButton.dataset.targetVideoId
        const nextVideo = videoPlayer.querySelector('[data-video-id="' + targetVideoId + '"]')
        changeVideo(nextVideo)
    }

    function clickPrevButton(event) {
        const prevButton = event.target;
        if (prevButton.getAttribute('data-target-video-id') == 0) {
            return
        }
        const targetVideoId = prevButton.dataset.targetVideoId;
        const prevVideo = videoPlayer.querySelector('[data-video-id="' + targetVideoId + '"]')
        changeVideo(prevVideo)
    }

    function changeVideo(video) {
        // Looping on each additional video and remove any selected class
        arrayAdditionalVideos.forEach(videoListItems =>
            videoListItems.classList.remove('VideoPlayer-additionalline--selected')
        )
        // add selected class
        video.classList.add('VideoPlayer-additionalline--selected');


        let parentElement = video.parentNode; // Getting parent element of video -- .VideoPlayer

        while (parentElement != null && parentElement.classList.contains('VideoPlayer') !== true) {
            parentElement = parentElement.parentNode;
        }

        let videoContainerMainIframe = parentElement.querySelector('.VideoPlayer-mainiframe')
        let mainVideoTitle = parentElement.getElementsByClassName('VideoPlayer-mainvideotitle')
        let prevButton = parentElement.querySelector('[data-button="prev"]')
        let nextButton = parentElement.querySelector('[data-button="next"]')
        let elementCount = parentElement.getElementsByClassName('VideoPlayer-current')
        let additionalVideos = Array.from(parentElement.getElementsByClassName('VideoPlayer-additionalline'))
        let nextVideo = additionalVideos[additionalVideos.indexOf(video) + 1]
        let prevVideo = additionalVideos[additionalVideos.indexOf(video) - 1]

        if (videoContainerMainIframe.nodeName == 'IFRAME') {// check if iframe is present in page
            videoContainerMainIframe.setAttribute("src", video.dataset.videoUrl + autoPlay)
        }
        else {
            let mainIframe = '<iframe class="VideoPlayer-mainiframe" src="'+video.dataset.videoUrl + autoPlay + '" width="100%" height="480"  frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>'
            // building iframe with correct src values
            var newEl = document.createElement('div')
            newEl.innerHTML = mainIframe
            videoContainerMainIframe.parentNode.replaceChild(newEl, videoContainerMainIframe)
            videoContainerMainIframe = videoPlayer.querySelector('.VideoPlayer-mainiframe') // redefining cause element type changes
        }

        mainVideoTitle[0].innerHTML = video.dataset.videoTitle// change main title with video selected
        elementCount[0].innerHTML = additionalVideos.indexOf(video) + 1

        if (prevVideo !== undefined) {
            prevButton.setAttribute('data-target-video-id', parseInt(prevVideo.getAttribute('data-video-id')))
            prevButton.classList.remove('VideoPlayer-arrow--disabled')
        } else {
            prevButton.classList.add('VideoPlayer-arrow--disabled')
        }

        if (nextVideo !== undefined) {
            nextButton.setAttribute('data-target-video-id', parseInt(nextVideo.getAttribute('data-video-id')))
            nextButton.classList.remove('VideoPlayer-arrow--disabled')
        } else {
            nextButton.classList.add('VideoPlayer-arrow--disabled')
        }
    }


    /*****
     * Cookie consent overlay : if the user has not accepted cookies, disables all videos and wait for the user to accept cookies to reload the page.
     *****/
    const cookieConsentBackgroundImage = '/bundles/thermorprosite/images/video/cookie-video-disallow.png';

    // @see https://www.cookiebot.com/fr/developer/ for Cookiebot API
    if (typeof Cookiebot == 'object' && !Cookiebot.consented) {
        // Add an event listener on cookie consent to reload the page once the user has accepted
        window.addEventListener('CookiebotOnAccept', function (e) {
            location.reload();
        }, false);

        disableVideo(childVideoContainer, true); // Main video
        arrayAdditionalVideos.forEach(video => { // for loop on each additional video
            disableVideo(video, false);
        })

        // Disabling prev/next buttons
        nextButtons.forEach(nextButton => {
            nextButton.removeEventListener('click', clickNextButton)
        })
        prevButtons.forEach(prevButton => {
            prevButton.removeEventListener('click', clickPrevButton)
        })
    }

    /**
     * Disables play link, replaces it by Cookiebot modal, changes background image to ask user to accept cookies.
     * @param {Object} videoContainer The video HTML element
     * @param {boolean} isMainVideo Whether or not the video is in the main placeholder
     */
    function disableVideo(videoContainer, isMainVideo) {
        // Removing click events for playing videos
        if (isMainVideo) {
            videoContainer.removeEventListener('click', clickMainVideo);
        }
        else {
            videoContainer.removeEventListener('click', clickAdditionnalVideo);
        }

        // A click on the video displays the Cookiebot modal
        videoContainer.addEventListener('click', () => {
            Cookiebot.renew();
        });

        // Setting up new background image
        let button = videoContainer.querySelector('button.VideoPlayer-iframe, button.VideoPlayer-addiframe');
        if (button !== null) {
            button.style.backgroundImage = "url('" + cookieConsentBackgroundImage + "')";
        }

        // Removing the play picto
        let playPicto = videoContainer.querySelector('svg.icon-videoplay');
        if (playPicto !== null) {
            playPicto.remove();
        }
    }
}