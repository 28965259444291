import Popup from '../popup/popup'
import utilities from "../../scripts/utilities";
class PopupSearch extends Popup{

    init(){
        super.init()
        this.openPopupSearch()
    }

    openPopupSearch() {

    }

}

export default PopupSearch