import {getCookie} from "../../scripts/utilities";

const notification = document.querySelector('[data-notification]');
const notificationCloseButton = document.querySelector('[data-notification-close]');
const documentationItems = document.querySelectorAll('[data-product-favorited]');

if (documentationItems.length > 0 &&  notification != null) { // check if there's a notification loaded in page + some documentation items
    initDocNotification();
}

function initDocNotification() {
    const cookieValueJson = getCookie('is_logged_in');

    notificationCloseButton.addEventListener('click', () => {
        notification.setAttribute('aria-hidden', 'true')
    });

    const favButtonsArray = document.querySelectorAll('.TeaserFav-favorite')
    favButtonsArray.forEach(favButton => {
        favButton.addEventListener('click', () => {
            let selected = favButton.parentElement.getAttribute('aria-selected')
            if(cookieValueJson != null && selected == 'false') {// deselection, so remove from localStorage
                showNotification(notification);
                setTimeout(() => {
                    hideNotification(notification);
                }, 5000)
            }
        })
    })
}

function showNotification(notification) {
    notification.setAttribute('aria-hidden', 'false')
}

function hideNotification(notification) {
    notification.setAttribute('aria-hidden', 'true')
}
