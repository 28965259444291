import $ from 'jquery'
export function getCookie(name) {
    var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? decodeURIComponent(v[2]) : null;
}

export function setCookie(name, value, domain, path, days, sameSite) {
    let cookieString = name + '=' + encodeURIComponent(value);
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        cookieString += "; expires=" + date.toUTCString();
    }
    if (domain) { cookieString += '; domain=' + domain; }
    if (path) { cookieString += '; path=' + path; }
    if (sameSite) { cookieString += '; sameSite=' + sameSite; }
    if (window.location.protocol === 'https:') { cookieString += '; secure=true'; }

    document.cookie = cookieString;
}

export function deleteCookie(name, domain, path) {
    if (getCookie(name)) {
        let cookieString = name + "=" +
            ((path) ? "; path=" + path: "") +
            ((domain)? "; domain=" + domain : "") +
            '; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
        ;

        document.cookie = cookieString;
    }
}

export async function getData(url){
    try {
        const response = await fetch(url)

        const jsonData = await response.json()
        return jsonData

    } catch(exception){
        console.error(exception)
    }
}

export function createElementFromHTML(htmlString) {
    var div = document.createElement('div');
    div.innerHTML = htmlString.trim();

    // Change this to div.childNodes to support multiple top-level nodes.
    return div.firstChild;
}
var setVimeoVideos = function() {
    const videoButton = document.querySelector('.BlockVideo-iframe');
    const closeVideoButton = document.querySelectorAll('.video-pop-close');
    if (videoButton == null || !videoButton) {
        return;
    }
    let typeDoc = '';
    if (document.querySelector('.data-documents')) {
        let dataDocuments = JSON.parse(document.querySelector('.data-documents').value);
        typeDoc = dataDocuments?.type;
    }

    const videoThumbnails = document.querySelectorAll('.video-pl .BlockVideo-Thumbnails, .BlockVideo-mainiframe-top');
    $(videoThumbnails).off('click');
    videoThumbnails.forEach(function (thumbnail) {
        thumbnail.addEventListener('click', function (e) {
            e.stopPropagation();

            const mainIframe = document.createElement('iframe');
            mainIframe.style.width = '95%';
            mainIframe.style.marginTop = '70px';
            mainIframe.style.height = '60%';
            mainIframe.className = 'BlockVideo-mainiframe';
            mainIframe.src = 'https://player.vimeo.com/video/' + thumbnail.getAttribute('data-video-id') + '?autoplay=1';
            mainIframe.setAttribute('frameborder', '0');
            mainIframe.setAttribute('allow', 'autoplay; fullscreen');
            mainIframe.setAttribute('width', '100%');
            mainIframe.setAttribute('height', 'auto');
            mainIframe.setAttribute('allowfullscreen', '');

            const newDiv = document.getElementById('video-pop');
            newDiv.style.display = 'block';
            newDiv.appendChild(mainIframe);
        });
    });
    closeVideoButton.forEach(function (closeVideo) {

        closeVideo.addEventListener('click', function(e) {

            const videoPop = closeVideo.closest('.video-pop');


            if (videoPop && videoPop.style.display !== 'none') {
                videoPop.style.display = 'none';
                const mainIframe = videoPop.querySelector('.BlockVideo-mainiframe');
                if (mainIframe) {
                    // Arrêtez la vidéo en remplaçant l'URL src par une chaîne vide
                    mainIframe.src = '';

                    // Supprimez l'élément iframe de son parent
                    mainIframe.parentNode.removeChild(mainIframe);
                }
            }
        });
    });
}


export default {getCookie, setCookie, deleteCookie, getData, createElementFromHTML, setVimeoVideos}
