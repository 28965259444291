const contactButtons = document.querySelectorAll('[data-contact-tag]');

contactButtons.forEach(function (contactButton) {
    if(contactButton != null) {
        const button = contactButton.querySelector('[data-contact-button]');
        const subText = contactButton.querySelector('[data-contact-sub-text]');
        const buttonInner = button.querySelector('[data-link-inneritem]')

        button.addEventListener('click', event => {
            if(subText.innerHTML === '') {
                event.preventDefault()
                changeButtonlabel(button, subText, buttonInner)
            }
        })
    }
})


function changeButtonlabel(button, subText, buttonInner) {
    buttonInner.innerHTML= button.dataset.contactAltLabel
    button.href = button.dataset.contactLink;
    subText.innerHTML = subText.dataset.contactSubText
    subText.setAttribute('aria-hidden', 'false')
}
