(function () {
    const NewRegistrationForm = document.querySelector("[data-newRegistrationStep]")

    if (!NewRegistrationForm) {
        return
    }

    const passwordButton = document.querySelector("[data-newRegistrationStep-password]")
    if (passwordButton) {
        const passwordInput = document.getElementById('step3_password');

        passwordButton.addEventListener("click", () => {
            if (passwordInput.type == "password") {
                passwordInput.type = "text"
            } else if (passwordInput.type == "text") {
                passwordInput.type = "password"
            }
        })
    }

    let siretInfosContainer = document.querySelector('[data-infosCompanyContainer]')
    let activityInputs = document.querySelector('[data-activityInputs]')
    const companyNameInput = document.getElementById('step2_company_name')
    const accountCategoryLabel = document.querySelector("label[for='step2_account_category']")
    const accountCategoryInput = document.getElementById('step2_account_category')
    const businessSectorLabel = document.querySelector("label[for='step2_business_sector']")
    const businessSectorInput = document.getElementById('step2_business_sector')
    const hiddenAccountCategoryInput = document.getElementById('step2_hidden_account_category')
    const hiddenBusinessSectorInput = document.getElementById('step2_hidden_business_sector')
    const siretInput = document.getElementById('step2_siret')
    const submitInput = document.getElementById('step2_submit')
    const autocompleteInfoMessage = document.querySelector('.SearchBar-result-infomessage')
    const autocompleteListResults = document.querySelector('.SearchBar-results[data-result=b2b]')
    const autocompleteNoResultErrorMessage = document.querySelector('.AutocompleteNoResultErrorMessage')

    let nature_type = [accountCategoryInput, businessSectorInput];
    nature_type.forEach((element) => {
        if(element){
            element.addEventListener('input', function (e) {

                if (businessSectorInput.value === "") {
                    submitInput.setAttribute('disabled', 'disabled')
                } else {
                    submitInput.removeAttribute('disabled');
                }
                if(accountCategoryInput.value === ""){
                    submitInput.setAttribute('disabled', 'disabled')
                }
            });
        }
    });

    let typingTimer
    document.querySelectorAll('[data-input=companyName]').forEach(companyName => {
        companyName.addEventListener('keyup', () => {
            submitInput.setAttribute('disabled', 'disabled')
            autocompleteNoResultErrorMessage.setAttribute('aria-hidden', 'true')

            if (companyName.value != "") {
                clearTimeout(typingTimer);
                typingTimer = setTimeout(() => {
                    companyNameInput.parentNode.classList.remove('Form-group--error')
                    fetch('/dqe-company-search-name/' + encodeURI(companyName.value))
                        .then(data => data.json())
                        .then((result) => {
                            result = JSON.parse(result)
                            companySearchAutocomplete(result)
                        })
                }, 200);
            } else {
                clearTimeout(typingTimer);
                clearAutocompleteList()
            }
        })

        // Hide autocomplete list when clicking outside of it
        // document.addEventListener("click", event => {
        //     if (event.target !== companyName && event.target !== listResults) {
        //         autocompleteListResults.setAttribute('aria-hidden', 'true')
        //     }
        // })
    })

    function synchronizeNatureAndTypeHiddenInputs() {
        hiddenAccountCategoryInput.value = accountCategoryInput.value
        hiddenBusinessSectorInput.value = businessSectorInput.value
    }

    if (accountCategoryInput !== null) {
        synchronizeNatureAndTypeHiddenInputs()
        accountCategoryInput.addEventListener('change', (event) => {
            synchronizeNatureAndTypeHiddenInputs()
        })
    }
    if (businessSectorInput !== null) {
        businessSectorInput.addEventListener('change', (event) => {
            synchronizeNatureAndTypeHiddenInputs()
        })
    }

    // if (siretInput.value != "") {
    //     submitInput.removeAttribute('disabled')
    // }

    /**
     * This function updates the "Type" field options (on step 2) depending on the "Nature" field.
     * It uses a global javascript variable "accountCategories" which list the options mapping.
     * @see template step2.html.twig
     */
    function refreshBusinessSectors() {
        const categoryValue = accountCategoryInput.value
        const businessSectorValue = businessSectorInput.value

        if (categoryValue === "") {
            businessSectorInput.innerHTML = '<option value="">Sélectionnez une nature d\'activité ci-dessus</option>';
            return;
        }

        businessSectorInput.innerHTML = "";
        let options = '<option value="">Sélectionnez un type d\'activité</option>';
        for (const sectorId in accountCategories[categoryValue].business_sectors) {
            let selected = (businessSectorValue == sectorId) ? 'selected' : '';
            options += '<option value="' + sectorId + '" ' + selected + '>' + accountCategories[categoryValue].business_sectors[sectorId] + '</option>';
        }
        businessSectorInput.innerHTML = options;
    }

    if (accountCategoryInput !== null && businessSectorInput !== null && accountCategories !== undefined) {
        accountCategoryInput.addEventListener('change', function (event) {
            refreshBusinessSectors();
        });
        refreshBusinessSectors();
    }

    function companySearchAutocomplete(data) {
        autocompleteListResults.innerHTML = '' // clear list of results
        autocompleteInfoMessage.setAttribute('aria-hidden', 'true')

        let validResults = 0
        for (let i = 1; i < 4; i++) {
            if (data['DATA' + i] === undefined || data['DATA' + i].CompanyName === "" || data['DATA' + i].CompanyNumber === "") {
                break
            }
            validResults++

            // Creating proper DOM elements
            const listItem = document.createElement('li') // Element inside list of results

            listItem.classList.add('SearchBar-result')
            listItem.innerText = (`${data['DATA' + i].CompanyName} - ${data['DATA' + i].ZIP_Code} - ${data['DATA' + i].Locality}`)

            autocompleteListResults.appendChild(listItem);

            // On click replace current field value with autocomplete value
            listItem.addEventListener('click', event => {
                enableLoader()
                document.querySelector('[data-input="companyName"]').value = data['DATA' + i].CompanyName
                hiddenAccountCategoryInput.value = ''
                hiddenBusinessSectorInput.value = ''

                getCompanyFullInfoBySearchRef(data['DATA' + i].SEARCH_REF).then(data => {
                    if (data.CompanyNumber != '') {
                        const infosCompany = `<span>N° SIRET : ${data.CompanyNumber}</span>
                        <span>${data.CompanyAddress2}</span>
                        <span>${data.ZIP_Code} ${data.Locality}, France</span>`
                        siretInfosContainer.classList.add("newRegistrationStep-template-siret")
                        siretInfosContainer.innerHTML = infosCompany
                        siretInput.value = data.CompanyNumber
                        companyNameInput.value = data.CompanyName
                        getCompanyInfosFromID350(data.CompanyNumber, data.CompanySIC)
                    }
                })
                accountCategoryLabel.classList.add('required');
                businessSectorLabel.classList.add('required');

                document.querySelectorAll('.Form-group[aria-hidden=true]').forEach(input => {
                    input.removeAttribute('aria-hidden')
                })
            })
        }

        if (validResults === 0) {
            companyNameInput.parentNode.classList.add('Form-group--error')
            autocompleteNoResultErrorMessage.setAttribute('aria-hidden', 'false')
        } else {
            companyNameInput.parentNode.classList.remove('Form-group--error')
        }

        autocompleteListResults.setAttribute('aria-hidden', autocompleteListResults.innerHTML.trim() === "")
        autocompleteInfoMessage.setAttribute('aria-hidden', autocompleteListResults.innerHTML.trim() === "")
    }

    /**
     * This function fetches a controller which request if the company already exists in ID360 and controls if it is
     * allowed to register (whitelist of NAF codes).
     */
    async function getCompanyInfosFromID350(CompanyNumber, codeNAF) {
        let response = await fetch(Routing.generate('thermor_pro_registration_get_company_by_siret', {
            siret: CompanyNumber,
            codeNAF: codeNAF
        }));

        if (!response.ok) {
            console.log(response.status);
        }
        let data = await response.json();

        if (data.found && data.is_allowed) {
            if (data.nature !== null && data.type !== null) {
                synchronizeNatureAndTypeHiddenInputs()
                activityInputs.setAttribute('aria-hidden', 'true') // hiding nature and type inputs
                if (siretInput.value != "") {
                    submitInput.removeAttribute('disabled')
                }
            } else {
                activityInputs.removeAttribute('aria-hidden')  // displaying nature and type inputs
            }
        } else if (!data.found && data.is_allowed) {
            activityInputs.removeAttribute('aria-hidden') // displaying nature and type inputs
        } else {
            activityInputs.setAttribute('aria-hidden', 'true') // hiding nature and type inputs
            if (siretInput.value != "") {
                submitInput.removeAttribute('disabled')
            }
        }

        clearAutocompleteList()

        const companyBadApeCodePanel = document.getElementById('companyBadApeCodeErrorPanel');
        if (data.is_allowed) {
            companyBadApeCodePanel.style.display = 'none';
            // submitInput.removeAttribute('disabled');
        } else {
            companyBadApeCodePanel.style.display = 'block';
        }

        disableLoader()
    }

    function clearAutocompleteList() {
        autocompleteListResults.innerHTML = ''
        autocompleteListResults.setAttribute('aria-hidden', 'true')
        autocompleteInfoMessage.setAttribute('aria-hidden', 'true')
    }

    /**
     * Returns company full data by search reference.
     *
     * @param searchRef
     * @returns {Promise<*>}
     */
    function getCompanyFullInfoBySearchRef(searchRef) {
        return fetch('/dqe-company-get-info/' + encodeURI(searchRef.replace(' ', '')))
            .then(data => data.json())
            .then((response) => JSON.parse(response))
            .then(data => {
                if (data.DATA1 !== undefined)
                    return data.DATA1
                throw new Error("Search reference not found")
            })
    }

    // Loader functions
    const loaderLayout = document.querySelector("#Loader")
    let loaderElement = null

    function enableLoader() {
        if (loaderLayout === null) return
        if (loaderElement !== null) return
        loaderElement = loaderLayout.content.cloneNode(true).childNodes[1]
        document.querySelector('body').prepend(loaderElement)
    }

    function disableLoader() {
        if (loaderElement === null) return
        document.querySelector('body').removeChild(loaderElement)
        loaderElement = null
    }

}());