import Popup from '../popup/popup'
import utilities from "../../scripts/utilities";


class PopupMail extends Popup{

    init(){
        super.init()
        this.openPopupMail()
    }

    openPopupMail() {

    }

}

export default PopupMail