document.addEventListener("DOMContentLoaded", event => {
    const backToTopBtn = document.querySelector("[data-backtotop]");

    if (backToTopBtn == null) {
        return;
    }
    initScrollToTop(backToTopBtn);
}, {'once': true});

function initScrollToTop(backToTopBtn) {
    /****
     ** Events
     ***/

    // When the user scrolls down 20px from the top of the document, show the button

    window.addEventListener('scroll', function() {
        scrollFunction()
    });

    backToTopBtn.addEventListener('click', event => {
        topFunction();
    });

    /****
     ** Utilities
     ***/

    function scrollFunction() {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            backToTopBtn.setAttribute('aria-hidden', 'false')
        } else {
            backToTopBtn.setAttribute('aria-hidden', 'true')
        }
    }

    function topFunction() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
}